import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { getLatestPorts } from '../common/api/clients/gateway.api';
import { getPorts } from '../common/api/clients/port.api';
import { DoubleLineOption } from '../common/DoubleLineOption/DoubleLineOption';
import { AsyncSearchSelect } from '../common/form-elements/AsyncSearchSelect/AsyncSearchSelect';
import { useFormData } from '../common/form-elements/Form/useFormData';
import { Label } from '../common/form-elements/Label/Label';
import { selectStyles } from '../common/form-elements/Select/Select.styles';
import { formatDateTime } from '../common/helpers/formatDateTime.helper';
import { translate } from '../common/helpers/translate.helper';
import { IconChevronRight } from '../common/icons/cdl/ChevronRight';
import { Flex } from '../common/ui/Flex';
import { theme } from '../common/ui/theme';

import { Port } from './Port';

const beforeStyles = (props) => {
    if (props.hideBorder) {
        return null;
    }

    const lineStyles = (props) => {
        if (props.first) {
            return css`
                top: 28px;
            `;
        }

        if (props.last) {
            return css`
                top: auto;
                bottom: 28px;
            `;
        }

        return css`
            top: 0;
        `;
    };

    return css`
        &:before {
            content: '';
            position: absolute;
            ${lineStyles};
            left: 20px;
            border-left: 2px solid ${theme.colors['dark-blue'][5]};
            height: 100%;
            width: 12px;
        }
    `;
};

const ScheduleDoubleLineOption = styled(DoubleLineOption)`
    position: relative;
    padding-left: 40px !important;

    ${beforeStyles};

    &:after {
        content: '';
        position: absolute;
        left: 17px;
        top: 26px;
        height: 8px;
        width: 8px;
        background-color: ${theme.colors['dark-blue'][0]};
        border-radius: 100%;
    }
`;

const PortOption = ({ data: { port, schedule, hideBorder, schedulesLength }, ...props }) => {
    if (schedule) {
        const formattedDate = formatDateTime({
            date: schedule.eta,
            timeZoneId: schedule.timeZoneId,
        });

        return (
            <ScheduleDoubleLineOption
                headline={<Port port={port} short={true} />}
                subline={
                    <Flex alignItems="center">
                        <span>
                            {port.country.name} ({port.locCode})
                        </span>
                        <IconChevronRight width={20} height={20} />
                        <span>{formattedDate}</span>
                    </Flex>
                }
                hideBorder={hideBorder || schedulesLength === 1}
                first={schedule.rank === 1}
                last={schedule.rank === schedulesLength}
                {...props}
            />
        );
    }

    return (
        <DoubleLineOption
            headline={<Port port={port} short={true} />}
            subline={`${port.country.name} (${port.locCode})`}
            {...props}
        />
    );
};

/**
 * @deprecated Use PortSelectWithSchedules instead
 **/

export function DeprecatedPortSelectWithSchedules({
    portId,
    onChange,
    schedules,
    label = translate('portSelect.label'),
    ...props
}) {
    const [selectedValue, setSelectedValue] = useState(null);
    const [isDirty, setIsDirty] = useState(false);

    const findSchedule = (portId) => {
        if (!schedules) {
            return false;
        }

        return schedules.find((schedule) => schedule.port?.id === portId);
    };

    const loadPortsBySearchQuery = (searchQuery) => {
        if (!searchQuery) {
            return new Promise((resolve) => {
                getLatestPorts()
                    .then((response) => {
                        const portsWithSchedule = [];

                        schedules
                            ?.filter((schedule) => !!schedule.port)
                            .forEach((schedule) => {
                                portsWithSchedule.push({
                                    label: schedule.port.name,
                                    value: schedule.port.id,
                                    port: schedule.port,
                                    schedule,
                                    schedulesLength: schedules.length,
                                });
                            });

                        const portsWithoutSchedule = response
                            .filter((port) => !findSchedule(port.id))
                            .map((port) => {
                                return {
                                    label: port.name,
                                    value: port.id,
                                    port,
                                };
                            });

                        return [...portsWithSchedule, ...portsWithoutSchedule];
                    })
                    .then((ports) => {
                        resolve(ports);
                    });
            });
        }
        return new Promise((resolve) => {
            getPorts({ searchQuery })
                .then((response) => {
                    const portsWithSchedule = [];
                    const portsWithoutSchedule = [];

                    response.content.forEach((port) => {
                        const schedule = findSchedule(port.id);

                        if (schedule) {
                            portsWithSchedule.push({
                                label: port.name,
                                value: port.id,
                                port,
                                schedule,
                                schedulesLength: schedules.length,
                                hideBorder: true,
                            });
                        } else {
                            portsWithoutSchedule.push({
                                label: port.name,
                                value: port.id,
                                port,
                                schedule: null,
                                hideBorder: true,
                            });
                        }
                    });

                    return [...portsWithSchedule, ...portsWithoutSchedule];
                })

                .then((ports) => resolve(ports));
        });
    };

    const loadPortById = (portId) => {
        return new Promise((resolve) => {
            getPorts({ ids: [portId] })
                .then((response) => {
                    const port = response.content[0];
                    return {
                        label: port.name,
                        value: port.id,
                        port,
                    };
                })

                .then((port) => resolve(port));
        });
    };

    useEffect(() => {
        let mounted = true;
        if (portId) {
            loadPortById(portId).then((result) => {
                if (mounted) {
                    setSelectedValue(result);
                }
            });
        }

        return () => (mounted = false);
    }, [portId]);

    const updateSelectedValue = (option) => {
        onChange(option.value, option);
    };

    const hasSchedules = !!schedules?.length;

    const customStyles = {
        ...selectStyles,
        menu: (provided, state) => ({
            ...provided,
            ...selectStyles.menu(provided, state),
            width: hasSchedules ? '200%' : undefined,
            maxWidth: hasSchedules ? '400px' : undefined,
        }),
    };

    return (
        <Label label={label} required={props.required}>
            <AsyncSearchSelect
                placeholder={translate('portSelect.placeholder')}
                values={selectedValue}
                onChange={updateSelectedValue}
                loadOptions={loadPortsBySearchQuery}
                components={{
                    Option: PortOption,
                }}
                className={isDirty ? 'dirty' : ''}
                onBlur={() => setIsDirty(true)}
                styles={customStyles}
                // Hack to trigger fetching of new options including schedules
                rerenderValue={JSON.stringify(schedules)}
                {...props}
            />
        </Label>
    );
}

export const FormPortSelectWithSchedules = ({ dataPath, ...props }) => {
    const { value, onChange } = useFormData(dataPath, { default: null });

    const onChangeHandle = (portId) => {
        onChange({
            target: { value: portId },
        });
    };

    return <DeprecatedPortSelectWithSchedules key={value} portId={value} onChange={onChangeHandle} {...props} />;
};
