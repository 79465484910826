import { useState } from 'react';

import { DrawerFormLayout } from '../cdl/Drawer/DrawerFormLayout';
import { Form } from '../common/form-elements/Form/Form';
import { FormSelect } from '../common/form-elements/Select/Select';
import { translate } from '../common/helpers/translate.helper';
import { useEnums } from '../common/hooks/useEnums';

export const ProductCategoryForm = ({ title, data: oldCategory, onSubmit: onSubmitCallback }) => {
    const [isValid, setValid] = useState(!!oldCategory || false);
    const { enums, getHumanReadableValue, getEnumsForTypeAndContext } = useEnums();

    const fuelProductGroups = getEnumsForTypeAndContext('productGroup', 'FUEL');

    const createOptionObject = ({ enumType, key }) => {
        return {
            label: getHumanReadableValue(enumType, key),
            value: key,
        };
    };

    const productCategoryOptions = fuelProductGroups
        ? Object.keys(fuelProductGroups).map((enumKey) =>
              createOptionObject({ enumType: 'productGroup', key: enumKey })
          )
        : [];

    const sulphurContentOptions = Object.entries(enums).length
        ? Object.keys(enums.sulphurContent).map((enumKey) =>
              createOptionObject({ enumType: 'sulphurContent', key: enumKey })
          )
        : [];

    const handleChange = ({ data }) => {
        if (data.productGroup && data.sulphurContent) {
            setValid(true);
        }
    };

    const onSubmit = ({ data: { productGroup, sulphurContent }, event }) => {
        event.preventDefault();
        onSubmitCallback({
            data: {
                productGroup,
                sulphurContent,
            },
            oldCategory,
        });
    };

    return (
        <Form height="100%" data={oldCategory} onChange={handleChange} onSubmit={onSubmit}>
            <DrawerFormLayout title={title} isSubmitButtonDisabled={!isValid}>
                <FormSelect
                    placeholder={translate('keyPorts.placeholder.productCategory')}
                    dataPath="productGroup"
                    label={translate('keyPorts.productCategory')}
                    options={productCategoryOptions}
                    required
                />

                <FormSelect
                    placeholder={translate('keyPorts.placeholder.sulphurContent')}
                    dataPath="sulphurContent"
                    label={translate('keyPorts.sulphurContent')}
                    options={sulphurContentOptions}
                    required
                />
            </DrawerFormLayout>
        </Form>
    );
};
