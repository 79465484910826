import { useRef, useState } from 'react';

import { IconButton } from '../../cdl/IconButton/IconButton';
import { CharacterCount } from '../../common/CharacterCount/CharacterCount';
import { translate } from '../../common/helpers/translate.helper';
import { IconSend } from '../../common/icons/cdl/Send';
import { Box } from '../../common/ui/Box';
import { useCharacterLimit } from '../ApprovalRequest/useCharacterLimit';

import { GrowingTextArea } from './GrowingTextArea';
import { useMessageCreate } from './useMessageCreate';

const maxLength = 500;

export const OfferChatInput = ({ offerId }) => {
    const [message, setMessage] = useState('');
    const messageMutation = useMessageCreate();
    const { remainingCharacters, limitReached } = useCharacterLimit({
        characterLength: message.length,
        characterLimit: maxLength,
    });
    const inputRef = useRef(null);

    const inputInvalid = limitReached || message.length === 0;

    const sendMessage = async (event) => {
        event.preventDefault();

        if (!inputInvalid) {
            messageMutation.mutate({ offerId, message });
            setMessage('');
            inputRef.current?.focus();
        }
    };

    const handleChange = (event) => {
        setMessage(event.target.value);
    };

    const handleKeySubmit = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            sendMessage(event);
        }
    };

    return (
        <Box display="flex" borderTop={'1px solid #eee'}>
            <Box width="85%">
                <form onSubmit={sendMessage}>
                    <GrowingTextArea
                        ref={inputRef}
                        onChange={handleChange}
                        value={message}
                        placeholder={translate('placeholder.write')}
                        onKeyDown={handleKeySubmit}
                    />
                </form>
            </Box>
            <Box
                display="flex"
                flex={1}
                flexDirection="column"
                justifyContent="flex-end"
                alignItems="center"
                margin={2}
            >
                {remainingCharacters < 50 ? (
                    <Box marginBottom="8px">
                        <CharacterCount count={message.length} limit={maxLength} />
                    </Box>
                ) : null}
                <IconButton
                    Icon={IconSend}
                    disabled={inputInvalid}
                    emphasis="medium"
                    size="medium"
                    onClick={sendMessage}
                />
            </Box>
        </Box>
    );
};
