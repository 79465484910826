import styled from 'styled-components';

import { Text } from '../../cdl/Text/Text';
import { translate } from '../../common/helpers/translate.helper';
import { useStartUserlikeChat } from '../../common/hooks/useStartUserlikeChat';
import { get } from '../../common/ui/get';

const FooterWrapper = styled.div`
    text-align: center;
    bottom: 4px;
    left: 0;
    right: 0;
`;

const Item = styled(Text).attrs({ variant: 'extraSmall' })`
    display: inline-block;
    font-weight: 500;
    color: ${get('colors.foreground.subtle')};
    font-size: 10px;
`;

const Link = styled.a`
    color: ${get('colors.foreground.subtle')};

    &:hover {
        color: ${get('colors.accent.foreground')};
        text-decoration: none;
    }
`;

const Separator = styled(Text).attrs({ variant: 'extraSmall' })`
    margin-left: 8px;
    margin-right: 8px;
    display: inline-block;
    font-weight: 500;
    color: ${get('colors.background.muted')};
    font-size: 10px;
`;

export const LoginFooter = () => {
    const startUserlikeChat = useStartUserlikeChat();

    return (
        <FooterWrapper>
            <Item>© {new Date().getFullYear()} Closelink GmbH</Item>
            <Separator>·</Separator>
            <Item>
                <Link
                    href="#"
                    onClick={(event) => {
                        event.preventDefault();
                        startUserlikeChat();
                    }}
                >
                    {translate('login.contactUs')}
                </Link>
            </Item>
            <Separator>·</Separator>
            <Item>
                <Link href="//www.closelink.com/imprint" target="_blank">
                    {translate('login.imprint')}
                </Link>
            </Item>
            <Separator>·</Separator>
            <Item>
                <Link href="//www.closelink.com/gtc-customer" target="_blank">
                    {translate('login.gtcCustomer')}
                </Link>
            </Item>
            <Separator>·</Separator>
            <Item>
                <Link href="//www.closelink.com/gtc-supplier" target="_blank">
                    {translate('login.gtcSupplier')}
                </Link>
            </Item>
        </FooterWrapper>
    );
};
