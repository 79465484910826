import { useField } from 'formik';

import { LegacyEnumOptionSelect } from '../../enums/LegacyEnumOptionSelect';

export const FormikLegacyEnumOptionSelect = (props) => {
    const [field, , helpers] = useField(props.name);

    const onSelect = (option) => {
        if (!option) {
            helpers.setValue(null);
        } else {
            helpers.setValue(option?.value);
        }
    };

    return (
        <LegacyEnumOptionSelect
            {...field}
            value={field.value}
            label={props.label}
            enumType={props.enumType}
            context={props.context}
            required={props.required}
            onSelect={onSelect}
            isClearable={props.isClearable}
            placeholder={props.placeholder}
            sort={props.sort}
        />
    );
};
