import { Tooltip } from '../cdl/Tooltip/Tooltip';
import { translate } from '../common/helpers/translate.helper';
import { useKeyPorts } from '../common/hooks/useKeyPorts';

import { KeyPortIndicator, KeyPortIndicatorProps } from './KeyPortIndicator';

interface KeyPortHighlightProps extends KeyPortIndicatorProps {
    portId: string;
    vesselId?: string;
    showTooltip?: boolean;
}

export const KeyPortHighlight = ({ portId, vesselId, color, showTooltip = false }: KeyPortHighlightProps) => {
    const { isKeyPort } = useKeyPorts();

    if (!isKeyPort({ portId, vesselId })) {
        return null;
    }

    const title = translate('keyPorts.highlightTooltip');

    if (showTooltip) {
        return (
            <Tooltip label={title}>
                <KeyPortIndicator color={color} />
            </Tooltip>
        );
    }

    return <KeyPortIndicator color={color} />;
};
