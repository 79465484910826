import { FormSelect } from '../../../common/form-elements/Select/Select';
import { useEnums } from '../../../common/hooks/useEnums';

export const PackTypeFormSelect = ({ dataPath, productGroup }) => {
    const { enums, isPending, getHumanReadableValue } = useEnums();

    const filterPackTypes = (packTypes, productGroup) => {
        let filteredPackTypes = Object.keys(packTypes);
        if (productGroup === undefined) {
            return filteredPackTypes;
        }

        if (['SAMPLE_ANALYSIS', 'TEST_KIT'].includes(productGroup)) {
            return filteredPackTypes.filter((packType) => ['BOTTLE', 'PACK'].includes(packType));
        }

        return filteredPackTypes.filter((packType) => !['BOTTLE', 'PACK'].includes(packType));
    };

    const makeEnumOptions = (enums) => {
        return filterPackTypes(enums.packType, productGroup).map((key) => {
            const humanReadableValue = getHumanReadableValue('packType', key);
            return {
                value: key,
                label: humanReadableValue,
            };
        });
    };

    const options = !isPending ? makeEnumOptions(enums) : [];

    return <FormSelect dataPath={dataPath} options={options} />;
};
