import css from '@styled-system/css';
import styled from 'styled-components';

import { formatNumber } from '../helpers/formatNumber.helper';
import { translate } from '../helpers/translate.helper';
import { Tab } from '../Tab';
import { Box } from '../ui/Box';

const TabBar = styled.div(
    css({
        marginTop: 4,
        marginBottom: 5,
        borderBottom: 1,
        [Tab]: {
            marginRight: 6,
            ':last-of-type': {
                marginRight: 0,
            },
        },
    })
);

export const TabSwitch = ({ selectedContext, onSelect, totals }) => {
    const possibleContexts = Object.keys(totals);
    return (
        <Box>
            <TabBar>
                {possibleContexts.map((context) => {
                    return (
                        <Tab key={context} onClick={() => onSelect(context)} active={context === selectedContext}>
                            {totals[context] === null || totals[context] === undefined
                                ? translate(`contextSwitch.${context}.title`)
                                : translate(`contextSwitch.${context}.titleWithTotal`, {
                                      total: formatNumber({
                                          number: totals[context],
                                          minimumFractionDigits: 0,
                                          maximumFractionDigits: 0,
                                      }),
                                  })}
                        </Tab>
                    );
                })}
            </TabBar>
        </Box>
    );
};
