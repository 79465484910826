import useComponentSize from '@rehooks/component-size';
import { useEffect, useRef } from 'react';
import SwiperCore, { Navigation } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/swiper-bundle.css';
import { CategoryState } from '../../../../types/ReachInformationMessage';
import { IconButton } from '../../../cdl/IconButton/IconButton';
import { CheapIndicator } from '../../../common/CheapIndicator/CheapIndicator';
import { IconChevronLeft } from '../../../common/icons/cdl/ChevronLeft';
import { IconChevronRight } from '../../../common/icons/cdl/ChevronRight';
import { CloakBox } from '../../../common/Slider/CloakBox';
import { Box } from '../../../common/ui/Box.tsx';
import { Frame } from '../ScheduleSection/ScheduleStop';

import { Line } from './components/Line';
import { LineActivePrefix } from './components/LineActivePrefix';
import { StopDot } from './components/StopDot';
import { ScheduleStopContainer } from './ScheduleStopContainer';

SwiperCore.use([Navigation]);

const Placeholder = () => (
    <ScheduleStopContainer>
        <Frame backgroundColor="background.inset" borderWidth={0} flex="1" />
    </ScheduleStopContainer>
);

export const ScheduleSlider = ({
    activeSlide,
    onSlideChange,
    scheduleList,
    negativeStopIndices = [],
    cheapestDefaultScope,
    onSlidesPerViewChange,
    showControls = true,
}) => {
    const sliderWrapperRef = useRef();
    const { width } = useComponentSize(sliderWrapperRef);

    const singleItemWidth = 270 + 32;
    const slidesPerView = Math.floor(((width || 160) - 160) / singleItemWidth) || 1;

    for (let count = scheduleList.length; count < slidesPerView; count++) {
        scheduleList.push(<Placeholder key={count} />);
    }

    useEffect(() => {
        onSlidesPerViewChange(slidesPerView);
    }, [slidesPerView]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Box position="relative">
            {showControls ? (
                <CloakBox left="0" width="88px" hasBackground>
                    {cheapestDefaultScope.prev ? <CheapIndicator left="10px" /> : null}
                    <IconButton Icon={IconChevronLeft} emphasis="medium" className="prev-slide" />
                </CloakBox>
            ) : null}

            {showControls ? (
                <CloakBox right="0" width="88px" hasBackground reverseGradient>
                    {cheapestDefaultScope.next ? <CheapIndicator right="10px" /> : null}
                    <IconButton Icon={IconChevronRight} emphasis="medium" className="next-slide" />
                </CloakBox>
            ) : null}

            <Line />

            <Box paddingLeft="80px" paddingRight="80px" ref={sliderWrapperRef}>
                <Swiper
                    slidesPerView={slidesPerView}
                    spaceBetween={32}
                    slidesPerGroup={1}
                    loop={false}
                    navigation={{
                        prevEl: '.prev-slide',
                        nextEl: '.next-slide',
                    }}
                    onSlideChange={(swiper) => {
                        onSlideChange(swiper.realIndex);
                    }}
                    touchStartPreventDefault={false}
                    threshold={5}
                >
                    {scheduleList.map((schedule, index) => {
                        let stopDotColor = 'border.muted';
                        let activeLineColor = 'accent.emphasis';

                        if (index === 0) {
                            stopDotColor = 'accent.emphasis';
                        }

                        const relevantStopIndexes = negativeStopIndices.filter(
                            (negativeStopIndex) => negativeStopIndex.index === index
                        );

                        if (relevantStopIndexes.length) {
                            if (
                                relevantStopIndexes.some(
                                    (stopIndex) => stopIndex.categoryState === CategoryState.SAFETY_RESERVE
                                )
                            ) {
                                stopDotColor = 'attention.emphasis';
                                activeLineColor = 'attention.emphasis';
                            }

                            if (
                                relevantStopIndexes.some(
                                    (stopIndex) => stopIndex.categoryState === CategoryState.OUT_OF_REACH
                                )
                            ) {
                                stopDotColor = 'negative.emphasis';
                                activeLineColor = 'negative.emphasis';
                            }
                        }

                        return (
                            <SwiperSlide key={index} style={{ height: 'auto' }}>
                                {index === 0 ? <LineActivePrefix borderColor={activeLineColor} /> : null}
                                {index - activeSlide <= slidesPerView ? <StopDot borderColor={stopDotColor} /> : null}
                                {schedule}
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </Box>
        </Box>
    );
};
