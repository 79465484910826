import { useEffect, useState } from 'react';

import { Text } from '../../../cdl/Text/Text';
import { TextButton } from '../../../common/buttons/TextButton';
import { translate } from '../../../common/helpers/translate.helper';
import { useKeyPorts } from '../../../common/hooks/useKeyPorts';
import { useProductContext } from '../../../common/hooks/useProductContext';
import { useRole } from '../../../common/hooks/useRole';
import { IconCircleCheck } from '../../../common/icons/cdl/CircleCheck';
import { IconPlus } from '../../../common/icons/cdl/Plus';
import { Box } from '../../../common/ui/Box';
import { Flex } from '../../../common/ui/Flex';
import { useKeyPortCreate } from '../../../keyport/useKeyPortCreate';

export const AddKeyPort = ({ portId }) => {
    const [added, setAdded] = useState(false);

    const { context } = useProductContext();
    const role = useRole();

    const { isKeyPort } = useKeyPorts();
    const { mutate: createKeyPort } = useKeyPortCreate({
        onSuccess: () => setAdded(true),
    });

    useEffect(() => {
        setAdded(false);
    }, [portId]);

    const onKeyPortButtonClick = (event) => {
        event.preventDefault();

        const keyPort = {
            context,
            customerGroupId: role.user.groupId,
            portId,
        };

        createKeyPort(keyPort);
    };

    if (added) {
        return (
            <Flex alignItems="center" color="confirming-green.0">
                <IconCircleCheck width={18} height={18} />
                <Text marginLeft={3} color="dark-blue.0" weight="semi">
                    {translate('compare.keyPort.successHint')}
                </Text>
            </Flex>
        );
    }

    if (isKeyPort({ portId })) {
        return null;
    }

    return (
        <Box marginLeft="-5px">
            <TextButton onClick={onKeyPortButtonClick}>
                <IconPlus />
                {translate('compare.keyPort.addButton')}
            </TextButton>
        </Box>
    );
};
