import { useLocation, useNavigate } from '@tanstack/react-router';
import { Fragment, useState } from 'react';

import { ActionIconListItem } from '../../../common/ActionBar/ActionIconListItem';
import { ActionIconListWrapper } from '../../../common/ActionBar/ActionIconListWrapper';
import { ActionListItem } from '../../../common/ActionBar/ActionListItem';
import { translate } from '../../../common/helpers/translate.helper';
import { useRole } from '../../../common/hooks/useRole';
import { IconCircleX } from '../../../common/icons/cdl/CircleX';
import { IconCopy } from '../../../common/icons/cdl/Copy';
import { IconHistory } from '../../../common/icons/cdl/History.tsx';
import { IconList } from '../../../common/icons/cdl/List.tsx';
import { IconPencil } from '../../../common/icons/cdl/Pencil';
import { OfferCancelPopup } from '../../../common/OfferCancelPopup/OfferCancelPopup';
import { OfferExportDropdown } from '../common/components/OfferExportDropdown';
import { useOfferUpdate } from '../common/hooks/useOfferUpdate';

import { FuelInitialEnquiryPopup } from './FuelInitialEnquiryPopup';

export const FuelOfferDetailActionBar = ({ offer }) => {
    const [showInitialEnquiryPopup, setShowInitialEnquiryPopup] = useState(false);

    const [showCancelPopup, setShowCancelPopup] = useState(false);

    const updateOfferMutation = useOfferUpdate();

    const role = useRole();
    const navigate = useNavigate();
    const location = useLocation();

    const navigateToEditPage = () => {
        if (['ENQUIRY', 'QUOTED'].includes(offer.state)) {
            navigate({
                to: '/fuel/offer/$id/quote',
                params: {
                    id: offer.id,
                },
            });
            return;
        }
        navigate({
            to: '/fuel/offer/$id/edit',
            params: {
                id: offer.id,
            },
        });
    };

    const prefillOrder = (order) => {
        navigate({
            to: '/fuel/create',
            state: {
                order,
                originLocation: location,
            },
        });
    };

    const navigateToOfferOverview = () => {
        navigate({
            to: '/fuel/order/$id',
            params: {
                id: offer.orderId,
            },
        });
    };

    const hasOfferWriteRights = (offer) => {
        return role.hasWriteRights(offer.customerId) || role.hasWriteRights(offer.supplierId);
    };

    const determineCancelState = (currentState) => {
        const enquiryStates = ['ENQUIRY', 'QUOTED'];

        if (enquiryStates.includes(currentState)) {
            if (role.isSupplier()) {
                return 'ENQUIRY_DECLINED';
            }
            if ('ENQUIRY' === currentState) {
                return 'ENQUIRY_CANCELED';
            }
            return 'QUOTE_CANCELED';
        }

        return 'CANCELED';
    };

    const onCancelPopupSubmit = async ({ cancelReason }) => {
        const newState = determineCancelState(offer.state);

        await updateOfferMutation.mutateAsync({
            offer: {
                ...offer,
                state: newState,
                cancelReason,
            },
            offerId: offer.id,
        });

        setShowCancelPopup(false);
    };

    const iconList = [];

    if (!role.isAdmin() && offer.isEditable() && hasOfferWriteRights(offer)) {
        iconList.push(
            <ActionIconListItem
                onClick={navigateToEditPage}
                tooltip={translate('offer.detail.header.actions.editProducts')}
                ariaLabel="Edit offer"
            >
                <IconPencil />
            </ActionIconListItem>
        );
    }

    if (role.isCustomer() && role.hasWriteRights(offer.customerId)) {
        iconList.push(
            <ActionIconListItem
                onClick={() => {
                    prefillOrder({
                        customerId: offer.customerId,
                        vesselId: offer.vesselId,
                        portId: offer.portId,
                        port: offer.port,
                        contractId: offer.contractId,
                        contract: offer.contract,
                        eta: offer.eta,
                        etd: offer.etd,
                        agentId: offer.agentId,
                        buyerReference: offer.buyerReference,
                        comment: offer.comment,
                        validityTime: offer.validityTime,
                        testOrder: offer.testOrder,
                        assignedSupplierIds: offer.assignedSupplierIds?.length ? offer.assignedSupplierIds : null,
                        receiverSupplierIds: offer.receiverSupplierIds?.length ? offer.receiverSupplierIds : null,
                        spot: offer.spot ?? false,
                        items: offer.items,
                        attributes: offer.attributes,
                    });
                }}
                tooltip={translate('offer.detail.header.actions.copy')}
            >
                <IconCopy />
            </ActionIconListItem>
        );
    }

    iconList.push(
        <ActionIconListItem
            onClick={() => setShowInitialEnquiryPopup(true)}
            tooltip={translate('offer.detail.header.actions.initialOrder')}
        >
            <IconHistory />
        </ActionIconListItem>
    );

    if (!role.isSupplier()) {
        iconList.push(
            <ActionIconListItem
                onClick={navigateToOfferOverview}
                tooltip={translate('offer.detail.header.actions.spotOverview')}
            >
                <IconList />
            </ActionIconListItem>
        );
    }

    iconList.push(
        <ActionListItem>
            <OfferExportDropdown offer={offer} />
        </ActionListItem>
    );

    if (role.isCustomer() && !offer.isFinished() && hasOfferWriteRights(offer)) {
        iconList.push(
            <ActionIconListItem
                onClick={() => setShowCancelPopup(true)}
                tooltip={translate('offer.detail.header.actions.cancel')}
            >
                <IconCircleX color="red" />
            </ActionIconListItem>
        );
    }

    if (role.isSupplier() && !offer.isFinished() && hasOfferWriteRights(offer)) {
        const tooltip =
            offer.state === 'QUOTED'
                ? translate('offer.detail.header.actions.cancel')
                : translate('offer.detail.header.actions.decline');

        iconList.push(
            <ActionIconListItem onClick={() => setShowCancelPopup(true)} tooltip={tooltip}>
                <IconCircleX color="red" />
            </ActionIconListItem>
        );
    }

    return (
        <>
            <ActionIconListWrapper>
                {iconList.map((entry, index) => (
                    <Fragment key={index}>{entry}</Fragment>
                ))}
            </ActionIconListWrapper>

            {showInitialEnquiryPopup ? (
                <FuelInitialEnquiryPopup
                    offerId={offer.id}
                    onDismiss={() => setShowInitialEnquiryPopup(false)}
                    isOpen={showInitialEnquiryPopup}
                />
            ) : null}

            {showCancelPopup ? (
                <OfferCancelPopup
                    isOpen={showCancelPopup}
                    onSubmit={onCancelPopupSubmit}
                    onDismiss={() => setShowCancelPopup(false)}
                    isSubmitting={updateOfferMutation.isPending}
                    offerState={offer.state}
                />
            ) : null}
        </>
    );
};
