import { Tooltip } from '../../../cdl/Tooltip/Tooltip';
import { formatDateTime } from '../../../common/helpers/formatDateTime.helper';
import { conditionalTranslate } from '../../../common/helpers/translate.helper';
import { Box } from '../../../common/ui/Box';

interface ActiveFlagProps {
    active: boolean;
    dateActiveUpdated?: string;
}

const Flag = (props: Pick<ActiveFlagProps, 'active'>) => {
    return (
        <Box display="flex" alignItems="center">
            <span>{conditionalTranslate(props.active, 'global.active', 'global.inactive')}</span>
            <Box
                backgroundColor={props.active ? 'positive.emphasis' : 'negative.emphasis'}
                width="5px"
                height="5px"
                marginTop={1} // to align the dot with the text more pleasing
                marginLeft={4}
                borderRadius="100%"
            />
        </Box>
    );
};

export const VesselActiveFlag = (props: ActiveFlagProps) => {
    if (props.dateActiveUpdated) {
        return (
            <Tooltip
                label={formatDateTime({
                    date: props.dateActiveUpdated,
                })}
                side="right"
                showArrow
            >
                <div>
                    <Flag active={props.active} />
                </div>
            </Tooltip>
        );
    }

    return <Flag active={props.active} />;
};
