import { Box } from '../../../common/ui/Box';
import { FormPortSelectWithSchedules } from '../../../port/DeprecatedPortSelectWithSchedules';
import { PortPriceRow } from '../PortPriceRow';
import { RemoveLineIcon } from '../RemoveLineIcon';

import { ComparePriceButton } from './ComparePriceButton';
import { ComparePriceWrapper } from './ComparePriceWrapper';
import { useCompareSelectionState } from './hooks/useCompareSelectionState';

export const OverlayPortPriceInformation = ({
    index,
    isFetching,
    supplierData,
    portId,
    showRemoveIcon,
    onRemovePortButtonClick,
    onSelectBoxClick,
    schedules,
}) => {
    const { selection } = useCompareSelectionState();

    const isSelected = (portId, supplierId) => {
        return selection.portId === portId && selection.supplierIds.includes(supplierId);
    };

    const _onRemovePortButtonClick = (index) => (event) => {
        event.preventDefault();

        onRemovePortButtonClick(index);
    };

    const _onSelectBoxClick = (supplierId) => (event) => {
        event.preventDefault();

        onSelectBoxClick(portId, supplierId);
    };

    return (
        <PortPriceRow>
            {showRemoveIcon ? <RemoveLineIcon onClick={_onRemovePortButtonClick(index)} /> : null}
            <Box position="relative" width="180px" marginRight={5} style={{ flexShrink: 0 }}>
                <FormPortSelectWithSchedules
                    dataPath={`portInformation[${index}].portId`}
                    label={null}
                    schedules={schedules}
                />
            </Box>

            <ComparePriceWrapper supplierData={supplierData} isFetching={isFetching}>
                {supplierData
                    ? Object.keys(supplierData).map((supplierId) => {
                          const priceInfo = supplierData[supplierId];

                          return (
                              <Box key={supplierId} width="240px">
                                  <ComparePriceButton
                                      selected={isSelected(portId, supplierId)}
                                      price={priceInfo}
                                      onClick={_onSelectBoxClick(supplierId)}
                                  />
                              </Box>
                          );
                      })
                    : []}
            </ComparePriceWrapper>
        </PortPriceRow>
    );
};
