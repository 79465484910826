import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';

import { useToasts } from '../../../cdl/Toast/useToasts';
import { translate } from '../../../common/helpers/translate.helper';
import { createOverlay } from '../../../common/Overlay/Overlay';

import { CompareCallout } from './CompareCallout';
import { CompareOverlay } from './CompareOverlay';
import { CompareSelectionStateContextProvider } from './context/CompareSelectionStateContextProvider';
import { useCompareSelectionState } from './hooks/useCompareSelectionState';

export const CompareOverlayFlow = ({ order, schedules, onSubmit, trackingId }) => {
    return (
        <CompareSelectionStateContextProvider>
            <ContextAwareCompareOverlayFlow order={order} schedules={schedules} onSubmit={onSubmit} id={trackingId} />
        </CompareSelectionStateContextProvider>
    );
};

const ContextAwareCompareOverlayFlow = ({ order, schedules = [], onSubmit, trackingId }) => {
    const { selection, setSelection } = useCompareSelectionState();
    const [show, setShow] = useState(false);
    const { addToast } = useToasts();
    const stringifiedReceiverSupplierIds = JSON.stringify(order.receiverSupplierIds);

    useEffect(() => {
        setSelection({
            portId: order.portId,
            supplierIds: order.receiverSupplierIds,
        });
    }, [setSelection, order.receiverSupplierIds, stringifiedReceiverSupplierIds, order.portId]);

    const hasAtLeastOneValidItem = order.items.some((item) => item.isComplete());

    if (!order.portId || !order.vesselId || !order.customerId || !hasAtLeastOneValidItem) {
        return null;
    }

    const createPorts = () => {
        const ports = [];

        schedules.forEach((schedule) => {
            ports.push({
                id: schedule.port.id,
                etaWithZone: schedule.etaWithZone,
            });
        });

        const portIds = ports.map((port) => port.id);

        if (!portIds.includes(order.portId)) {
            ports.push({
                id: order.portId,
            });
        }

        return ports;
    };

    const ports = createPorts() || [];

    const onDirectApply = (portId, receiverSupplierIds) => {
        if (!(import.meta.env.MODE === 'production' && order.testOrder)) {
            ReactGA.event('apply_best_price_clicked', {
                category: 'Better port hint',
                action: 'Apply best price button clicked',
                id: trackingId,
                customerId: order.customerId,
                vesselId: order.vesselId,
                portId: order.portId,
            });
        }

        const dateDelivery = ports.find((port) => port.id === portId)?.etaWithZone;

        onSubmit({
            receiverSupplierIds,
            portId,
            dateDelivery,
        });

        if (dateDelivery) {
            addToast(translate('priceCompare.updatedToastDeliveryDate'));
        } else {
            addToast(translate('priceCompare.updatedToast'));
        }
    };

    const onCompareApply = () => {
        const receiverSupplierIds = selection.supplierIds;
        const portId = selection.portId;

        const dateDelivery = ports.find((port) => port.id === portId)?.etaWithZone;

        onSubmit({
            receiverSupplierIds,
            portId,
            dateDelivery,
        });
        setShow(false);
    };

    const onClick = () => {
        if (!(import.meta.env.MODE === 'production' && order.testOrder)) {
            ReactGA.event('compare_prices_clicked', {
                category: 'Better port hint',
                action: 'Compare prices button clicked',
                id: trackingId,
                customerId: order.customerId,
                vesselId: order.vesselId,
                portId: order.portId,
            });
        }
        setShow(true);
    };
    const onClose = () => setShow(false);

    return (
        <>
            <CompareCallout
                trackingId={trackingId}
                onCompareClick={onClick}
                order={order}
                schedules={schedules}
                onApply={onDirectApply}
            />
            {show
                ? createOverlay(
                      <CompareOverlay
                          items={order.items}
                          portIds={ports.map((port) => port.id)}
                          customerId={order.customerId}
                          schedules={schedules}
                          onClose={onClose}
                          onApply={onCompareApply}
                      />
                  )
                : null}
        </>
    );
};
