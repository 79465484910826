import { useLocation, useNavigate } from '@tanstack/react-router';
import { useEffect } from 'react';

import { Route } from '../../../routes/_app.fuel.create';
import { getFormStateFromOrder } from '../getFormStateFromOrder';

import { useOrderState } from './useOrderState';

const emptyOrderState = {
    orderId: null,
    customerId: null,
    vesselId: null,
    portId: null,
    contractId: null,
    eta: '',
    etd: '',
    agentId: null,
    timeZoneId: null,
    buyerReference: '',
    comment: '',
    validityTime: '',
    testOrder: false,
    items: [
        {
            productName: null,
            productId: null,
            units: '',
            unit: null,
            sulphurContent: null,
            group: null,
            packType: null,
            unitSize: null,
        },
    ],
    files: [],
    scheduleId: null,
};

export const usePrefilledFormState = () => {
    const navigate = useNavigate();

    const location = useLocation();
    const orderFromUrl = location.state.order;
    const { orderId } = Route.useSearch();

    const { data: orderFromApi, isLoading, isSuccess } = useOrderState(orderId);

    useEffect(() => {
        if (orderFromUrl) {
            return;
        }

        if (orderFromApi && orderFromApi.state !== 'DRAFT') {
            navigate({ to: '/fuel/dashboard' });
        }
    }, [orderFromUrl, orderFromApi, navigate]);

    if (orderFromUrl) {
        return {
            data: getFormStateFromOrder({ ...orderFromUrl, id: null }),
            isLoading: false,
        };
    }

    return {
        data: isSuccess ? orderFromApi : emptyOrderState,
        isLoading: isLoading,
    };
};
