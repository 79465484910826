import { useState } from 'react';

import { Button } from '../../cdl/Button/Button';
import { Modal } from '../../cdl/Modal/Modal';
import { Text } from '../../cdl/Text/Text';
import { TextArea } from '../../cdl/TextArea/TextArea';
import { translate } from '../helpers/translate.helper';
import { Box } from '../ui/Box';

export const OrderCancelPopup = ({ isOpen, onSubmit, onDismiss, isSubmitting }) => {
    const [cancelReason, setCancelReason] = useState('');

    const _onSubmit = (event) => {
        event.preventDefault();

        onSubmit({
            cancelReason,
        });
    };

    return (
        <Modal isOpen={isOpen} onDismiss={onDismiss} scrollable={false}>
            <Box padding={6}>
                <Text variant="title" as="h2" marginBottom={4}>
                    {translate('fuelOrder.cancelEnquiry.headline')}
                </Text>

                <Text variant="body">{translate('fuelOrder.cancelEnquiry.text')}</Text>

                <form onSubmit={_onSubmit}>
                    <Box display="flex" flexDirection="column" gap={6} marginTop={6}>
                        <TextArea
                            placeholder={translate('alert.cancelorder.placeholder')}
                            onChange={(e) => setCancelReason(e.target.value)}
                            value={cancelReason}
                        />

                        <Box display="flex" justifyContent="end">
                            <Button emphasis="high" destructive disabled={!cancelReason} isLoading={isSubmitting}>
                                {translate('fuelOrder.actions.cancelOrder')}
                            </Button>
                        </Box>
                    </Box>
                </form>
            </Box>
        </Modal>
    );
};
