import { useFormikContext } from 'formik';

import { ProductContext } from '../../../../../types/ProductContext';
import { FormikAgentSelectFlow } from '../../../../agent/AgentSelectFlow/FormikAgentSelectFlow';
import { FormikInput } from '../../../../cdl/Input/FormikInput';
import { ReadOnlyField } from '../../../../cdl/ReadOnlyField/ReadOnlyField';
import { Text } from '../../../../cdl/Text/Text';
import { FormikDebug } from '../../../../common/form-elements/formik/FormikDebug';
import { FormikEnumOptionSelect } from '../../../../common/form-elements/formik/FormikEnumOptionSelect';
import { FormikTimestampDatePicker } from '../../../../common/form-elements/formik/FormikTimestampDatePicker';
import { FormikFormattedNumberInput } from '../../../../common/FormattedNumberInput/FormikFormattedNumberInput';
import { FormikLubesProductTable } from '../../../../common/FormikProductTable/lubes/FormikLubesProductTable';
import { translate } from '../../../../common/helpers/translate.helper';
import { useRole } from '../../../../common/hooks/useRole';
import { Box } from '../../../../common/ui/Box';
import { OfferModel } from '../../../model/OfferModel';
import { OfferState } from '../../../model/OfferState';

import { PortDisplay } from './PortDisplay';

export const OfferEditForm = () => {
    const { values: offer } = useFormikContext<OfferModel>();
    const role = useRole();
    return (
        <Box display="grid" padding={7} gap={7}>
            <Box>
                <Text variant="title">{translate('offer.edit.lubes.generalInformation')}</Text>
                <Box
                    display="grid"
                    gridTemplateColumns="repeat(3, minmax(0,1fr))"
                    gridTemplateRows={role.isCustomer() ? 'auto 100px' : 'auto'}
                    rowGap={6}
                    columnGap={5}
                    marginTop={4}
                >
                    {role.isSupplier() ? (
                        <ReadOnlyField value={offer.customer.name} label={translate('offer.edit.lubes.customer')} />
                    ) : (
                        <ReadOnlyField value={offer.supplier.name} label={translate('offer.edit.lubes.supplier')} />
                    )}

                    <ReadOnlyField value={offer.vessel.name} label={translate('offer.edit.lubes.vessel')} />

                    {offer.port ? <PortDisplay offer={offer} /> : null}

                    <div>
                        <FormikTimestampDatePicker
                            name="dateDelivery"
                            label={translate('offer.edit.lubes.deliveryDate')}
                        />
                    </div>

                    {role.isCustomer() ? (
                        <>
                            <FormikInput name="buyerReference" label={translate('offer.edit.lubes.buyerReference')} />
                            <FormikAgentSelectFlow
                                name="agentId"
                                customerId={offer.customerId}
                                portId={offer.portId}
                                label={translate('offer.edit.lubes.agent')}
                            />
                        </>
                    ) : null}

                    {role.isSupplier() ? (
                        <>
                            {offer.isSpot() ? (
                                <Box display="grid" gridTemplateColumns="3fr 1fr" gap={4}>
                                    <FormikEnumOptionSelect
                                        label={translate('offer.edit.lubes.paymentTerms')}
                                        name="paymentTermReference"
                                        enumType="paymentTermReference"
                                        onOptionChange={(option) => {
                                            if (option === 'PREPAYMENT') {
                                                offer.paymentTermReferenceDays = undefined;
                                            }
                                        }}
                                    />
                                    <FormikFormattedNumberInput
                                        name="paymentTermReferenceDays"
                                        label={translate('offer.edit.lubes.paymentTermsDays')}
                                        allowNegativeValue={false}
                                        allowDecimals={false}
                                        disabled={offer.paymentTermReference === 'PREPAYMENT'}
                                    />
                                </Box>
                            ) : null}

                            <FormikFormattedNumberInput
                                name="noticeDays"
                                label={translate('offer.edit.lubes.noticeDays')}
                                allowNegativeValue={false}
                                allowDecimals={false}
                            />
                            <FormikEnumOptionSelect
                                name="supplyMode"
                                enumType="supplyMode"
                                label={translate('offer.edit.lubes.supplyMode')}
                                context={ProductContext.LUBES}
                            />
                            <FormikInput name="vendorReference" label={translate('offer.edit.lubes.vendorReference')} />
                        </>
                    ) : null}
                </Box>
            </Box>

            <Box>
                <Text variant="title">Products</Text>
                <FormikLubesProductTable
                    config={{
                        showPrices: true,
                        editable: true,
                        editPrices: role.isSupplier() || (role.isCustomer() && offer.state === OfferState.DELIVERED),
                        editSurcharges:
                            role.isSupplier() || (role.isCustomer() && offer.state === OfferState.DELIVERED),
                        initialItems: offer.items,
                    }}
                />
            </Box>
            <FormikDebug />
        </Box>
    );
};
