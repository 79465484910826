import { useRole } from '../../../common/hooks/useRole';
import { StateSwitchContextProvider } from '../common/components/StateSwitchContextProvider';

import { FuelAdminOfferDetailStatusBar } from './FuelAdminOfferDetailStatusBar';
import { FuelCustomerOfferDetailStatusBar } from './FuelCustomerOfferDetailStatusBar';
import { FuelSupplierOfferDetailStatusBar } from './FuelSupplierOfferDetailStatusBar';

export const FuelOfferDetailStatusBar = ({ offer, customerSettings }) => {
    const role = useRole();

    if (role.isCustomer()) {
        return (
            <StateSwitchContextProvider>
                <FuelCustomerOfferDetailStatusBar offer={offer} customerSettings={customerSettings} />
            </StateSwitchContextProvider>
        );
    }

    if (role.isSupplier()) {
        return (
            <StateSwitchContextProvider>
                <FuelSupplierOfferDetailStatusBar offer={offer} />
            </StateSwitchContextProvider>
        );
    }

    if (role.isAdmin()) {
        return <FuelAdminOfferDetailStatusBar offer={offer} />;
    }

    return null;
};
