import { useLocation } from '@tanstack/react-router';
import { AxiosError } from 'axios';
import { Formik, FormikErrors, FormikHelpers } from 'formik';

import { Text } from '../../cdl/Text/Text';
import { useToasts } from '../../cdl/Toast/useToasts';
import { isValidEmailAddress } from '../../common/helpers/isValidEmailAddress.helper';
import { translate } from '../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../common/hooks/useDocumentTitle';
import { authenticate } from '../../lib/auth';
import { useRedirectAfterLogin } from '../hooks/useRedirectAfterLogin';

import { FormikLoginForm } from './FormikLoginForm';
import { Link } from './Link.tsx';
import { LoginPageContentBox } from './LoginPageContentBox';

export interface FormikLoginState {
    emailAddress: string;
    password: string;
}

const validate = (values: FormikLoginState): FormikErrors<FormikLoginState> => {
    const errors: FormikErrors<FormikLoginState> = {};

    if (!isValidEmailAddress(values.emailAddress)) {
        errors.emailAddress = translate('login.error.invalidEmailAddress');
    }

    if (!values.password) {
        errors.password = translate('login.error.passwordRequired');
    }

    return errors;
};

export const LoginPage = () => {
    const { addErrorToast } = useToasts();
    const location = useLocation();
    const redirectAfterLogin = useRedirectAfterLogin();

    const emailAddressFromRoute = location.state?.emailAddress;

    useDocumentTitle(translate('login.pageTitle'));

    const handleSubmit = (login: FormikLoginState, meta: FormikHelpers<FormikLoginState>) => {
        authenticate(login)
            .then(() => redirectAfterLogin())
            .catch((error: AxiosError) => {
                if (error.status === 403) {
                    meta.setErrors({
                        password: translate('login.error.emailAddressOrPasswordIncorrect'),
                    });
                } else {
                    addErrorToast(translate('login.error.weCouldNotConnectToCloselink'));
                }

                meta.setSubmitting(false);
            });
    };

    return (
        <LoginPageContentBox
            belowBoxContent={
                <Text variant="extraSmall">
                    {translate('login.dontHaveAnAccountYet')}{' '}
                    <Link href="//closelink.com/contact-us">{translate('login.getInTouchToday')}</Link>
                </Text>
            }
        >
            <Text variant="title" as="h1">
                {translate('login.loginToYourAccount')}
            </Text>
            <Formik
                initialValues={{
                    emailAddress: emailAddressFromRoute || '',
                    password: '',
                }}
                validate={validate}
                onSubmit={handleSubmit}
                component={FormikLoginForm}
            />
        </LoginPageContentBox>
    );
};
