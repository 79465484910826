import { useContext } from 'react';

import { DrawerContext } from './DrawerContext.ts';
import { LayerContext } from './LayerContext';
import { Layer } from './layerStoreReducer';

export const useDrawer = () => {
    const { store, dispatch } = useContext(DrawerContext);
    const { isTopLayer } = useContext(LayerContext);

    return {
        layers: store.layers,
        hasLayers: store.layers.length > 0,
        isTopLayer,
        push: (data: Layer) => dispatch({ type: 'push', data }),
        pop: (data?: Layer) => dispatch({ type: 'pop', data }),
    };
};
