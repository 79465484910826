import themeGet from '@styled-system/theme-get';
import { useEffect, useRef, useState } from 'react';
import ReactCurrencyInput from 'react-currency-input-field';
import styled from 'styled-components';

import { Box } from '../../ui/Box';
import { useFormContext } from '../Form/useFormContext';
import { useFormData } from '../Form/useFormData';
import { Label } from '../Label/Label';

const StyledCurrencyInput = styled(ReactCurrencyInput)`
    border-radius: ${themeGet('radii.1')}px;
    border: ${themeGet('borders.1')};
    padding: ${themeGet('sizes.3')}px ${themeGet('sizes.4')}px;
    width: 100%;
    font-weight: normal;

    border-color: ${(props) => {
        if (props.$highlight) {
            return themeGet('colors.clear-blue.0');
        }

        if (props.$error) {
            return themeGet('colors.signal-red.0');
        }

        return null;
    }};

    :hover,
    :focus {
        border-color: ${themeGet('colors.clear-blue.0')};
    }

    ::placeholder {
        color: ${themeGet('colors.dark-blue.3')};
        opacity: 1;
    }
`;

export const FormattedNumberInput = ({
    label,
    focus = false,
    annotation = null,
    highlight = false,
    error = false,
    required = false,
    onChange,
    decimalsLimit = 1,
    allowNegativeValue = false,
    decimalScale = undefined,
    placeholder,
    groupSeparator = ',',
    decimalSeparator = '.',
    ...input
}) => {
    const inputRef = useRef(null);
    const [isDirty, setIsDirty] = useState(false);

    useEffect(() => {
        if (focus) {
            inputRef.current.focus();
        }
    }, [focus]);

    return (
        <Label label={label} annotation={annotation} required={required}>
            <Box position="relative">
                <StyledCurrencyInput
                    onValueChange={onChange}
                    ref={inputRef}
                    required={required}
                    className={isDirty ? 'dirty' : ''}
                    $highlight={highlight}
                    $error={error}
                    onBlur={() => setIsDirty(true)}
                    decimalsLimit={decimalsLimit}
                    allowNegativeValue={allowNegativeValue}
                    allowDecimals={decimalsLimit > 0}
                    decimalScale={decimalScale}
                    placeholder={placeholder}
                    groupSeparator={groupSeparator}
                    decimalSeparator={decimalSeparator}
                    {...input}
                />
            </Box>
        </Label>
    );
};

export const FormFormattedNumberInput = ({
    dataPath,
    transformValue = (v) => v,
    disabled = false,
    allowNegativeValue,
    allowDecimals,
    decimalsLimit,
    ...props
}) => {
    const { onChange, value } = useFormData(dataPath, { transformValue });
    const { data } = useFormContext();
    const disabledValue = typeof disabled === 'function' ? disabled(data) : disabled;

    const onChangeHandle = (value) => {
        onChange({
            target: { value: value },
        });
    };

    return (
        <FormattedNumberInput
            value={value}
            onChange={onChangeHandle}
            allowNegativeValue={allowNegativeValue}
            allowDecimals={allowDecimals}
            decimalsLimit={decimalsLimit}
            {...props}
            disabled={disabledValue}
        />
    );
};
