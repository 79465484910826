import { SurchargeType } from '../../../types/SurchargeType';
import { Button } from '../../cdl/Button/Button';
import { useDrawer } from '../../cdl/Drawer/useDrawer.hook';
import { translate } from '../../common/helpers/translate.helper';
import { IconPlus } from '../../common/icons/cdl/Plus';

import { SurchargeForm } from './SurchargeForm';

export interface Surcharge {
    surchargeType: SurchargeType;
    amount: string;
    customOption?: string;
}

interface SurchargeButtonProps {
    onSubmit: (surcharge: Surcharge) => void;
}

export const SurchargeButton = ({ onSubmit }: SurchargeButtonProps) => {
    const { push, pop } = useDrawer();

    function openModal() {
        push({
            content: (
                <SurchargeForm
                    onSubmit={(surcharge) => {
                        onSubmit(surcharge);
                        pop();
                    }}
                />
            ),
        });
    }

    return (
        <Button type="button" leadingVisual={<IconPlus height={16} width={16} />} onClick={openModal}>
            {translate('surchargeForm.button')}
        </Button>
    );
};
