import { useField } from 'formik';

import { FuelMoneyInput } from '../NumberInputs/FuelMoneyInput';

export const FormikFuelMoneyInput = (props) => {
    const [field, meta, helpers] = useField(props.name);
    const money = field.value;

    const onChange = (value) => {
        if (value) {
            helpers.setValue({
                currency: money?.currency ?? 'USD',
                value,
            });
        } else {
            helpers.setValue(null);
        }
    };

    return (
        <FuelMoneyInput
            allowNegativeValue={props.allowNegativeValue}
            {...props}
            {...field}
            value={money?.value}
            onValueChange={onChange}
            highlight={props.required && !meta.touched && !field.value}
            error={!!meta.error}
        />
    );
};
