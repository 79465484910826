import { createFileRoute } from '@tanstack/react-router';

import { ProductContext } from '../../types/ProductContext';
import { queries } from '../common/api/queryKeys/queries';
import { LubesOfferComparePage } from '../order/detail/lubes/LubesOfferComparePage';

export const Route = createFileRoute('/_app/_lubes/order/$id')({
    async loader({ params, context }) {
        context.queryClient.ensureQueryData(queries.orders.detail(params.id));
        context.queryClient.prefetchQuery(
            queries.offers.list({ orderIds: [params.id], types: [ProductContext.LUBES] })
        );
    },
    component: LubesOfferComparePage,
});
