import { HeaderRow } from '../../order/fuel/ProductTable';
import { HumanReadableEnumValue } from '../enums/HumanReadableEnumValue';
import { formatNumber } from '../helpers/formatNumber.helper';
import { Table } from '../Table/Table';
import { Td } from '../Table/Td';
import { Tr } from '../Table/Tr';

export const FuelShowProductTable = ({ items }) => {
    return (
        <Table>
            <thead>
                <HeaderRow />
            </thead>
            <tbody>
                {items.map((item, index) => (
                    <Tr key={index}>
                        <Td width="30%">{item.product.name}</Td>
                        <Td width="20%">
                            {item.isoSpec ? (
                                <HumanReadableEnumValue enumType="fuelIsoSpec" value={item.isoSpec} />
                            ) : (
                                '-'
                            )}
                        </Td>
                        <Td width="20%">
                            <HumanReadableEnumValue enumType="productGroup" value={item.product.group} />{' '}
                            <HumanReadableEnumValue enumType="sulphurContent" value={item.product.sulphurContent} />
                        </Td>
                        <Td width="15%">
                            {formatNumber({
                                number: item.volume,
                                maximumFractionDigits: 1,
                            })}
                        </Td>
                        <Td width="10%">
                            <HumanReadableEnumValue enumType="productUnit" value={item.unit} />
                        </Td>
                    </Tr>
                ))}
            </tbody>
        </Table>
    );
};
