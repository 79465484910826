import styled from 'styled-components';

import { CL_DARK_BLUE_2 } from '../colors';
import { HumanReadableEnumValue } from '../enums/HumanReadableEnumValue';
import { formatFuelMoney } from '../helpers/formatFuelMoney.helper';
import { formatNumber } from '../helpers/formatNumber.helper';
import { translate } from '../helpers/translate.helper';
import { Td } from '../Table/Td';

const TotalRow = styled.tr`
    background-color: ${CL_DARK_BLUE_2};

    td {
        font-weight: 600;
    }
`;

export const Total = ({ offer, showPrices, showOnlyInitialValues }) => {
    return (
        <TotalRow>
            <Td />
            <Td colSpan={showOnlyInitialValues ? 3 : 5}>{translate('order.itemtotal')}</Td>
            <Td colSpan={2}>
                {formatNumber({
                    number: offer.volume,
                    maximumFractionDigits: 1,
                })}{' '}
                <HumanReadableEnumValue enumType="productUnit" value={offer.items[0].unit} />
            </Td>

            {showPrices ? (
                <Td>
                    {offer.hasValidProducts()
                        ? formatFuelMoney({
                              value: offer.total.value,
                              currency: offer.total.currency,
                          })
                        : '-'}
                </Td>
            ) : null}
        </TotalRow>
    );
};
