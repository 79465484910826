import { Button } from '../../cdl/Button/Button';
import { translate } from '../../common/helpers/translate.helper';

export const NextButton = ({ onClick, disabled }) => {
    return (
        <Button type="button" emphasis="high" disabled={disabled} onClick={onClick}>
            {translate('fuelEnquiry.navigation.next')}
        </Button>
    );
};
