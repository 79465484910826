import { useNavigate } from '@tanstack/react-router';
import { Form, FormikProps, useFormikContext } from 'formik';
import moment from 'moment';
import { ReactNode } from 'react';

import { Iso8601DateTimeString } from '../../../types/Iso8601DateTimeString';
import { PortMessage } from '../../../types/PortMessage';
import { SamplekitMessage } from '../../../types/SamplekitMessage';
import { FormikAgentSelectFlow } from '../../agent/AgentSelectFlow/FormikAgentSelectFlow';
import { Button } from '../../cdl/Button/Button';
import { FormikInput } from '../../cdl/Input/FormikInput';
import { ReadOnlyField } from '../../cdl/ReadOnlyField/ReadOnlyField';
import { Text } from '../../cdl/Text/Text';
import { Divider } from '../../common/Divider/Divider';
import { FormikDatePicker } from '../../common/form-elements/formik/FormikDatePicker';
import { FormikDebug } from '../../common/form-elements/formik/FormikDebug';
import { FormikLubesProductTable } from '../../common/FormikProductTable/lubes/FormikLubesProductTable';
import { formatDateTime } from '../../common/helpers/formatDateTime.helper.ts';
import { formatVessel } from '../../common/helpers/formatVessel.helper';
import { translate } from '../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../common/hooks/useDocumentTitle';
import { useSchedules } from '../../common/hooks/useSchedules';
import { useVesselDetails } from '../../common/hooks/useVesselDetails';
import { ItemModel } from '../../common/models/ItemModel';
import { OverlayHeader } from '../../common/Overlay/OverlayHeader';
import { Box } from '../../common/ui/Box';
import { FormikPortSelectWithSchedules } from '../../port/portSelect/FormikPortDetailSelectWithSchedule';
import { Route } from '../../routes/_app._lubes.order_.edit.$id';
import { OrderVesselInformation } from '../create/OrderVesselInformation';
import { useOrder } from '../lubes/hooks/useOrder';
import { OrderState } from '../model/OrderModel';

export type FormikEditOrderValues = {
    id: string;
    vesselId: string;
    customerId: string;
    portId: string;
    dateDelivery: Iso8601DateTimeString | null;
    buyerReference: string;
    agentId: string;
    items: ItemModel[];
    samplekits: SamplekitMessage[];
    state?: OrderState;
};

interface PageWithHeaderProps {
    children: ReactNode;
}

const PageWithHeader = ({ children }: PageWithHeaderProps) => {
    const { id } = Route.useParams();
    const { data: order } = useOrder(id);
    const navigate = useNavigate();
    const { isSubmitting, dirty } = useFormikContext();

    useDocumentTitle(translate('page.orderedit', { orderNumber: id }));

    const callIfConfirmedDirty = (fn: Function) => () => {
        if (dirty && !confirm(translate('confirmation.warning'))) {
            return;
        }

        fn();
    };

    return (
        <OverlayHeader
            title={translate('order.edit.headline', { orderId: order?.orderNumber || '' })}
            onClose={callIfConfirmedDirty(() => navigate({ to: '/order/$id', params: { id } }))}
            trailingVisual={
                <Box gap={4}>
                    <Button type="submit" emphasis="high" isLoading={isSubmitting}>
                        {translate('order.edit.update')}
                    </Button>
                </Box>
            }
        >
            {children}
        </OverlayHeader>
    );
};

export const OrderEditForm = (props: FormikProps<FormikEditOrderValues>) => {
    const { data: vessel } = useVesselDetails(props.values.vesselId);
    const { schedules } = useSchedules(vessel?.imo);
    const { setFieldValue } = useFormikContext<FormikEditOrderValues>();

    const onPortSelect = (port: PortMessage, scheduleRank?: number) => {
        setFieldValue('agentId', undefined, false);

        const matchingSchedule = schedules.find(
            (schedule) => schedule.port?.id === port.id && schedule.rank === scheduleRank
        );

        if (matchingSchedule) {
            setFieldValue(
                'dateDelivery',
                moment
                    .utc(
                        formatDateTime({
                            date: matchingSchedule.eta,
                            timeZoneId: matchingSchedule.timeZoneId,
                        })
                    )
                    .startOf('day')
                    .toISOString(),
                false
            );

            if (matchingSchedule.agentIds?.length === 1) {
                setFieldValue('agentId', matchingSchedule.agentIds[0], false);
            }
        }
    };

    return (
        <Form>
            <PageWithHeader>
                <Box padding={7} display="flex" flexDirection="column">
                    <div>
                        <Text variant="title">{translate('order.coreData')}</Text>
                        <Text variant="body" color="foreground.muted" as="p">
                            {translate('order.coreDataSubText')}
                        </Text>
                    </div>
                    <Divider marginTop={4} marginBottom={7} />
                    <Box display="flex" gap={9} flexDirection="column">
                        <Box display="grid" gridTemplateColumns="1fr 1fr" gap={8} marginBottom={6}>
                            <Box display="flex" gap={5} flexDirection="column">
                                <ReadOnlyField
                                    value={formatVessel({ vessel, short: true })}
                                    label={translate('global.coreData.vessel')}
                                />

                                <FormikPortSelectWithSchedules
                                    name="portId"
                                    schedule={schedules}
                                    onSelect={onPortSelect}
                                />

                                <FormikDatePicker name="dateDelivery" label={translate('order.dateDelivery')} />

                                <FormikInput label={translate('order.buyerReferenceOptional')} name="buyerReference" />

                                <FormikAgentSelectFlow
                                    label={translate('order.agentOptional')}
                                    customerId={props.values.customerId}
                                    portId={props.values.portId}
                                    name="agentId"
                                />
                            </Box>

                            <OrderVesselInformation vesselId={props.values.vesselId} />
                        </Box>

                        <Box>
                            <Text variant="title">{translate('order.products')}</Text>
                            <Divider marginTop={5} marginBottom={7} />
                            <FormikLubesProductTable
                                config={{
                                    initialItems: [],
                                    editable: true,
                                    showPrices: false,
                                    editSurcharges: false,
                                    editPrices: false,
                                }}
                            />
                        </Box>
                    </Box>
                    <FormikDebug />
                </Box>
            </PageWithHeader>
        </Form>
    );
};
