import { useField } from 'formik';

import { DeprecatedPortSelectWithSchedules } from '../../../port/DeprecatedPortSelectWithSchedules';

/**
 * @deprecated Use FormikPortSelectWithSchedules instead
 **/

export const DeprecatedFormikPortSelectWithSchedules = (props) => {
    const [field, , helpers] = useField(props.name);

    const onChange = (portId, option) => {
        helpers.setValue(portId);
        props.onPortSelect?.(option.port);
    };

    return <DeprecatedPortSelectWithSchedules {...props} onChange={onChange} portId={field.value} />;
};
