import { useField } from 'formik';
import styled from 'styled-components';

import { FormikContractVolumeWarning } from '../../../order/fuel/FormikContractVolumeWarning';
import { FormikFormattedNumberInput } from '../../form-elements/formik/FormikFormattedNumberInput';
import { formatNumber } from '../../helpers/formatNumber.helper';
import { translate } from '../../helpers/translate.helper';
import { Box } from '../../ui/Box';

import { useProductTableConfig } from './useProductTableConfig';

const Grid = styled.div`
    display: grid;
    grid-template-columns: 3fr 1fr;
    align-items: center;
    grid-gap: 6px;
`;

export const UnitsDisplay = (props) => {
    const { initialItems } = useProductTableConfig();

    const [field, meta] = useField(`${props.name}.units`);

    if (props.itemDeleted || props.editable === false) {
        if (field.value) {
            return formatNumber({
                number: field.value,
                maximumFractionDigits: 1,
            });
        }
        return '-';
    }

    return (
        <Grid>
            <FormikFormattedNumberInput
                name={`${props.name}.units`}
                placeholder={translate('offer.quotationProcess.fuel.volumePlaceholder')}
                step={0.1}
                error={!!meta.error}
                highlight={!meta.touched && !field.value}
                required
            />
            <Box>
                {props.isContract ? (
                    <FormikContractVolumeWarning name={props.name} initialItems={initialItems} index={props.index} />
                ) : null}
            </Box>
        </Grid>
    );
};
