import { useLocation, useNavigate } from '@tanstack/react-router';
import { useFormikContext } from 'formik';

import { Button } from '../../cdl/Button/Button';
import { translate } from '../../common/helpers/translate.helper';
import { OrderState } from '../model/OrderModel';

import { useDraftCreate } from './hooks/useDraftCreate';
import { useDraftUpdate } from './hooks/useDraftUpdate';
import { mapToFuelOrderRequestObject } from './mapToFuelOrderRequestObject';

interface SaveAsDraftButtonProps {
    disabled: boolean;
}

export const SaveAsDraftButton = ({ disabled }: SaveAsDraftButtonProps) => {
    const { values } = useFormikContext<any>();

    const draftCreate = useDraftCreate();
    const draftUpdate = useDraftUpdate();

    const navigate = useNavigate();
    const location = useLocation();

    const onClick = () => {
        const order = mapToFuelOrderRequestObject(values, OrderState.DRAFT);

        if (values.orderId) {
            draftUpdate.mutate({
                orderId: values.orderId,
                order,
            });
        } else {
            draftCreate.mutate(order, {
                onSuccess: (data) => {
                    const orderId = data.id;

                    navigate({
                        to: `/fuel/create`,
                        search: { orderId },
                        state: location.state,
                        replace: true,
                    });
                },
            });
        }
    };

    return (
        <Button type="button" disabled={disabled} onClick={onClick}>
            {translate('fuelEnquiry.navigation.saveAsDraft')}
        </Button>
    );
};
