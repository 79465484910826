import { useToasts } from '../../../../cdl/Toast/useToasts';
import { translate } from '../../../../common/helpers/translate.helper';

import { SimpleConfirmPopup } from './SimpleConfirmPopup';

export const AcknowledgePopup = ({ onConfirm, onDismiss, isOpen, isSubmitting }) => {
    const { addToast, addErrorToast } = useToasts();

    const onSubmit = async () => {
        try {
            await onConfirm();
            addToast(translate('alert.state.ACKNOWLEDGED.title'));
        } catch {
            addErrorToast(translate('global.genericToastError'));
        }
    };

    return (
        <SimpleConfirmPopup
            isOpen={isOpen}
            onSubmit={onSubmit}
            onDismiss={onDismiss}
            headline={translate('alert.acknowledge.title')}
            description={translate('alert.acknowledge.message')}
            buttonText={translate('alert.acknowledge.btnok')}
            isSubmitting={isSubmitting}
        />
    );
};
