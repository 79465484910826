import styled, { keyframes } from 'styled-components';

import { translate as t } from '../../common/helpers/translate.helper';
import { IconX } from '../../common/icons/cdl/X';
import { Box } from '../../common/ui/Box.tsx';
import { get } from '../../common/ui/get';

import { LayerContext } from './LayerContext';
import { useDrawer } from './useDrawer.hook';

const slideInAnimation = keyframes`
    from {
        transform: translate3d(100%, 0, 0);
    }

    to {
        transform: translate3d(0, 0, 0);
    }
`;
const StyledLayer = styled(Box)`
    position: fixed;
    top: 16px;
    bottom: 16px;
    right: 16px;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 12px 24px -4px #1936434d;
    overflow: auto;
    transition:
        padding-right 200ms ease-out,
        transform 200ms ease-out;
    animation: ${slideInAnimation} 200ms ease-in-out;
    width: ${({ width = '33vw' }) => width};
    min-width: 400px;
    max-width: 800px;
    z-index: ${({ index }) => index + 1};

    ${({ isTopLayer }) => (!isTopLayer ? 'pointer-events: none;' : '')}

    ${({ offset }) => {
        return offset ? `transform:translateX(-${offset}px)` : null;
    }}
`;

const Close = styled.button`
    border: none;
    padding: ${get('space.2')}px;
    position: absolute;
    top: ${get('space.7')}px;
    right: ${get('space.7')}px;
    color: ${get('colors.foreground.default')};
`;

const CloseButton = ({ disabled }) => {
    const { pop } = useDrawer();

    /**
     * Note: we're still rendering the close button in non-top-layers so that we
     * do not get layout jumps and keep its margins etc.
     */

    return (
        <Close
            onClick={() => {
                if (!disabled) pop();
            }}
            title={!disabled ? t('drawer.closeTitle') : null}
            disabled={disabled}
        >
            <IconX />
        </Close>
    );
};

export const Layer = ({ index, total, offset, width, ...props }) => {
    const isTopLayer = index + 1 === total;

    return (
        <LayerContext.Provider value={{ isTopLayer }}>
            <StyledLayer width={width} index={index} offset={offset} isTopLayer={isTopLayer} {...props}>
                <CloseButton disabled={!isTopLayer} />
                {props.children}
            </StyledLayer>
        </LayerContext.Provider>
    );
};
