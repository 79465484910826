import { Fragment } from 'react';

import { Text } from '../../cdl/Text/Text';
import { translate } from '../../common/helpers/translate.helper';
import { IconSpotMarket } from '../../common/icons/SpotMarket';
import { Flex } from '../../common/ui/Flex';

export const FuelEnquiryBoxContent = () => {
    return (
        <Fragment>
            <Text fontSize="16px" weight="semi">
                {translate('fuelEnquiry.second.contractProducts.enquiry')}
            </Text>

            <Flex height="130px" justifyContent="center" alignItems="center">
                <IconSpotMarket width={74} height={72} />
            </Flex>

            <Flex
                position="absolute"
                bottom="0"
                left="0"
                right="0"
                height="60px"
                justifyContent="center"
                alignItems="center"
                borderTop={1}
                borderTopColor="border.default"
            >
                <Text size={14} weight="semi">
                    {translate('fuelEnquiry.second.contractProducts.enquiryText')}
                </Text>
            </Flex>
        </Fragment>
    );
};
