import { createFileRoute } from '@tanstack/react-router';

import { queries } from '../common/api/queryKeys/queries';
import { CustomerGroupDetailPage } from '../group/customer/detail/CustomerGroupDetailPage';

export const Route = createFileRoute('/_app/customer-group/$id')({
    async loader({ params, context }) {
        context.queryClient.ensureQueryData(queries.customerGroups.detail(params.id));
        context.queryClient.prefetchQuery(queries.customers.list({ groupId: params.id }));
    },
    component: CustomerGroupDetailPage,
});
