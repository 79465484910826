import { Form, Formik, FormikErrors } from 'formik';

import { Button } from '../../cdl/Button/Button';
import { FormikInput } from '../../cdl/Input/FormikInput';
import { Text } from '../../cdl/Text/Text';
import { FormikDebug } from '../../common/form-elements/formik/FormikDebug';
import { translate } from '../../common/helpers/translate.helper';
import { Characters } from '../../common/helpers/unicode.helper';
import { FormikMoneyInput } from '../../common/MoneyInput/FormikMoneyInput';
import { Box } from '../../common/ui/Box';

import { Waiver } from './WaiverButton';

const NegativeDollar = () => {
    return (
        <span>
            {Characters.DOLLAR}
            {Characters.NO_BREAK_SPACE}
            {Characters.MINUS}
        </span>
    );
};

interface WaiverFormProps {
    onSubmit: (waiver: Waiver) => void;
    data?: Waiver;
}

interface WaiverFormState {
    description: string;
    amount: string;
}

export const WaiverForm = ({ onSubmit, data }: WaiverFormProps) => {
    const initialValues: WaiverFormState = {
        description: data?.description ?? '',
        amount: data?.amount ?? '',
    };

    const validateWaiverForm = (values: WaiverFormState): FormikErrors<WaiverFormState> => {
        const errors: FormikErrors<WaiverFormState> = {};

        if (!values.description) {
            errors.description = translate('waiverForm.errors.descriptionRequired');
        }

        if (!values.amount) {
            errors.amount = translate('waiverForm.errors.amountRequired');
        }

        if (Number(values.amount) === 0) {
            errors.amount = translate('waiverForm.errors.amountZero');
        }

        return errors;
    };

    return (
        <Formik initialValues={initialValues} validate={validateWaiverForm} onSubmit={onSubmit}>
            {({ isSubmitting }) => (
                <Form style={{ height: '100%' }}>
                    <Box display="grid" gridTemplateRows="auto 1fr auto" height="100%" gridTemplateColumns="100%">
                        <Box padding={7} paddingBottom={6}>
                            <Text variant="title" marginBottom={3} as="h1">
                                {translate('waiverForm.title')}
                            </Text>
                            <Text variant="body" color="foreground.muted" as="p" style={{ whiteSpace: 'pre-wrap' }}>
                                {translate('waiverForm.description.amount')}
                                <br />
                                {translate('waiverForm.description.total')}
                            </Text>
                        </Box>

                        <Box paddingX={7} display="flex" flexDirection="column" gap={5} overflowY="auto">
                            <FormikInput name="description" label={translate('waiverForm.labels.description')} />
                            <FormikMoneyInput
                                name="amount"
                                label={translate('waiverForm.labels.amount')}
                                decimals={2}
                                allowNegativeValue={false}
                                leadingVisual={<NegativeDollar />}
                            />
                            <FormikDebug />
                        </Box>

                        <Box padding={7}>
                            <Button emphasis="high" type="submit" size="large" isLoading={isSubmitting} width="100%">
                                {translate('waiverForm.button')}
                            </Button>
                        </Box>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};
