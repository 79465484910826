import css from '@styled-system/css';
import styled from 'styled-components';

import { Text } from '../../../cdl/Text/Text.tsx';
import { Select } from '../../../common/form-elements/Select/Select';
import { translate } from '../../../common/helpers/translate.helper';
import { IconPlus } from '../../../common/icons/cdl/Plus';

const InteractiveText = styled(Text)(
    css({
        alignItems: 'center',
        display: 'flex',
        marginLeft: '-8px',
        color: 'clear-blue.0',
        fontWeight: 'semiBold',
        columnGap: 2,
    })
);

const AgentSelectItemCreateAgent = () => (
    <InteractiveText>
        <IconPlus /> {translate('selectAgent.addAgent')}
    </InteractiveText>
);

/**
 * @deprecated use new AgentSelect and FormikAgentSelectFlow instead
 **/

export const DeprecatedAgentSelect = (props) => {
    props.options.push({
        value: 'createAgent',
        label: <AgentSelectItemCreateAgent />,
    });

    return (
        <Select
            options={props.options}
            onChange={props.onChange}
            onInputChange={props.onInputChange}
            /*
             * We do the filtering in the parent component.
             * We do not want react-select to filter, that's why we kind of disable it.
             */
            filterOption={() => true}
            value={props.value}
            placeholder={translate('selectAgent.placeholder')}
            isClearable
        />
    );
};
