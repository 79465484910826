import { DrawerFormLayout } from '../../../cdl/Drawer/DrawerFormLayout';
import { Text } from '../../../cdl/Text/Text';
import { Form } from '../../../common/form-elements/Form/Form';
import { FormTextInput } from '../../../common/form-elements/TextInput/TextInput';
import { translate } from '../../../common/helpers/translate.helper';

export const MessageSettingsForm = ({ onSubmit, supplierSettingsData }) => {
    return (
        <Form height="100%" data={supplierSettingsData} onSubmit={onSubmit}>
            <DrawerFormLayout title={translate('supplier.messageSettings.editMessageSettings')}>
                <Text>{translate('supplier.messageSettings.explanation')}</Text>

                <FormTextInput
                    label={translate('supplier.messageSettings.quotationMessageLabel')}
                    dataPath={'messageSettings.quotationMessage'}
                    placeholder={translate('supplier.messageSettings.messagePlaceholder')}
                />

                <FormTextInput
                    label={translate('supplier.messageSettings.orderConfirmationMessageLabel')}
                    dataPath={'messageSettings.orderConfirmationMessage'}
                    placeholder={translate('supplier.messageSettings.messagePlaceholder')}
                />
            </DrawerFormLayout>
        </Form>
    );
};
