import { createContext, Dispatch } from 'react';

import { LayerAction, LayerStoreState } from './layerStoreReducer.ts';

type DrawerContextType = {
    store: LayerStoreState;
    dispatch: Dispatch<LayerAction>;
};

export const DrawerContext = createContext<DrawerContextType>({
    store: { layers: [] },
    dispatch: () => {
        throw new Error("Must be called within a DrawerContextProvider's child");
    },
});
