import { Form, useFormikContext } from 'formik';
import { useEffect } from 'react';

import { ProductContext } from '../../../../types/ProductContext';
import { Button } from '../../../cdl/Button/Button';
import { FormikCheckbox } from '../../../cdl/Checkbox/FormikCheckbox';
import { FormikInput } from '../../../cdl/Input/FormikInput';
import { Text } from '../../../cdl/Text/Text';
import { FormikTextArea } from '../../../cdl/TextArea/FormikTextArea';
import { FormikDebug } from '../../../common/form-elements/formik/FormikDebug';
import { FormikEnumComboBox } from '../../../common/form-elements/formik/FormikEnumComboBox';
import { FormikEnumOptionSelect } from '../../../common/form-elements/formik/FormikEnumOptionSelect';
import { FormikSupplierCompanySelect } from '../../../common/form-elements/formik/FormikSupplierCompanySelect';
import { translate } from '../../../common/helpers/translate.helper';
import { useRole } from '../../../common/hooks/useRole';
import { Box } from '../../../common/ui/Box';
import { FormikEditSupplierGroupState } from '../../../group/supplier/detail/components/EditSupplierGroupForm';

import { FormikCreateSupplierGroupState } from './CreateSupplierGroupForm';

interface SupplierGroupBaseFormProps {
    title: string;
    showSpotAllowedInputs?: boolean;
}

export const SupplierGroupBaseForm = ({ title, showSpotAllowedInputs = false }: SupplierGroupBaseFormProps) => {
    const role = useRole();
    const { isSubmitting, isValid, values, setFieldValue } = useFormikContext<
        FormikEditSupplierGroupState | FormikCreateSupplierGroupState
    >();

    useEffect(() => {
        if ('spotAllowedLubes' in values && !values.spotAllowedLubes) {
            setFieldValue('spotSupplierIdLubes', '');
        }

        if ('spotAllowedFuel' in values && !values.spotAllowedFuel) {
            setFieldValue('spotSupplierIdFuel', '');
        }
    }, [setFieldValue, values]);

    return (
        <Form style={{ height: '100%' }}>
            <Box display="grid" gridTemplateRows="auto 1fr auto" height="100%" gridTemplateColumns="100%">
                <Box padding={7} paddingBottom={6}>
                    <Text variant="title" marginBottom={6}>
                        {title}
                    </Text>
                </Box>

                <Box
                    paddingX={7}
                    display="flex"
                    flexDirection="column"
                    overflowY="auto"
                    rowGap={5}
                    paddingBottom="250px"
                >
                    <FormikInput name="name" label={translate('company.name')} markAsRequired />
                    <FormikInput name="email" label={translate('company.email')} />
                    <FormikEnumOptionSelect
                        name="type"
                        enumType="supplierGroupType"
                        label={translate('suppliergroup.type')}
                        markAsRequired
                    />
                    <FormikInput name="phone" label={translate('company.phone')} />
                    <FormikInput name="fax" label={translate('company.fax')} />

                    <Text variant="subtitle1" marginTop={5}>
                        {translate('company.companyInformation')}
                    </Text>

                    <FormikInput
                        name="headquarter"
                        label={translate('company.headquarter')}
                        placeholder={translate('company.placeholder.headquarter')}
                    />
                    <FormikTextArea name="description" label={translate('company.description')} />
                    <FormikInput
                        name="gtcUrl"
                        label={translate('company.gtcUrl')}
                        placeholder={translate('company.placeholder.gtcUrl')}
                    />

                    <Text variant="subtitle1" marginTop={5}>
                        {translate('company.address')}
                    </Text>

                    <FormikInput name="address.companyName" label={translate('company.name')} />
                    <Box display="grid" gridTemplateColumns="2fr 1fr" columnGap={5}>
                        <FormikInput name="address.street" label={translate('global.address.street')} />
                        <FormikInput name="address.streetNumber" label={translate('global.address.streetnumber')} />
                    </Box>
                    <Box display="grid" gridTemplateColumns="1fr 2fr" columnGap={5}>
                        <FormikInput name="address.zipcode" label={translate('global.address.zipcode')} />
                        <FormikInput name="address.city" label={translate('global.address.city')} />
                    </Box>
                    <FormikInput name="address.country" label={translate('global.address.country')} />

                    {role.isAdmin() ? (
                        <Box display="flex" flexDirection="column" rowGap={4}>
                            <Box display="flex" flexDirection="column" rowGap={4} alignItems="flex-start">
                                <Text variant="subtitle1" marginTop={5}>
                                    {translate('company.settings')}
                                </Text>

                                <FormikCheckbox name="testCompany" label={translate('company.testCompany')} />
                                <FormikCheckbox name="emailQuote" label={translate('company.emailquote')} />
                                <FormikCheckbox
                                    name="anonymousQuote"
                                    label={translate('suppliergroup.anonymousQuote')}
                                />
                            </Box>

                            <Text variant="subtitle1" marginTop={5}>
                                {translate('company.lubesSettings')}
                            </Text>

                            <FormikEnumComboBox
                                name="supplierProductGroups"
                                enumType="supplierProductGroup"
                                label={translate('company.supplierproductgroups')}
                                isMulti
                            />

                            <FormikEnumOptionSelect
                                name="translationSupplierProductGroup"
                                enumType="supplierProductGroup"
                                label={translate('company.translateproductgroup')}
                                isClearable
                            />

                            {showSpotAllowedInputs ? (
                                <Box display="flex" flexDirection="column" rowGap={4} paddingBottom="150px">
                                    <FormikCheckbox name="spotAllowedLubes" label={translate('company.spotallowed')} />

                                    {'spotAllowedLubes' in values && values.spotAllowedLubes ? (
                                        <FormikSupplierCompanySelect
                                            name="spotSupplierIdLubes"
                                            groupId={values.id}
                                            context={ProductContext.LUBES}
                                        />
                                    ) : null}

                                    <Text variant="subtitle1" marginTop={5}>
                                        {translate('company.fuelSettings')}
                                    </Text>

                                    <FormikCheckbox name="spotAllowedFuel" label={translate('company.spotallowed')} />

                                    {'spotAllowedFuel' in values && values.spotAllowedFuel ? (
                                        <FormikSupplierCompanySelect
                                            name="spotSupplierIdFuel"
                                            groupId={values.id}
                                            context={ProductContext.FUEL}
                                        />
                                    ) : null}
                                </Box>
                            ) : null}
                        </Box>
                    ) : null}
                    <FormikDebug />
                </Box>

                <Box padding={7}>
                    <Button
                        emphasis="high"
                        type="submit"
                        size="large"
                        disabled={!isValid}
                        isLoading={isSubmitting}
                        width="100%"
                    >
                        {translate('btn.save.default')}
                    </Button>
                </Box>
            </Box>
        </Form>
    );
};
