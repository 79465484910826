import { components } from 'react-select';
import styled from 'styled-components';

import { CL_DARK_BLUE_10 } from '../../colors';
import { CL_DARK_BLUE_4 } from '../../colors';
import { CL_DARK_BLUE_60 } from '../../colors';

const { Option } = components;

const StyledOption = styled(Option)`
    && {
        color: ${(props) => (props.isSelected ? 'black' : 'black')};
        background-color: ${(props) => (props.isSelected || props.isFocused ? CL_DARK_BLUE_4 : 'white')};
        border-bottom: 1px solid ${CL_DARK_BLUE_10};
        font-size: 14px;
        cursor: pointer;
        font-weight: 600;

        &:hover svg {
            color: ${CL_DARK_BLUE_60};
        }
    }
`;

const StyledOptionDiv = styled.div`
    display: flex;
    align: center;
    justify: center;
    align-items: center;
`;

export const CustomOption = (props) => {
    return (
        <StyledOption {...props}>
            <StyledOptionDiv>
                <div>{props.data.label}</div>
            </StyledOptionDiv>
        </StyledOption>
    );
};
