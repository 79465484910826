import { Surcharge, SurchargeButton } from '../../../admin/surcharge/SurchargeButton';
import { Waiver, WaiverButton } from '../../../admin/surcharge/WaiverButton';
import { Button } from '../../../cdl/Button/Button';
import { translate } from '../../helpers/translate.helper';
import { useProductContext } from '../../hooks/useProductContext';
import { useRole } from '../../hooks/useRole';
import { IconPlus } from '../../icons/cdl/Plus';
import { Td } from '../../Table/Td';
import { Tr } from '../../Table/Tr';
import { Box } from '../../ui/Box';
import { SampleKitButton } from '../lubes/components/SampleKitButton';
import { SampleKit } from '../lubes/components/SampleKitForm';

interface AddRowProps {
    onAddProductClick: () => void;
    onAddSurchargeClick: (surcharge: Surcharge) => void;
    onAddWaiverClick: (waiver: Waiver) => void;
    onAddSampleKitClick: (sampleKit: SampleKit) => void;
    editSurcharges?: boolean;
    addProducts?: boolean;
}

export const AddRow = ({
    onAddProductClick,
    onAddSurchargeClick,
    onAddWaiverClick,
    onAddSampleKitClick,
    editSurcharges,
    addProducts = true,
}: AddRowProps) => {
    const role = useRole();
    const { isLubes } = useProductContext();
    return (
        <Tr>
            <Td colSpan={10}>
                <Box display="flex" gap={4}>
                    {addProducts ? (
                        <Button
                            type="button"
                            onClick={onAddProductClick}
                            leadingVisual={<IconPlus height={16} width={16} />}
                        >
                            {translate('offer.edit.lubes.addProduct')}
                        </Button>
                    ) : null}

                    {editSurcharges ? (
                        <>
                            <SurchargeButton onSubmit={onAddSurchargeClick} />
                            <WaiverButton onSubmit={onAddWaiverClick} />
                        </>
                    ) : null}

                    {role?.isCustomer() && isLubes ? <SampleKitButton onSubmit={onAddSampleKitClick} /> : null}
                </Box>
            </Td>
        </Tr>
    );
};
