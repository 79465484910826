import { useField } from 'formik';

import { FormattedNumberInput } from '../NumberInputs/FormattedNumberInput';

export const FormikFormattedNumberInput = (props) => {
    const [field, , helpers] = useField(props.name);

    const onChange = (value) => {
        helpers.setValue(value);
    };

    return <FormattedNumberInput {...props} value={field.value} onChange={onChange} />;
};
