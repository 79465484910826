import { Formik } from 'formik';
import { useState } from 'react';

import { useToasts } from '../../cdl/Toast/useToasts';
import { translate } from '../../common/helpers/translate.helper';
import { Offer } from '../../offer/model/offer.model';
import { useAnonymousQuotationMessageCreate } from '../hooks/useAnonymousQuotationMessageCreate';
import { useAnonymousQuotationOfferUpdate } from '../hooks/useAnonymousQuotationOfferUpdate';
import { makeOfferUpdateRequest } from '../utils/makeOfferUpdateRequest';

import { AnonymousOfferEditForm } from './AnonymousOfferEditForm';

export const AnonymousOfferEditFormikWrapper = (props) => {
    const { addErrorToast, addToast } = useToasts();
    const offerUpdateMutation = useAnonymousQuotationOfferUpdate(props.anonymousQuotationId);
    const messageMutation = useAnonymousQuotationMessageCreate(props.anonymousQuotationId);
    const [isCancelLoading, setIsCancelLoading] = useState(false);

    const validate = (values) => {
        const errors = {};

        if (!values.dateDelivery) {
            errors.dateDelivery = 'REQUIRED';
        }

        if (!values.supplyMode) {
            errors.supplyMode = 'REQUIRED';
        }

        if (values.supplyModeIncludesDeliveryCosts === undefined) {
            errors.supplyModeIncludesDeliveryCosts = 'REQUIRED';
        }

        if (!values.quoteValidityTime) {
            errors.quoteValidityTime = 'REQUIRED';
        }

        if (values.paymentTermReference !== 'PREPAYMENT') {
            if (values.paymentTermReferenceDays === null || values.paymentTermReferenceDays === undefined) {
                errors.paymentTermReferenceDays = 'REQUIRED';
            }
        }

        if (!values.hasValidProducts()) {
            errors.invalidProducts = true;
        }

        return errors;
    };

    const onSubmit = async (values, actions) => {
        try {
            const offerRequest = {
                ...makeOfferUpdateRequest(values),
                state: 'QUOTED',
            };

            await offerUpdateMutation.mutateAsync({
                anonymousQuotationId: props.anonymousQuotationId,
                offer: offerRequest,
            });

            if (values.message) {
                await messageMutation.mutateAsync({
                    anonymousQuotationId: props.anonymousQuotationId,
                    message: values.message,
                });
            }

            // set values for updated offer optimisticly, because there is no response
            actions.setValues(
                new Offer({
                    ...offerRequest,
                    dateDelivery: values.dateDelivery,
                    vendorReference: values.vendorReference,
                    quoteValidityTime: values.quoteValidityTime,
                    message: '',
                    state: 'QUOTED',
                })
            );

            switch (props.offer.state) {
                case 'ENQUIRY':
                    addToast(translate('anonymousQuotation.offerCreated'));
                    break;
                default:
                    addToast(translate('anonymousQuotation.offerSuccessfullyUpdated'));
            }
        } catch {
            addErrorToast(translate('anonymousQuotation.failedToUpdateOffer'));
        } finally {
            actions.setSubmitting(false);
        }
    };

    const onCancelOffer = async () => {
        try {
            setIsCancelLoading(true);

            const offerRequest = {
                ...makeOfferUpdateRequest(props.offer),
                state: 'ENQUIRY_DECLINED',
            };

            await offerUpdateMutation.mutateAsync({
                anonymousQuotationId: props.anonymousQuotationId,
                offer: offerRequest,
            });
        } catch {
            addErrorToast(translate('anonymousQuotation.failedToUpdateOffer'));
        } finally {
            setIsCancelLoading(false);
        }
    };

    return (
        <Formik initialValues={props.offer} validateOnMount validate={validate} onSubmit={onSubmit} enableReinitialize>
            {(formikProps) => (
                <AnonymousOfferEditForm
                    {...formikProps}
                    offer={props.offer}
                    anonymousQuotationId={props.anonymousQuotationId}
                    onCancelOffer={onCancelOffer}
                    isCancelLoading={isCancelLoading}
                />
            )}
        </Formik>
    );
};
