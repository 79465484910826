import { PortMessage } from '../../types/PortMessage';
import { formatPort } from '../common/helpers/formatPort.helper';
import { Box } from '../common/ui/Box';
import { KeyPortHighlight } from '../keyport/KeyPortHighlight';

interface PortProps {
    port: PortMessage;
    vesselId?: string;
    short?: boolean;
    country?: boolean;
    locode?: boolean;
    highlightColor?: string;
    showTooltip?: boolean;
}

export const Port = ({ port, vesselId, short, country, locode, highlightColor, showTooltip }: PortProps) => {
    const formattedPort = formatPort({ port, short, country, locode });

    return (
        <Box>
            {formattedPort}
            &nbsp;
            <KeyPortHighlight portId={port.id} vesselId={vesselId} color={highlightColor} showTooltip={showTooltip} />
        </Box>
    );
};
