import { FuelShowOfferProductTable } from '../../../common/FuelShowOfferProductTable/FuelShowOfferProductTable';
import { ConfirmDeliveryPopup } from '../common/components/ConfirmDeliveryPopup';

export const FuelConfirmDeliveryPopup = ({ onDismiss, offer, onConfirm, isSubmitting, isOpen }) => {
    return (
        <ConfirmDeliveryPopup
            onDismiss={onDismiss}
            isOpen={isOpen}
            offer={offer}
            onConfirm={onConfirm}
            isSubmitting={isSubmitting}
        >
            <FuelShowOfferProductTable offer={offer} showPrices={false} />
        </ConfirmDeliveryPopup>
    );
};
