import { createFileRoute } from '@tanstack/react-router';

import { getCustomerDetails } from '../common/api/clients/company.api';
import { getCustomerSettings } from '../common/api/clients/companySettings.api';
import { queryKeys } from '../common/api/queryKeys';
import { CustomerDetailPage } from '../company/customer/detail/CustomerDetailPage';

export const Route = createFileRoute('/_app/customer/detail/$id')({
    async loader({ params, context }) {
        context.queryClient.ensureQueryData({
            queryKey: queryKeys.customerDetails(params.id),
            queryFn: () => getCustomerDetails(params.id),
        });
        context.queryClient.prefetchQuery({
            queryKey: queryKeys.customerSettings(params.id),
            queryFn: () => getCustomerSettings(params.id),
        });
    },
    component: CustomerDetailPage,
});
