import { ChangeEventHandler } from 'react';

import { ApproverMessage } from '../../../../types/ApproverMessage';
import { Checkbox } from '../../../cdl/Checkbox/Checkbox';
import { Label } from '../../../cdl/Label/Label.tsx';
import { Radio, RadioGroupItem } from '../../../cdl/RadioGroup/RadioGroup';

import { ApproverLabel } from './ApproverLabel';
import { ApproverListWrapper } from './ApproverListWrapper';

interface ApproverListProps {
    approvers: ApproverMessage[];
    isApproverSelected: (approver: ApproverMessage) => boolean;
    onApproverChange: <E>(approver: ApproverMessage) => ChangeEventHandler<E>;
    preventMultipleApprovers: boolean;
}

export const ApproverList = (props: ApproverListProps) => {
    if (props.preventMultipleApprovers) {
        return (
            <ApproverListWrapper>
                {props.approvers.map((approver) => {
                    return (
                        <RadioGroupItem key={approver.emailAddress}>
                            <Radio
                                value={approver.emailAddress}
                                onChange={props.onApproverChange(approver)}
                                checked={props.isApproverSelected(approver)}
                            />
                            <Label>
                                <ApproverLabel approver={approver} />
                            </Label>
                        </RadioGroupItem>
                    );
                })}
            </ApproverListWrapper>
        );
    } else {
        return (
            <ApproverListWrapper>
                {props.approvers.map((approver) => {
                    return (
                        <Checkbox
                            checked={props.isApproverSelected(approver)}
                            key={approver.emailAddress}
                            onChange={props.onApproverChange(approver)}
                            label={<ApproverLabel approver={approver} />}
                        />
                    );
                })}
            </ApproverListWrapper>
        );
    }
};
