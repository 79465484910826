import { useLocation, useNavigate } from '@tanstack/react-router';
import { Formik, FormikErrors, setIn } from 'formik';
import { useEffect, useState } from 'react';

import { ProductContext } from '../../../../types/ProductContext';
import { SamplekitMessage } from '../../../../types/SamplekitMessage';
import { SupplierMessage } from '../../../../types/SupplierMessage';
import { validateOrderCreation, validateOrderUpdate } from '../../../common/api/clients/order.api';
import { CenteredPageError } from '../../../common/Error/CenteredPageError';
import { translate } from '../../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle';
import { useRole } from '../../../common/hooks/useRole';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { Item } from '../../../common/models/item.model';
import { ItemModel } from '../../../common/models/ItemModel';
import { OrderState } from '../../model/OrderModel';
import { useOrder } from '../hooks/useOrder';

import { FormikLubesCreateEnquiryForm } from './components/FormikLubesCreateEnquiryForm';
import { FormikSpotModal } from './components/FormikSpotModal';
import { useEnquiryCreate } from './hooks/useEnquiryCreate';
import { useEnquiryUpdate } from './hooks/useEnquiryUpdate';
import { useMarkOrderRead } from './hooks/useMarkOrderRead';
import { buildOrderRequest } from './util/buildOrderRequest';
import { isDateDeliveryInPast } from './util/isDateDeliveryInPast';

export interface FormikCreateEnquiryValues {
    customerId: string;
    vesselId: string;
    portId: string;
    dateDelivery: string | null;
    buyerReference: string;
    agentId: string;
    items: ItemModel[];
    samplekits: SamplekitMessage[];
    testOrder: boolean;
    assignedSupplierIds: string[];
    assignedSuppliers: SupplierMessage[];
    receiverSupplierIds: string[];
    spot: boolean;
    comment?: string;
    creationComment?: string;
    state?: OrderState;
}

interface LubesCreateEnquiryProps {
    orderId?: string;
}

export const LubesCreateEnquiryFlow = ({ orderId }: LubesCreateEnquiryProps) => {
    const [spotModalOpen, setSpotModalOpen] = useState(false);
    const role = useRole();

    const { mutateAsync: createEnquiryAsync } = useEnquiryCreate();
    const { mutateAsync: updateEnquiryAsync } = useEnquiryUpdate();
    const { mutate: markOrderRead } = useMarkOrderRead();
    const navigate = useNavigate();
    const orderDetailsQuery = useOrder(orderId);
    const location = useLocation();

    const {
        order: orderFromState,
        vesselId: vesselIdFromState,
        customerId: customerIdFromState,
        portId: portIdFromState,
        items: itemsFromState,
    } = location.state;

    useEffect(() => {
        if (orderId && orderDetailsQuery.data && !role.isAdmin() && !orderDetailsQuery.data.read) {
            markOrderRead({ orderId, read: true });
        }
    }, [orderId, markOrderRead, orderDetailsQuery.data, orderDetailsQuery.data?.read, role]);

    const isDraftEnquiry = !!orderId;
    const isCopiedFromOrder = !!orderFromState;
    const order = orderDetailsQuery.data;

    useDocumentTitle(isDraftEnquiry ? translate('order.createNewDraft') : translate('order.createnew'));

    if (isDraftEnquiry && orderDetailsQuery.isPending) {
        return <LoadingIndicator variant="page" />;
    }

    if (isDraftEnquiry && orderDetailsQuery.isError) {
        return <CenteredPageError />;
    }

    if (orderId && order?.state !== OrderState.DRAFT) {
        navigate({ to: '/order/$id', params: { id: order.id } });
    }

    const handleSendEnquiry = async (values: FormikCreateEnquiryValues) => {
        if (isDraftEnquiry) {
            await updateEnquiryAsync({ order: buildOrderRequest(values, OrderState.OPEN), orderId });
        } else {
            await createEnquiryAsync(buildOrderRequest(values, OrderState.OPEN));
        }
    };

    const formikValidate = async (
        values: FormikCreateEnquiryValues
    ): Promise<FormikErrors<FormikCreateEnquiryValues>> => {
        let errors: FormikErrors<FormikCreateEnquiryValues> = {};

        if (!values.vesselId) {
            errors.vesselId = translate('errormessage.empty');
        }

        if (!values.portId) {
            errors.portId = translate('errormessage.empty');
        }

        if (!values.dateDelivery) {
            errors.dateDelivery = translate('errormessage.empty');
        }

        if (isDateDeliveryInPast(values.dateDelivery)) {
            errors.dateDelivery = translate('errormessage.isInPast');
        }

        if (!values.items || values.items.length === 0) {
            errors.items = translate('errormessage.empty');
        } else {
            values.items.forEach((item, index) => {
                if (!item.productId) {
                    errors = setIn(errors, `items.${index}.productId`, true);
                }

                if (!item.units || Number(item.units) === 0) {
                    errors = setIn(errors, `items.${index}.units`, true);
                }

                if (!item.unitSize || Number(item.unitSize) === 0) {
                    errors = setIn(errors, `items.${index}.unitSize`, true);
                }
            });
        }

        if (values.samplekits) {
            values.samplekits.forEach((samplekit, index) => {
                if (!samplekit.name) {
                    errors = setIn(errors, `samplekits.${index}.name`, true);
                }

                if (!samplekit.quantity || Number(samplekit.quantity) === 0) {
                    errors = setIn(errors, `samplekits.${index}.quantity`, true);
                }
            });
        }

        if ((!values.receiverSupplierIds || values.receiverSupplierIds.length === 0) && values.spot === false) {
            errors.receiverSupplierIds = translate('order.supplierRequired');
        }

        try {
            if (values.buyerReference) {
                const validationRequest = {
                    type: ProductContext.LUBES,
                    vesselId: values.vesselId,
                    buyerReference: values.buyerReference,
                };
                const response = isDraftEnquiry
                    ? await validateOrderUpdate(validationRequest, orderId)
                    : await validateOrderCreation(validationRequest);

                const buyerReferenceError = response.validationErrors.find((error) => error.path === 'buyerReference');

                if (buyerReferenceError) {
                    errors.buyerReference = buyerReferenceError.message;
                }
            }
        } catch {
            return errors;
        }

        return errors;
    };

    const handleInitialItems = (items: ItemModel[]): ItemModel[] => {
        if (items?.length) {
            return items.map((item): ItemModel => {
                // @ts-ignore
                return new Item({
                    productId: item.productId || '',
                    units: item.units || '',
                    packType: item.packType || 'BULK',
                    unitSize: item.unitSize || 1,
                    unit: item.unit || 'L',
                    new: true,
                    deleted: false,
                    total: item.total || undefined,
                    product: item.product || undefined,
                });
            });
        }

        return [
            // @ts-ignore
            new Item({
                productId: '',
                new: true,
                unit: 'L',
                units: '',
                packType: 'BULK',
                unitSize: 1,
                deleted: false,
            }),
        ];
    };

    const initialItemsFromOrder = order?.items.length ? order.items : undefined;
    const initialItemsFromState = itemsFromState || orderFromState?.items || undefined;

    const initialValues: FormikCreateEnquiryValues = {
        vesselId: orderFromState?.vesselId || vesselIdFromState || order?.vesselId || '',
        customerId: orderFromState?.customerId || customerIdFromState || order?.customerId || '',
        portId: orderFromState?.portId || portIdFromState || order?.portId || '',
        dateDelivery: orderFromState?.dateDelivery || order?.dateDelivery || null,
        testOrder: orderFromState?.testOrder || order?.testOrder || role.group.testCompany,
        buyerReference: orderFromState?.buyerReference || order?.buyerReference || '',
        comment: orderFromState?.comment || order?.comment || '',
        items: handleInitialItems(initialItemsFromState || initialItemsFromOrder),
        samplekits: orderFromState?.samplekits || order?.samplekits || [],
        assignedSupplierIds: [],
        assignedSuppliers: [],
        receiverSupplierIds: orderFromState?.receiverSupplierIds || order?.receiverSupplierIds || [],
        creationComment: order?.creationComment || '',
        spot: orderFromState?.spot || order?.spot || false,
        agentId: orderFromState?.agentId || order?.agentId || '',
        state: order?.state,
    };

    const onCloseClick = (dirty: boolean) => {
        if (!dirty) {
            history.back();
            return;
        }

        const confirmed = confirm(translate('confirmation.warning'));
        if (confirmed) {
            history.back();
        }
    };

    return (
        <Formik<FormikCreateEnquiryValues>
            initialValues={initialValues}
            initialTouched={{
                receiverSupplierIds:
                    (isDraftEnquiry && initialValues.receiverSupplierIds?.length) || isCopiedFromOrder ? true : false,
                buyerReference: !!(isCopiedFromOrder && initialValues.buyerReference),
            }}
            validate={formikValidate}
            onSubmit={(values, formikHelpers) => {
                if (values.spot) {
                    formikHelpers.setSubmitting(false);
                    setSpotModalOpen(true);
                } else {
                    return handleSendEnquiry(values);
                }
            }}
        >
            {(formikProps) => {
                return (
                    <div>
                        <FormikLubesCreateEnquiryForm {...formikProps} orderId={orderId} onCloseClick={onCloseClick} />

                        <FormikSpotModal
                            onSubmit={() => {
                                formikProps.setSubmitting(true);
                                void handleSendEnquiry(formikProps.values);
                            }}
                            isOpen={spotModalOpen}
                            onDismiss={() => setSpotModalOpen(false)}
                        />
                    </div>
                );
            }}
        </Formik>
    );
};
