import { Fragment, useState } from 'react';
import styled from 'styled-components';

import { Text } from '../../cdl/Text/Text';
import { TextButton } from '../../common/buttons/TextButton';
import { formatMoney } from '../../common/helpers/formatMoney.helper';
import { translate } from '../../common/helpers/translate.helper';
import { PortDirectory } from '../../common/port-directory/PortDirectory';
import { Triangle } from '../../common/Triangle';
import { Box } from '../../common/ui/Box';
import { Flex } from '../../common/ui/Flex';

import { ItemsPopup } from './ItemsPopup';

const StyledTriangle = styled(Triangle)`
    vertical-align: middle;
    margin-right: 5px;
    position: relative;
    top: -1px;
`;

const Price = ({ price }) => {
    const getColorForPrice = (price) => {
        if (price.bestPrice) {
            return 'confirming-green.0';
        }

        if (price.worstPrice) {
            return 'signal-red.0';
        }

        return 'dark-blue.0';
    };

    const worstPriceDifference = price.worstPriceDifference;
    const formattedWorstPriceDifference = worstPriceDifference
        ? formatMoney({
              value: Math.abs(worstPriceDifference.value),
              currency: worstPriceDifference.currency,
              minimumFractionDigits: 2,
              maximumFractionDigits: 4,
          })
        : null;

    const formattedTotalPrice = price.totalPrice
        ? formatMoney({
              value: price.totalPrice.value,
              currency: price.totalPrice.currency,
              minimumFractionDigits: 2,
              maximumFractionDigits: 4,
          })
        : null;

    if (!formattedTotalPrice) {
        return (
            <Text size={24} color="dark-blue.0" marginBottom="18px">
                -
            </Text>
        );
    }

    if (!worstPriceDifference) {
        return (
            <Text size={24} color={getColorForPrice(price)} marginBottom="18px">
                {formattedTotalPrice}
            </Text>
        );
    }

    return (
        <Box>
            <Text size={24} color={getColorForPrice(price)}>
                {formattedTotalPrice}
            </Text>

            <Text size={14} color="confirming-green.0" display="block">
                <StyledTriangle direction="down" />
                {formattedWorstPriceDifference}
            </Text>
        </Box>
    );
};

export const PriceBoxContent = ({ price }) => {
    const [popupOpen, setPopupOpen] = useState(false);

    const onPopupButtonClick = (event) => {
        event.preventDefault();
        event.stopPropagation();

        setPopupOpen(true);
    };

    return (
        <Fragment>
            <Text size={16} weight="semi">
                {price.supplier.supplierGroup.name}
            </Text>

            <Flex marginTop={3}>
                <Price price={price} />
            </Flex>

            <Flex marginTop={4}>
                {price.ppl ? (
                    <Text size={14} color="dark-blue.3">
                        {translate('compare.ppl', {
                            ppl: formatMoney({
                                value: price.ppl.value,
                                currency: price.ppl.currency,
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 4,
                            }),
                        })}
                    </Text>
                ) : null}

                <Text size={14} color="dark-blue.3" marginLeft={price.ppl ? 3 : 0}>
                    {translate('compare.volume', {
                        volume: price.volume,
                    })}
                    L
                </Text>
            </Flex>

            <Box marginTop={2}>
                <TextButton onClick={onPopupButtonClick}>{translate('compare.itemsPopup.button')}</TextButton>
            </Box>

            <Box position="absolute" bottom={5} left={5} right={5}>
                <PortDirectory portId={price.portId} supplierGroupId={price.supplier.supplierGroup.id} />
            </Box>

            <ItemsPopup price={price} onDismiss={() => setPopupOpen(false)} open={popupOpen} />
        </Fragment>
    );
};
