import { useNavigate } from '@tanstack/react-router';
import { useState } from 'react';

import { Button } from '../../../cdl/Button/Button';
import { Callout } from '../../../cdl/Callout/Callout';
import { ButtonGroup } from '../../../common/buttons/ButtonGroup';
import { DeprecatedFormikAgentSelect } from '../../../common/form-elements/formik/DeprecatedFormikAgentSelect';
import { DeprecatedFormikDatePicker } from '../../../common/form-elements/formik/DeprecatedFormikDatePicker';
import { DeprecatedFormikPortSelect } from '../../../common/form-elements/formik/DeprecatedFormikPortSelect';
import { DeprecatedFormikPortSelectWithSchedules } from '../../../common/form-elements/formik/DeprecatedFormikPortSelectWithSchedules';
import { FormikDebug } from '../../../common/form-elements/formik/FormikDebug';
import { FormikTextInput } from '../../../common/form-elements/formik/FormikTextInput';
import { useFormikDirtyWarning } from '../../../common/form-elements/formik/useFormikDirtyWarning';
import { FormikFuelProductTable } from '../../../common/FormikProductTable/fuel/FormikFuelProductTable';
import { translate } from '../../../common/helpers/translate.helper';
import { useRole } from '../../../common/hooks/useRole';
import { useSchedules } from '../../../common/hooks/useSchedules';
import { IconInfoCircle } from '../../../common/icons/cdl/InfoCircle';
import { StatusBar } from '../../../common/StatusBar/StatusBar';
import { StatusBarType } from '../../../common/StatusBar/StatusBarType';
import { Box } from '../../../common/ui/Box';
import { CardVariation } from '../../../common/ui/CardVariation';
import { Grid, GridItem } from '../../../common/ui/Grid';
import { OfferState } from '../../model/OfferState';
import { FormikFuelDeliveryCostsSelect } from '../../quote/fuel/components/FormikFuelDeliveryCostsSelect';
import { FormikFuelSupplyTypeSelect } from '../../quote/fuel/components/FormikFuelSupplyTypeSelect';
import { FormikPaymentTerms } from '../../quote/fuel/components/FormikPaymentTerms';

export const OfferEditForm = (props) => {
    useFormikDirtyWarning();
    const navigate = useNavigate();
    const role = useRole();
    const { schedules } = useSchedules(props.values.vessel.imo);

    const [items] = useState(props.values.items);

    const navigateToOffer = (offerId) => {
        navigate({ to: '/fuel/offer/$id', params: { id: offerId } });
    };

    const onCancelButtonClick = () => {
        if (props.dirty) {
            const confirmed = confirm(translate('confirmation.warning'));
            if (confirmed) {
                navigateToOffer(props.values.id);
            }
        } else {
            navigateToOffer(props.values.id);
        }
    };

    const onSubmitClick = () => {
        if (!props.isSubmitting) {
            return props.submitForm();
        }
    };

    const onPortSelect = (port) => {
        if (!schedules || !schedules.length) {
            return;
        }

        const matchingSchedule = schedules.find((schedule) => schedule.port?.id === port.id);

        if (!matchingSchedule) {
            return;
        }

        if (matchingSchedule.agentIds?.length === 1) {
            props.setFieldValue('agentId', matchingSchedule.agentIds[0], false);
        }
    };

    return (
        <Box padding={6} display="flex" flexDirection="column" gap={6}>
            <CardVariation paddingX={0} paddingY={0}>
                <StatusBar
                    headline={
                        props.values.isOrder()
                            ? translate('offer.edit.fuel.statusBar.orderHeadline')
                            : translate('offer.edit.fuel.statusBar.offerHeadline')
                    }
                    subHeadline={translate('offer.edit.fuel.statusBar.subHeadline')}
                    type={StatusBarType.WHITE}
                    icon={<IconInfoCircle />}
                />
            </CardVariation>

            <CardVariation marginTop={6}>
                <Grid>
                    <GridItem width={1 / 2}>
                        {role.isCustomer() ? (
                            <DeprecatedFormikPortSelectWithSchedules
                                name="portId"
                                schedules={schedules}
                                onPortSelect={onPortSelect}
                            />
                        ) : (
                            <DeprecatedFormikPortSelect name="portId" />
                        )}
                    </GridItem>
                    {role.isSupplier() ? (
                        <>
                            <GridItem width={1 / 2}>
                                <FormikFuelSupplyTypeSelect name="supplyMode" />
                            </GridItem>

                            <GridItem width={1 / 2}>
                                <FormikFuelDeliveryCostsSelect
                                    name={'supplyModeIncludesDeliveryCosts'}
                                    label={translate('offer.quotationProcess.fuel.deliveryCosts')}
                                    required
                                />
                            </GridItem>
                        </>
                    ) : null}

                    <GridItem width={1 / 2}>
                        <DeprecatedFormikDatePicker
                            required
                            label={translate('offer.edit.fuel.estimatedDeliveryDate')}
                            name="dateDelivery"
                        />
                    </GridItem>

                    {role.isSupplier() ? (
                        <>
                            <GridItem width={1 / 2}>
                                <FormikTextInput
                                    name="vendorReference"
                                    label={translate('offer.edit.fuel.vendorReference')}
                                    placeholder={translate('offer.edit.fuel.vendorReferencePlaceholder')}
                                />
                            </GridItem>

                            <GridItem width={1 / 2}>
                                <FormikPaymentTerms label={translate('offer.edit.fuel.paymentTerms')} required />
                            </GridItem>
                        </>
                    ) : null}
                    {role.isCustomer() ? (
                        <>
                            <GridItem width={1 / 2}>
                                <FormikTextInput
                                    name="buyerReference"
                                    label={translate('offer.edit.fuel.buyerReference')}
                                    placeholder={translate('offer.edit.fuel.buyerReferencePlaceholder')}
                                />
                                {props.errors.buyerReference ? (
                                    <Callout
                                        variant="negative"
                                        description={translate('offer.edit.fuel.duplicateBuyerReference')}
                                    />
                                ) : null}
                            </GridItem>
                            <GridItem width={1 / 2}>
                                <DeprecatedFormikAgentSelect name="agentId" />
                            </GridItem>
                            <GridItem width={1 / 2}>
                                <FormikTextInput
                                    name="surveyor.name"
                                    label={translate('offer.edit.fuel.surveyorName')}
                                    placeholder={translate('offer.edit.fuel.surveyorNamePlaceholder')}
                                />
                            </GridItem>
                            <GridItem width={1 / 2}>
                                <FormikTextInput
                                    name="surveyor.emailAddress"
                                    label={translate('offer.edit.fuel.surveyorEmailAddress')}
                                    placeholder={translate('offer.edit.fuel.surveyorEmailAddressPlaceholder')}
                                />
                            </GridItem>
                        </>
                    ) : null}
                </Grid>
            </CardVariation>

            <CardVariation marginTop={6}>
                <FormikFuelProductTable
                    config={{
                        showPhysicalSupplierColumn: role.isSupplier(),
                        editEnergyContent: role.isSupplier(),
                        editPrices: role.isSupplier(),
                        editSurcharges:
                            role.isSupplier() || (role.isCustomer() && props.values.state === OfferState.DELIVERED),
                        showCounterOffers: role.isSupplier(),
                        initialItems: items,
                    }}
                />

                <Box display="flex" justifyContent="center" marginTop={9}>
                    <ButtonGroup>
                        <Button type="button" emphasis="medium" onClick={onCancelButtonClick}>
                            {translate('offer.edit.fuel.cancel')}
                        </Button>
                        <Button
                            emphasis="high"
                            disabled={!props.isValid}
                            onClick={onSubmitClick}
                            isLoading={props.isSubmitting}
                        >
                            {props.values.isOrder()
                                ? translate('offer.edit.fuel.updateOrder')
                                : translate('offer.edit.fuel.updateOffer')}
                        </Button>
                    </ButtonGroup>
                </Box>
            </CardVariation>

            <FormikDebug />
        </Box>
    );
};
