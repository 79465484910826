import { Text } from '../../../cdl/Text/Text';
import { DataSheet } from '../../../common/DataSheet/DataSheet';
import { formatDateDelivery } from '../../../common/helpers/formatDateDelivery.helper';
import { formatDateTime } from '../../../common/helpers/formatDateTime.helper';
import { formatVessel } from '../../../common/helpers/formatVessel.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { getTableValidity } from '../../../common/Validity/getTableValidity';
import { YesOrNo } from '../../../common/YesOrNo/YesOrNo';
import { Port } from '../../../port/Port';

export const OrderSummary = ({ order }) => {
    return (
        <DataSheet>
            <DataSheet.Label>{translate('fuelOrder.summary.vessel')}</DataSheet.Label>
            <Text>{formatVessel({ vessel: order.vessel })}</Text>

            {order.port ? (
                <>
                    <DataSheet.Label>{translate('fuelOrder.summary.port')}</DataSheet.Label>
                    <Port port={order.port} />
                </>
            ) : null}

            {order.dateDelivery ? (
                <>
                    <DataSheet.Label>{translate('order.detail.coreData.dateDelivery')}</DataSheet.Label>
                    <Text>{formatDateDelivery({ date: order.dateDelivery })}</Text>
                </>
            ) : null}

            {order.agentInformation?.name ? (
                <>
                    <DataSheet.Label>{translate('fuelOrder.summary.agent')}</DataSheet.Label>
                    <Text>{order.agentInformation.name}</Text>
                </>
            ) : null}

            {order.eta ? (
                <>
                    <DataSheet.Label>{translate('fuelOrder.summary.eta')}</DataSheet.Label>
                    <Text>
                        {formatDateTime({
                            date: order.eta,
                            timeZoneId: order.port.timeZoneId,
                        })}
                    </Text>
                </>
            ) : null}

            {order.etd ? (
                <>
                    <DataSheet.Label>{translate('fuelOrder.summary.etd')}</DataSheet.Label>
                    <Text>
                        {formatDateTime({
                            date: order.etd,
                            timeZoneId: order.port.timeZoneId,
                        })}
                    </Text>
                </>
            ) : null}

            {order.buyerReference ? (
                <>
                    <DataSheet.Label>{translate('fuelOrder.summary.buyerReference')}</DataSheet.Label>
                    <Text>{order.buyerReference} </Text>
                </>
            ) : null}

            {order.validUntil ? (
                <>
                    <DataSheet.Label>{translate('fuelOrder.summary.validity')}</DataSheet.Label>
                    <Text>{getTableValidity(order.validUntil)}</Text>
                </>
            ) : null}

            <DataSheet.Label>{translate('order.detail.coreData.spotSuppliers')}</DataSheet.Label>
            <Text>
                <YesOrNo value={!!order.spot} />
            </Text>
        </DataSheet>
    );
};
