import {
    Menu as ReachMenu,
    MenuButton as ReachMenuButton,
    MenuItem as ReachMenuItem,
    MenuList as ReachMenuList,
} from '@reach/menu-button';
import { Fragment } from 'react';
import styled from 'styled-components';

import { Tag } from '../../cdl/Tag/Tag';
import { Text } from '../../cdl/Text/Text';
import { APPROVAL_STATE } from '../../common/approvalState.constant';
import { CL_DARK_BLUE_40 } from '../../common/colors';
import { formatDate } from '../../common/helpers/formatDate.helper';
import { formatMoney } from '../../common/helpers/formatMoney.helper';
import { translate } from '../../common/helpers/translate.helper';
import { useEnums } from '../../common/hooks/useEnums';
import { Paragraph } from '../../common/ui/Paragraph';
import { IconChevronDown } from '../../common/icons/cdl/ChevronDown';
import { Box } from '../../common/ui/Box';
import { get } from '../../common/ui/get.js';

const MetaData = styled.p`
    font-size: 12px;
    margin: 0;
`;

const MenuItemContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 24px;
    border-bottom: 1px solid ${CL_DARK_BLUE_40};

    &:last-child {
        border-bottom: 0;
    }
`;

const Left = styled.div`
    width: 100px;
    flex-shrink: 0;
`;

const Right = styled.div`
    margin-left: 20px;
    white-space: normal;
`;

const ClickableArea = styled.div`
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 1px solid ${get('colors.border.muted')};
    border-radius: 4px;
    padding: 8px;
    width: 350px;
    user-select: none;
    cursor: pointer;
    height: 32px;
`;

const ClickableAreaMetaData = styled(MetaData)`
    margin-left: 8px;
`;

const RightSection = ({ approvalRequest }) => {
    switch (approvalRequest.state) {
        case APPROVAL_STATE.APPROVED: {
            const makeHeadline = () => {
                if (approvalRequest.responseMessage) {
                    return translate('approvalRequest.dropdown.approved.headlineComment', {
                        responseMessage: approvalRequest.responseMessage,
                    });
                }

                return translate('approvalRequest.dropdown.approved.headlineNoComment');
            };

            return (
                <>
                    {approvalRequest.total ? (
                        <Text size={14} weight="bold">
                            {translate('approvalRequest.dropdown.total', {
                                value: formatMoney({
                                    value: approvalRequest.total.value,
                                    currency: approvalRequest.total.currency,
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 3,
                                }),
                            })}
                        </Text>
                    ) : null}
                    <Paragraph>{makeHeadline()}</Paragraph>
                    <MetaData>
                        {translate('approvalRequest.dropdown.approved.subHeadline', {
                            date: formatDate({
                                date: approvalRequest.dateApproved,
                            }),
                            author: approvalRequest.controllerEmailAddress,
                        })}
                    </MetaData>
                </>
            );
        }
        case APPROVAL_STATE.DECLINED: {
            const makeHeadline = () => {
                if (approvalRequest.responseMessage) {
                    return translate('approvalRequest.dropdown.declined.headlineComment', {
                        responseMessage: approvalRequest.responseMessage,
                    });
                }

                return translate('approvalRequest.dropdown.declined.headlineNoComment');
            };

            return (
                <>
                    {approvalRequest.total ? (
                        <Text size={14} weight="bold">
                            {translate('approvalRequest.dropdown.total', {
                                value: formatMoney({
                                    value: approvalRequest.total.value,
                                    currency: approvalRequest.total.currency,
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 3,
                                }),
                            })}
                        </Text>
                    ) : null}
                    <Paragraph>{makeHeadline()}</Paragraph>
                    <MetaData>
                        {translate('approvalRequest.dropdown.declined.subHeadline', {
                            date: formatDate({
                                date: approvalRequest.dateDeclined,
                            }),
                            author: approvalRequest.controllerEmailAddress,
                        })}
                    </MetaData>
                </>
            );
        }
        case APPROVAL_STATE.WITHDRAWN: {
            return (
                <>
                    {approvalRequest.total ? (
                        <Text size={14} weight="bold">
                            {translate('approvalRequest.dropdown.total', {
                                value: formatMoney({
                                    value: approvalRequest.total.value,
                                    currency: approvalRequest.total.currency,
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 3,
                                }),
                            })}
                        </Text>
                    ) : null}
                    <Paragraph>
                        {translate('approvalRequest.dropdown.withdrawn.headline', {
                            controllerEmailAddress: approvalRequest.controllerEmailAddress,
                        })}
                    </Paragraph>
                    <MetaData>
                        {translate('approvalRequest.dropdown.withdrawn.subHeadline', {
                            date: formatDate({
                                date: approvalRequest.dateWithdrawn,
                            }),
                        })}
                    </MetaData>
                </>
            );
        }
        case APPROVAL_STATE.EXPIRED: {
            if (approvalRequest.updatedByUser) {
                return (
                    <>
                        <Paragraph>{translate('approvalRequest.dropdown.expiredUser.headline')}</Paragraph>
                        <MetaData>
                            {translate('approvalRequest.dropdown.expiredUser.subHeadline', {
                                date: formatDate({
                                    date: approvalRequest.dateExpired,
                                }),
                                author: `${approvalRequest.updatedByUser.firstname} ${approvalRequest.updatedByUser.lastname}`,
                            })}
                        </MetaData>
                    </>
                );
            }

            return (
                <>
                    <Paragraph>{translate('approvalRequest.dropdown.expiredSystem.headline')}</Paragraph>
                    <MetaData>
                        {translate('approvalRequest.dropdown.expiredSystem.subHeadline', {
                            date: formatDate({
                                date: approvalRequest.dateExpired,
                            }),
                        })}
                    </MetaData>
                </>
            );
        }
        case APPROVAL_STATE.PENDING: {
            const makeHeadline = () => {
                if (approvalRequest.requestMessage) {
                    return translate('approvalRequest.dropdown.pending.headlineComment', {
                        requestMessage: approvalRequest.requestMessage,
                    });
                }

                return translate('approvalRequest.dropdown.pending.headlineNoComment');
            };

            return (
                <>
                    <Paragraph>{makeHeadline()}</Paragraph>
                    <MetaData>
                        {translate('approvalRequest.dropdown.pending.subHeadline', {
                            date: formatDate({
                                date: approvalRequest.dateCreated,
                            }),
                            author: `${approvalRequest.createdByUser.firstname} ${approvalRequest.createdByUser.lastname}`,
                        })}
                    </MetaData>
                </>
            );
        }
    }
};

const getTagVariant = (approvalState) => {
    switch (approvalState) {
        case APPROVAL_STATE.APPROVED:
            return 'positive';
        case APPROVAL_STATE.PENDING:
            return 'default';
        case APPROVAL_STATE.WITHDRAWN:
            return 'attention';
        case APPROVAL_STATE.DECLINED:
        case APPROVAL_STATE.EXPIRED:
            return 'negative';
    }
};

const MenuItem = ({ approvalRequest }) => {
    const { getHumanReadableValue } = useEnums();

    return (
        <MenuItemContainer>
            <Left>
                <Tag variant={getTagVariant(approvalRequest.state)}>
                    {getHumanReadableValue('approvalRequestState', approvalRequest.state)}
                </Tag>
            </Left>

            <Right>
                <RightSection approvalRequest={approvalRequest} />
            </Right>
        </MenuItemContainer>
    );
};

const MenuButton = ({ approvalRequest }) => {
    const { getHumanReadableValue } = useEnums();

    return (
        <ClickableArea>
            <Tag variant={getTagVariant(approvalRequest.state)}>
                {getHumanReadableValue('approvalRequestState', approvalRequest.state)}
            </Tag>

            <ClickableAreaMetaData>
                {translate('approvalRequest.dropdown.latestActivity', {
                    date: formatDate({
                        date: approvalRequest.dateUpdated,
                    }),
                })}
            </ClickableAreaMetaData>

            <Box marginLeft="auto" color="foreground.subtle">
                <IconChevronDown />
            </Box>
        </ClickableArea>
    );
};

export const ApprovalRequestsDropdown = ({ approvalRequests }) => {
    const latestApprovalRequest = approvalRequests[0];

    return (
        <ReachMenu>
            {() => (
                <Fragment>
                    <ReachMenuButton>
                        <MenuButton approvalRequest={latestApprovalRequest} />
                    </ReachMenuButton>

                    <ReachMenuList style={{ position: 'relative', zIndex: 2 }}>
                        {approvalRequests.map((approvalRequest) => {
                            return (
                                <ReachMenuItem
                                    style={{
                                        minWidth: '500px',
                                        maxWidth: '600px',
                                    }}
                                    key={approvalRequest.id}
                                    onSelect={() => {}}
                                >
                                    <MenuItem approvalRequest={approvalRequest} />
                                </ReachMenuItem>
                            );
                        })}
                    </ReachMenuList>
                </Fragment>
            )}
        </ReachMenu>
    );
};
