import { FieldArray, useField, useFormikContext } from 'formik';

import { Item } from '../../models/item.model';
import { Surcharge } from '../../models/surcharge.model';
import { AddRow } from '../common/AddRow';

import { ProductRow } from './ProductRow';
import { SurchargeRow } from './SurchargeRow';
import { useProductTableConfig } from './useProductTableConfig';

const Products = (props) => {
    const [field] = useField('items');
    const items = field.value || [];

    return items.map((item, index) => (
        <ProductRow
            key={index}
            name={`items.${index}`}
            index={index}
            onRowRemoveClicked={() => {
                props.remove(index);
            }}
        />
    ));
};

const Surcharges = (props) => {
    const [field] = useField('surcharges');
    const surcharges = field.value || [];

    return surcharges.map((surcharge, index) => {
        return <SurchargeRow key={index} name={`surcharges.${index}`} onRowRemoveClicked={() => props.remove(index)} />;
    });
};

export const TableContent = () => {
    const { values: offer } = useFormikContext();
    const [itemsField, , itemsHelpers] = useField('items');
    const [surchargesField, , surchargesHelpers] = useField('surcharges');
    const { editable, editSurcharges } = useProductTableConfig();

    const onAddProductClick = () => {
        let physicalSupplierName = undefined;

        if (offer.supplier?.supplierGroup.type === 'MANUFACTURER') {
            physicalSupplierName = offer.supplier.name;
        }

        const emptyProduct = new Item({
            new: true,
            unit: 'MT',
            physicalSupplierName,
        });

        itemsHelpers.setValue([...itemsField.value, emptyProduct]);
    };

    const onAddSurchargeClick = (surcharge) => {
        const newSurcharge = new Surcharge({
            name: surcharge.surchargeType === 'CUSTOM' ? surcharge.customOption : '',
            surchargeType: surcharge.surchargeType,
            new: true,
            value: {
                value: Number(surcharge.amount),
                currency: 'USD',
            },
        });

        surchargesHelpers.setValue([...surchargesField.value, newSurcharge]);
    };

    const onAddWaiverClick = (waiver) => {
        const newWaiver = new Surcharge({
            name: waiver.description,
            surchargeType: 'CUSTOM',
            new: true,
            value: {
                value: Number(waiver.amount * -1),
                currency: 'USD',
            },
        });

        surchargesHelpers.setValue([...surchargesField.value, newWaiver]);
    };

    return (
        <>
            <FieldArray name="items" component={Products} />
            <FieldArray name="surcharges" component={Surcharges} />
            {editable && (
                <AddRow
                    onAddProductClick={onAddProductClick}
                    onAddSurchargeClick={onAddSurchargeClick}
                    onAddWaiverClick={onAddWaiverClick}
                    editSurcharges={editSurcharges}
                    addProducts={!offer.contractId}
                />
            )}
        </>
    );
};
