import { useNavigate } from '@tanstack/react-router';

import { CounterOfferState } from '../../../../../types/CounterOfferState';
import { Button } from '../../../../cdl/Button/Button';
import { ButtonGroup } from '../../../../common/buttons/ButtonGroup';
import { DeprecatedFormikDatePicker } from '../../../../common/form-elements/formik/DeprecatedFormikDatePicker';
import { FormikDebug } from '../../../../common/form-elements/formik/FormikDebug';
import { FormikPositiveNumberInput } from '../../../../common/form-elements/formik/FormikPositiveNumberInput';
import { FormikTextArea } from '../../../../common/form-elements/formik/FormikTextArea';
import { FormikTextInput } from '../../../../common/form-elements/formik/FormikTextInput';
import { useFormikDirtyWarning } from '../../../../common/form-elements/formik/useFormikDirtyWarning';
import { FormikFuelProductTable } from '../../../../common/FormikProductTable/fuel/FormikFuelProductTable';
import { translate } from '../../../../common/helpers/translate.helper';

import { Box } from '../../../../common/ui/Box';
import { CardVariation } from '../../../../common/ui/CardVariation';
import { Grid, GridItem } from '../../../../common/ui/Grid';

import { CounterOfferRejectHint } from './CounterOfferRejectHint';
import { FormikFuelDeliveryCostsSelect } from './FormikFuelDeliveryCostsSelect';
import { FormikFuelSupplyTypeSelect } from './FormikFuelSupplyTypeSelect';
import { FormikPaymentTerms } from './FormikPaymentTerms';
import { FuelOfferSummary } from './FuelOfferSummary';
import { FuelQuoteAttachments } from './FuelQuoteAttachments';
import { OfferQuoteStatusBar } from './OfferQuoteStatusBar';

export const QuoteForm = (props) => {
    useFormikDirtyWarning();
    const navigate = useNavigate();

    const navigateToOffer = (offerId) => {
        navigate({ to: '/fuel/offer/$id', params: { id: offerId } });
    };

    const onCancelButtonClick = () => {
        if (props.dirty) {
            const confirmed = confirm(translate('confirmation.warning'));
            if (confirmed) {
                navigateToOffer(props.values.id);
            }
        } else {
            navigateToOffer(props.values.id);
        }
    };

    const isCounterOfferExpiredOrOpen = () => {
        if (!props.values.counterOffers.length) {
            return false;
        }

        const latestCounterOffer = props.values.counterOffers[0];
        return [CounterOfferState.OPEN, CounterOfferState.EXPIRED].includes(latestCounterOffer.state);
    };

    return (
        <Box display="grid" gap={6} padding={6}>
            <CardVariation paddingX={0} paddingY={0}>
                <OfferQuoteStatusBar offer={props.values} />
            </CardVariation>

            <CardVariation>
                <FuelOfferSummary offer={props.values} />
            </CardVariation>

            <CardVariation>
                <FormikFuelProductTable />
            </CardVariation>

            <CardVariation>
                <Grid>
                    <GridItem width={1 / 2}>
                        <DeprecatedFormikDatePicker
                            required
                            label={translate('offer.quotationProcess.fuel.deliveryDate')}
                            name="dateDelivery"
                        />
                    </GridItem>
                    <GridItem width={1 / 2}>
                        <FormikFuelSupplyTypeSelect name="supplyMode" />
                    </GridItem>

                    <GridItem width={1 / 2}>
                        <FormikFuelDeliveryCostsSelect
                            name={'supplyModeIncludesDeliveryCosts'}
                            label={translate('offer.quotationProcess.fuel.deliveryCosts')}
                            required
                        />
                    </GridItem>

                    <GridItem width={1 / 2}>
                        <FormikPaymentTerms label={translate('offer.quotationProcess.fuel.paymentTerms')} required />
                    </GridItem>

                    <GridItem width={1 / 2}>
                        <FormikPositiveNumberInput
                            name="quoteValidityTime"
                            type="number"
                            label={translate('offer.quotationProcess.fuel.validityTime')}
                            placeholder={translate('offer.quotationProcess.fuel.validityTimePlaceholder')}
                            required
                        />
                    </GridItem>

                    <GridItem width={1 / 2}>
                        <FormikTextInput
                            name="vendorReference"
                            label={translate('offer.quotationProcess.fuel.vendorReference')}
                            placeholder={translate('offer.quotationProcess.fuel.vendorReferencePlaceholder')}
                        />
                    </GridItem>
                </Grid>

                <Grid paddingTop={5}>
                    <GridItem width={1 / 2}>
                        <FormikTextArea
                            placeholder={translate('offer.quotationProcess.fuel.messagePlaceholder')}
                            name="message"
                        />
                    </GridItem>
                    <GridItem width={1 / 2}>
                        <FuelQuoteAttachments offer={props.values} />
                    </GridItem>
                </Grid>

                {isCounterOfferExpiredOrOpen() ? (
                    <Box display="flex" justifyContent="center" marginTop={9} marginBottom={5}>
                        <CounterOfferRejectHint />
                    </Box>
                ) : null}

                <Box display="flex" justifyContent="center" marginTop={isCounterOfferExpiredOrOpen() ? 0 : 9}>
                    <ButtonGroup>
                        <Button type="button" emphasis="medium" onClick={onCancelButtonClick}>
                            {translate('offer.quotationProcess.fuel.cancel')}
                        </Button>
                        <Button
                            emphasis="high"
                            disabled={!props.isValid}
                            onClick={props.submitForm}
                            isLoading={props.isSubmitting}
                        >
                            {props.values.state === 'QUOTED'
                                ? translate('offer.quotationProcess.fuel.update')
                                : translate('offer.quotationProcess.fuel.send')}
                        </Button>
                    </ButtonGroup>
                </Box>
            </CardVariation>

            <FormikDebug />
        </Box>
    );
};
