import { AgentMessage } from '../../../types/AgentMessage';
import { ApprovalRequestState } from '../../../types/ApprovalRequestState';
import { ContractMessage } from '../../../types/ContractMessage';
import { CustomerCompanyMessage } from '../../../types/CustomerCompanyMessage';
import { FileMessage } from '../../../types/FileMessage';
import { Iso8601DateTimeString } from '../../../types/Iso8601DateTimeString';
import { PortMessage } from '../../../types/PortMessage';
import { ProductContext } from '../../../types/ProductContext';
import { SupplierCompanyMessage } from '../../../types/SupplierCompanyMessage';
import { UserMessage } from '../../../types/UserMessage';
import { VesselMessage } from '../../../types/VesselMessage';
import { ItemModel } from '../../common/models/ItemModel';
import { SamplekitModel } from '../../common/models/SamplekitModel';
import { OfferState } from '../../offer/model/OfferState';

export enum OrderState {
    DRAFT = 'DRAFT',
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
    CANCELED = 'CANCELED',
}

// This should reflect the exact response we get from the GatewayService
// Keep in sync with: https://github.com/closelink/GatewayService/blob/main/src/main/java/net/closelink/gatewayservice/service/clients/pojos/objects/OrderMessage.java

export interface OrderMessage {
    id: string;
    dateCreated: Iso8601DateTimeString;
    dateUpdated?: Iso8601DateTimeString;
    version?: number;
    customerId: string;
    supplierId: string;
    vesselId: string;
    portId: string;
    state: OrderState;
    agent?: string;
    dateDelivery?: Iso8601DateTimeString;
    dateCanceled?: Iso8601DateTimeString;
    dateStarted?: Iso8601DateTimeString;
    dateOrdered?: Iso8601DateTimeString;
    buyerReference?: string;
    orderNumber?: string;
    cancelReason?: string;
    items: ItemModel[];
    samplekits: SamplekitModel[];
    createdBy?: string;
    updatedBy?: string;
    comment: string;
    chosenOfferId?: string;
    read: boolean;
    spot: boolean;
    receiverSupplierIds: string[];
    assignedSupplierIds: string[];
    excludeSupplierGroupIds?: string[];
    offerCount: number;
    offerState?: OfferState;
    attributes?: Map<string, string>;
    creationComment?: string;
    agentId?: string;
    source?: string;
    type: ProductContext;
    contractId?: string;
    eta?: Iso8601DateTimeString;
    etd?: Iso8601DateTimeString;
    validityTime?: number;
    validUntil?: string;
    fileIds?: string[];
    testOrder: boolean;
    approvalRequestState?: ApprovalRequestState;
}

// This should reflect the exact response we get from the GatewayService
// Keep in sync with: https://github.com/closelink/GatewayService/blob/main/src/main/java/net/closelink/gatewayservice/service/clients/pojos/objects/OrderMessage.java
interface GatewayOrderMessage extends OrderMessage {
    agentInformation?: AgentMessage;
    customer?: CustomerCompanyMessage;
    supplier?: SupplierCompanyMessage;
    vessel: VesselMessage;
    port?: PortMessage;
    createdByUser?: UserMessage;
    updatedByUser?: UserMessage;
    contract?: ContractMessage;
    files?: FileMessage[];
}

// Main order model used in the frontend
// Used for both LUBES and FUEL
// This should only include the properties of the gateway response that are actually used in the frontend
export type OrderModel = Omit<
    GatewayOrderMessage,
    'dateCanceled' | 'dateOrdered' | 'createdBy' | 'updatedBy' | 'excludeSupplierGroupIds' | 'version' | 'source'
> & {
    isClosed(): boolean;

    isDraft(): boolean;

    isFinished(): boolean;

    isOpen(): boolean;

    isSpot(): boolean;
};
