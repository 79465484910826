import { IconCircleX } from '../../common/icons/cdl/CircleX';
import { Box } from '../../common/ui/Box';

export const RemoveLineIcon = ({ onClick }) => {
    return (
        <Box
            position="absolute"
            top="10px"
            right="10px"
            style={{
                zIndex: 2, // Make sure that button is clickable although element might be covered technically
            }}
        >
            <button onClick={onClick}>
                <IconCircleX />
            </button>
        </Box>
    );
};
