import { Field, FieldArray, useField } from 'formik';
import styled from 'styled-components';

import { CategoryState } from '../../../types/ReachInformationMessage';
import { Button } from '../../cdl/Button/Button';
import { IconButton } from '../../cdl/IconButton/IconButton';
import { HumanReadableEnumValue } from '../../common/enums/HumanReadableEnumValue';
import { FormikFormattedNumberInput } from '../../common/form-elements/formik/FormikFormattedNumberInput';
import { FormikFuelProductSelect } from '../../common/form-elements/formik/FormikFuelProductSelect';
import { translate } from '../../common/helpers/translate.helper';
import { useEnums } from '../../common/hooks/useEnums';
import { IconPlus } from '../../common/icons/cdl/Plus';
import { IconX } from '../../common/icons/cdl/X';
import { Table } from '../../common/Table/Table';
import { Td } from '../../common/Table/Td';
import { Tr } from '../../common/Table/Tr';
import { Box } from '../../common/ui/Box';

import { FormikContractVolumeWarning } from './FormikContractVolumeWarning';
import { FormikFuelIsoSpecSelect } from './FormikFuelIsoSpecSelect';
import { useFormikReachInformation } from './hooks/useFormikReachInformation';
import { ReachInformationWarning } from './ReachInformationWarning';

const Grid = styled.div`
    display: grid;
    grid-template-columns: 3fr 1fr;
    align-items: center;
    grid-gap: 6px;
`;

export const HeaderRow = () => {
    return (
        <Tr>
            <th>{translate('fuelEnquiry.second.productTable.product')}</th>
            <th>{translate('fuelEnquiry.second.productTable.iso')}</th>
            <th>{translate('fuelEnquiry.second.productTable.category')}</th>
            <th>{translate('fuelEnquiry.second.productTable.volume')}</th>
            <th>{translate('fuelEnquiry.second.productTable.unit')}</th>
        </Tr>
    );
};

const FormikHumanReadableEnumValue = (props) => {
    return (
        <Field name={props.name}>
            {({ field }) =>
                field.value ? <HumanReadableEnumValue enumType={props.enumType} value={field.value} /> : null
            }
        </Field>
    );
};

const TextField = (props) => {
    return <Field name={props.name}>{({ field }) => (field.value ? field.value : '-')}</Field>;
};

const ProductRow = (props) => {
    const [field, , helpers] = useField(props.name);
    const { getEnumField } = useEnums();
    const { getReachInformationCategory } = useFormikReachInformation();

    const findRelevantReachInformationCategory = () => {
        const item = field.value;

        if (!item) {
            return null;
        }

        return getReachInformationCategory(item.sulphurContent, item.group);
    };

    const onProductSelect = (product) => {
        const defaultUnitSize = getEnumField('packType', product.packTypeDefault, 'defaultUnitSize');

        helpers.setValue({
            ...field.value,
            productId: product.id,
            sulphurContent: product.sulphurContent,
            group: product.group,
            unit: product.unitDefault,
            productName: product.name,
            packType: product.packTypeDefault,
            unitSize: defaultUnitSize,
        });
    };

    const reachInformationCategory = findRelevantReachInformationCategory();

    return (
        <Tr key={props.name}>
            <Td width="30%">
                {props.type === 'ENQUIRY' ? (
                    <Grid>
                        <FormikFuelProductSelect name={`${props.name}.productId`} onSelect={onProductSelect} />
                        {reachInformationCategory &&
                        reachInformationCategory.categoryState !== CategoryState.IN_REACH ? (
                            <ReachInformationWarning reachInformationCategory={reachInformationCategory} />
                        ) : null}
                    </Grid>
                ) : (
                    field.value.productName
                )}
            </Td>
            <Td width="15%">
                {props.type === 'ENQUIRY' ? <FormikFuelIsoSpecSelect name={`${props.name}.isoSpec`} /> : '-'}
            </Td>
            <Td width="15%">
                <FormikHumanReadableEnumValue name={`${props.name}.group`} enumType="productGroup" />{' '}
                <FormikHumanReadableEnumValue name={`${props.name}.sulphurContent`} enumType="sulphurContent" />
            </Td>
            <Td width="20%">
                <Grid>
                    <FormikFormattedNumberInput
                        name={`${props.name}.units`}
                        placeholder={translate('fuelEnquiry.second.productTable.volumePlaceholder')}
                    />
                    <Box>{props.type === 'CONTRACTED' ? <FormikContractVolumeWarning name={props.name} /> : null}</Box>
                </Grid>
            </Td>
            <Td width="10%">
                <FormikHumanReadableEnumValue name={`${props.name}.unit`} enumType="productUnit" />
            </Td>

            <Td width="10%">
                {props.showRemoveButton ? (
                    <IconButton Icon={IconX} type="button" onClick={props.onRowRemoveClicked} />
                ) : null}
            </Td>
        </Tr>
    );
};

const AddProductRow = ({ onClick }) => {
    return (
        <Tr>
            <Td colSpan={6}>
                <Button leadingVisual={<IconPlus width={16} height={16} />} type="button" onClick={onClick}>
                    {translate('fuelEnquiry.second.productTable.addProduct')}
                </Button>
            </Td>
        </Tr>
    );
};

const Products = (props) => {
    const [field] = useField('items');
    const [{ value: contractId }] = useField('contractId');
    const items = field.value;

    const type = contractId ? 'CONTRACTED' : 'ENQUIRY';

    const showRemoveButton = type === 'ENQUIRY' && items.length > 1;

    return (
        <>
            {items.map((item, index) => (
                <ProductRow
                    key={index}
                    name={`items.${index}`}
                    type={type}
                    showRemoveButton={showRemoveButton}
                    onRowRemoveClicked={() => {
                        props.remove(index);
                    }}
                />
            ))}
            {type === 'ENQUIRY' ? (
                <AddProductRow
                    onClick={() => {
                        const empty = {
                            productName: null,
                            productId: null,
                            units: '',
                            unit: null,
                            sulphurContent: null,
                            group: null,
                            packType: null,
                            unitSize: null,
                        };
                        props.insert(items.length, empty);
                    }}
                />
            ) : null}
        </>
    );
};

export const ProductTable = () => {
    return (
        <Table>
            <thead>
                <HeaderRow />
            </thead>
            <tbody>
                <FieldArray name="items" component={Products} />
            </tbody>
        </Table>
    );
};
