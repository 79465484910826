import { linkOptions } from '@tanstack/react-router';

import { ProductContext } from '../../../types/ProductContext';
import { TableBuilder } from '../../cdl/TableBuilder/TableBuilder';
import { TableBuilderColumn } from '../../cdl/TableBuilder/TableBuilderColumn';
import { formatCompanyName } from '../../common/helpers/formatCompanyName.helper';
import { formatDate } from '../../common/helpers/formatDate.helper';
import { formatFuelMoney } from '../../common/helpers/formatFuelMoney.helper';
import { formatLubesPrice } from '../../common/helpers/formatLubesPrice.helper';
import { formatMoney } from '../../common/helpers/formatMoney.helper';
import { formatVessel } from '../../common/helpers/formatVessel.helper';
import { translate } from '../../common/helpers/translate.helper';
import { useRole } from '../../common/hooks/useRole';
import { OfferStatusTag } from '../../common/OfferStatusTag/OfferStatusTag';
import { Box } from '../../common/ui/Box';
import { OfferModel } from '../../offer/model/OfferModel';
import { UnreadIndicator } from '../../order/common/UnreadIndicator';
import { Port } from '../../port/Port';

interface SearchResultOfferTableProps {
    offers: OfferModel[];
}

export const SearchResultOfferTable = ({ offers }: SearchResultOfferTableProps) => {
    const role = useRole();

    const buildRowLink = (offer: OfferModel) => {
        switch (offer.type) {
            case ProductContext.FUEL:
                return linkOptions({ to: '/fuel/offer/$id', params: { id: offer.id } });

            case ProductContext.LUBES:
                return linkOptions({ to: '/offer/$id', params: { id: offer.id } });
        }
    };

    return (
        <TableBuilder data={offers} rowLink={buildRowLink} grow={false}>
            <TableBuilderColumn<OfferModel> header="" width="1%">
                {(offer) => <Box width="8px">{!offer.supplierRead ? <UnreadIndicator /> : null}</Box>}
            </TableBuilderColumn>

            <TableBuilderColumn<OfferModel> header={translate('order.vendorreferenceshort')} width="10%" truncate>
                {(offer) => offer.vendorReference || '-'}
            </TableBuilderColumn>

            {!role.isOneCompanyUser() ? (
                <TableBuilderColumn<OfferModel>
                    header={role.isSupplier() ? translate('offer.company') : translate('offer.supplier')}
                    width="15%"
                    truncate
                >
                    {(offer) => formatCompanyName({ company: offer.supplier, includeType: role.isAdmin() })}
                </TableBuilderColumn>
            ) : null}

            <TableBuilderColumn<OfferModel> header={translate('offer.customer')} width="15%" truncate>
                {(offer) => formatCompanyName({ company: offer.customer, includeType: role.isAdmin() })}
            </TableBuilderColumn>

            <TableBuilderColumn<OfferModel> header={translate('order.vessel')} width="15%" truncate>
                {(offer) => formatVessel({ vessel: offer.vessel, short: true })}
            </TableBuilderColumn>

            <TableBuilderColumn<OfferModel> header={translate('order.port')} width="15%">
                {(offer) => {
                    if (!offer.port) {
                        return null;
                    }
                    return <Port port={offer.port} vesselId={offer.vesselId} locode={false} showTooltip />;
                }}
            </TableBuilderColumn>

            <TableBuilderColumn<OfferModel> header={translate('order.dateDeliveryShort')} width="10%">
                {(offer) => {
                    if (!offer.dateDelivery) {
                        return null;
                    }
                    return formatDate({
                        date: offer.dateDelivery,
                        timeZone: 'UTC',
                    });
                }}
            </TableBuilderColumn>

            {role.isAdmin() ? (
                <TableBuilderColumn<OfferModel> header={translate('order.ppl')} width="5%">
                    {(offer) => {
                        if (!offer.ppl || offer.ppl.value === 0) {
                            return null;
                        }
                        if (offer.type === ProductContext.FUEL) {
                            return formatFuelMoney({
                                value: offer.ppl.value,
                                currency: offer.ppl.currency,
                            });
                        }
                        return formatLubesPrice(offer.ppl);
                    }}
                </TableBuilderColumn>
            ) : null}

            {role.isAdmin() ? (
                <TableBuilderColumn<OfferModel> header={translate('order.total')} width="5%">
                    {(offer) => {
                        if (!offer.total || offer.total.value === 0) {
                            return null;
                        }
                        return formatMoney({
                            value: offer.total.value,
                            currency: offer.total.currency,
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        });
                    }}
                </TableBuilderColumn>
            ) : null}

            <TableBuilderColumn<OfferModel> header={translate('order.status')} width="10%">
                {(offer) => {
                    if (!offer.state) {
                        return null;
                    }
                    return <OfferStatusTag state={offer.state} variant="supplier" />;
                }}
            </TableBuilderColumn>
        </TableBuilder>
    );
};
