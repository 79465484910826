import { DrawerFormLayout } from '../cdl/Drawer/DrawerFormLayout';
import { Text } from '../cdl/Text/Text';
import { FormCheckbox } from '../common/form-elements/Checkbox/Checkbox';
import { Form } from '../common/form-elements/Form/Form';
import { FormInputPlaces } from '../common/form-elements/InputPlaces/FormInputPlaces';
import { FormTextInput } from '../common/form-elements/TextInput/TextInput';
import { translate } from '../common/helpers/translate.helper';
import { useRole } from '../common/hooks/useRole';

export const GeneralInformationForm = ({ onSubmit, data }) => {
    const role = useRole();
    return (
        <Form height="100%" data={data} onSubmit={onSubmit}>
            <DrawerFormLayout title={translate('company.generalInformation.edit')}>
                <FormTextInput
                    label={translate('global.address.company')}
                    dataPath="name"
                    placeholder={translate('global.address.placeholder.companyname')}
                    required
                    focus
                />

                <FormTextInput
                    label={translate('global.contact.email')}
                    dataPath="email"
                    placeholder={translate('global.contact.placeholder.email')}
                    required
                />

                <FormTextInput
                    label={translate('global.contact.phone')}
                    dataPath="phone"
                    placeholder={translate('global.contact.placeholder.phone')}
                />

                <FormTextInput
                    label={translate('global.contact.fax')}
                    dataPath="fax"
                    placeholder={translate('global.contact.placeholder.fax')}
                />

                <FormTextInput
                    label={translate('global.contact.invoicemail')}
                    dataPath="invoiceMail"
                    placeholder={translate('global.contact.placeholder.email')}
                />

                <Text as="h2" variant="subtitle1" marginTop={7} marginBottom={2}>
                    {translate('global.address.title')}
                </Text>

                <FormTextInput
                    label={translate('global.address.company')}
                    dataPath="address.companyName"
                    placeholder={translate('global.address.placeholder.companyname')}
                />

                <FormInputPlaces
                    label={translate('global.address.street')}
                    dataPaths={{
                        street: 'address.street',
                        streetNumber: 'address.streetNumber',
                        zipcode: 'address.zipcode',
                        city: 'address.city',
                        country: 'address.country',
                    }}
                    input={{
                        placeholder: translate('global.address.placeholder.street'),
                    }}
                />

                <FormTextInput
                    label={translate('global.address.streetnumber')}
                    dataPath="address.streetNumber"
                    placeholder={translate('global.address.placeholder.streetnumber')}
                />

                <FormTextInput
                    label={translate('global.address.zipcode')}
                    dataPath="address.zipcode"
                    placeholder={translate('global.address.placeholder.zipcode')}
                />

                <FormTextInput
                    label={translate('global.address.city')}
                    dataPath="address.city"
                    placeholder={translate('global.address.placeholder.city')}
                />

                <FormTextInput
                    label={translate('global.address.country')}
                    dataPath="address.country"
                    placeholder={translate('global.address.placeholder.country')}
                />

                {!role.isAdmin() ? (
                    <>
                        <Text as="h2" variant="subtitle1" marginTop={7} marginBottom={5}>
                            {translate('global.mailsettings.title')}
                        </Text>

                        <FormCheckbox
                            label={translate('global.mailsettings.newsletter')}
                            dataPath="mailSettings.newsletter"
                        />
                    </>
                ) : null}
            </DrawerFormLayout>
        </Form>
    );
};
