import { Text } from '../../../cdl/Text/Text';
import { Flex } from '../../ui/Flex';
import { useFormData } from '../Form/useFormData';
import { StyledInput as Input } from '../TextInput/Input';

const Checkbox = ({ label, checked, ...props }) => (
    <label>
        <Flex alignItems="center">
            <Input margin="0 !important" border={1} borderRadius={1} type="checkbox" checked={checked} {...props} />
            <Text marginLeft={3}>{label}</Text>
        </Flex>
    </label>
);

/**
 * @deprecated use CDL Checkbox instead
 **/
export const FormCheckbox = ({ dataPath, ...props }) => {
    const { value, onChange } = useFormData(dataPath);

    const handleChange = (e) => {
        onChange(e, (e) => e.target.checked);
    };

    return <Checkbox checked={!!value} onChange={handleChange} {...props} />;
};
