import { CoreData } from '../../common/CoreData/CoreData';
import { useAnonymousCoreDataFields } from '../hooks/useAnonymousCoreDataFields';

export const AnonymousQuotationCoreData = ({ data }) => {
    const fields = useAnonymousCoreDataFields();

    return (
        <CoreData
            data={data}
            focusFields={[fields.vessel, fields.customer, fields.user, fields.agent]}
            baseFields={[fields.port, fields.buyerReference, fields.enquiryNote, fields.eta, fields.etd]}
        />
    );
};
