import { translate } from '../../common/helpers/translate.helper';

import { CommentPopup } from './CommentPopup';

export const DeclinePopup = ({ open, onDismiss, onSubmit, requestInProgress }) => {
    return (
        <CommentPopup
            open={open}
            onDismiss={onDismiss}
            onSubmit={onSubmit}
            requestInProgress={requestInProgress}
            headline={translate('approvalRequest.declinePopup.headline')}
            description={translate('approvalRequest.declinePopup.description')}
            inputLabel={translate('approvalRequest.declinePopup.reasonInput.label')}
        />
    );
};
