import { translate } from '../../common/helpers/translate.helper';
import { AgentForm } from '../AgentForm/AgentForm';
import { useAgentCreate } from '../useAgentCreate';

export const CreateAgentForm = ({ data, comment, onSuccess, showCompanySelect }) => {
    const createAgentMutation = useAgentCreate(onSuccess);

    const handleSubmit = ({ data, event }) => {
        event.preventDefault();
        createAgentMutation.mutate({ agent: data });
    };

    return (
        <AgentForm
            title={translate('createAgentForm.title')}
            onSubmit={handleSubmit}
            agent={data}
            showCompanySelect={showCompanySelect}
            comment={comment}
        />
    );
};
