import { useEffect, useState } from 'react';

import { CategoryState } from '../../../../types/ReachInformationMessage';
import { Error } from '../../../common/Error/Error';
import { translate } from '../../../common/helpers/translate.helper';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { TableEmptyState } from '../../../common/TableEmptyState/TableEmptyState';
import { useLubesSchedulesWithPrices } from '../hooks/useLubesSchedulesWithPrices';
import { LubesSchedulePriceCompare } from '../PriceCompare/LubesSchedulePriceCompare';
import { ScheduleStop } from './ScheduleStop';
import { ScheduleSlider } from '../Slider/ScheduleSlider';
import { ScheduleStopContainer } from '../Slider/ScheduleStopContainer';
import { ScheduleWrapper } from '../Slider/ScheduleWrapper';
import { useCheapestDefaultScope } from '../hooks/useCheapestDefaultScope';

import { createLubesPricesMap } from './createLubesPriceMap';
import { Box } from '../../../common/ui/Box';

const stopHasCheapestDefault = (stop, pricesMap) => {
    const pricesForPort = pricesMap[stop.port?.id];

    if (!pricesForPort) {
        return false;
    }

    return Object.values(pricesForPort).some((prices) => {
        return prices.some((price) => price.bestPriceCategoryDefault);
    });
};

const createReachInformationMap = (reachInformation) => {
    if (!reachInformation) {
        return {};
    }

    const scheduleIdToReachInformationMap = {};

    reachInformation.forEach((it) => {
        scheduleIdToReachInformationMap[it.scheduleId] = {};

        it.categories.forEach((category) => {
            scheduleIdToReachInformationMap[it.scheduleId][category.tankCategory] = category;
        });
    });

    return scheduleIdToReachInformationMap;
};

export const RegularLubesScheduleSection = ({ vessel }) => {
    const [slidesPerView, setSlidesPerView] = useState(0);
    const [activeSlide, setActiveSlide] = useState(0);
    const [pricesMap, setPricesMap] = useState({});

    const lubesSchedulesWithPricesQuery = useLubesSchedulesWithPrices(vessel);

    useEffect(() => {
        if (lubesSchedulesWithPricesQuery.data?.prices) {
            setPricesMap(createLubesPricesMap(lubesSchedulesWithPricesQuery.data.prices));
        }
    }, [lubesSchedulesWithPricesQuery.data]);

    const scheduleIdToReachInformationMap = createReachInformationMap(
        lubesSchedulesWithPricesQuery.data?.reachInformation
    );

    const cheapestDefaultScope = useCheapestDefaultScope({
        hasCheapestDefaultFn: stopHasCheapestDefault,
        activeSlide,
        slidesPerView,
        pricesMap,
        schedules: lubesSchedulesWithPricesQuery.data?.schedules,
    });

    if (lubesSchedulesWithPricesQuery.isPending) {
        return <LoadingIndicator />;
    }

    if (lubesSchedulesWithPricesQuery.isError) {
        return <Error />;
    }

    if (!lubesSchedulesWithPricesQuery.data.schedules?.length) {
        return <TableEmptyState text={translate('vessel.scheduleEmpty')} />;
    }

    const negativeStopIndices = [];

    const filledScheduleList = lubesSchedulesWithPricesQuery.data.schedules.map((stop, index) => {
        const reachInformation = scheduleIdToReachInformationMap[stop.id];

        if (reachInformation) {
            Object.values(reachInformation)
                .filter((it) => [CategoryState.OUT_OF_REACH, CategoryState.SAFETY_RESERVE].includes(it.categoryState))
                .forEach((it) => {
                    negativeStopIndices.push({
                        categoryState: it.categoryState,
                        index,
                    });
                });
        }

        return (
            <ScheduleStopContainer key={stop.id}>
                <ScheduleStop stop={stop} vesselId={vessel.id} index={index} />
                {stop.port?.id && pricesMap[stop.port.id] ? (
                    <LubesSchedulePriceCompare
                        prices={pricesMap[stop.port.id]}
                        reachInformation={scheduleIdToReachInformationMap[stop.id]}
                    />
                ) : null}
            </ScheduleStopContainer>
        );
    });

    return (
        <ScheduleWrapper>
            <Box position="relative" paddingX={0} overflow="hidden">
                <ScheduleSlider
                    activeSlide={activeSlide}
                    onSlideChange={setActiveSlide}
                    scheduleList={filledScheduleList}
                    cheapestDefaultScope={cheapestDefaultScope}
                    negativeStopIndices={negativeStopIndices}
                    onSlidesPerViewChange={(slides) => setSlidesPerView(slides)}
                />
            </Box>
        </ScheduleWrapper>
    );
};
