import { Modal } from '../../cdl/Modal/Modal';
import { formatCompanyName } from '../../common/helpers/formatCompanyName.helper';
import { formatMoney as _formatMoney } from '../../common/helpers/formatMoney.helper';
import { translate } from '../../common/helpers/translate.helper';
import { useRole } from '../../common/hooks/useRole';
import { IconExchange } from '../../common/icons/cdl/Exchange';
import { Table } from '../../common/Table/Table';
import { Td } from '../../common/Table/Td';
import { Th } from '../../common/Table/Th';
import { Tr } from '../../common/Table/Tr';
import { Box } from '../../common/ui/Box';
import { Flex } from '../../common/ui/Flex';
import { Heading } from '../../common/ui/Heading';

const ProductColumn = ({ item }) => {
    return (
        <Td>
            <Flex alignItems="center">
                {item.translated ? (
                    <IconExchange
                        style={{
                            marginRight: '4px',
                        }}
                    />
                ) : null}
                {item.product?.name}
            </Flex>
        </Td>
    );
};

export const ItemsPopup = ({ price, onDismiss, open }) => {
    const role = useRole();

    const formatVolume = (volume) => `${volume}L`;

    const formatMoney = (price) => {
        return _formatMoney({
            value: price.value,
            currency: price.currency,
            minimumFractionDigits: 2,
            maximumFractionDigits: 4,
        });
    };

    return (
        <Modal onDismiss={onDismiss} isOpen={open} scrollable={false}>
            <Box padding={9}>
                <Heading as="h4" level="h4" marginBottom={6}>
                    {formatCompanyName({
                        company: price.supplier,
                        includeType: role.isAdmin(),
                    })}
                </Heading>

                <Table>
                    <thead>
                        <Tr>
                            <Th>{translate('compare.itemsPopup.product')}</Th>
                            <Th>{translate('compare.itemsPopup.ppl')}</Th>
                            <Th>{translate('compare.itemsPopup.volume')}</Th>
                            <Th>{translate('compare.itemsPopup.total')}</Th>
                        </Tr>
                    </thead>
                    <tbody>
                        {price.items.map((item, index) => (
                            <Tr key={index}>
                                <ProductColumn item={item} />
                                <Td>{item.price ? formatMoney(item.price) : '-'}</Td>
                                <Td>{formatVolume(item.volume)}</Td>
                                <Td>{item.total ? formatMoney(item.total) : '-'}</Td>
                            </Tr>
                        ))}
                        <Tr>
                            <Td colSpan={2} />
                            <Td style={{ fontWeight: 'bold' }}>{formatVolume(price.volume)}</Td>
                            <Td style={{ fontWeight: 'bold' }}>
                                {price.totalPrice ? formatMoney(price.totalPrice) : '-'}
                            </Td>
                        </Tr>
                    </tbody>
                </Table>
            </Box>
        </Modal>
    );
};
