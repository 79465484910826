import { Modal } from '../../../cdl/Modal/Modal';
import { FuelShowProductTable } from '../../../common/FuelShowOrderProductTable/FuelShowOrderProductTable';
import { translate } from '../../../common/helpers/translate.helper';
import { Box } from '../../../common/ui/Box';
import { Heading } from '../../../common/ui/Heading';

export const ProductPopup = ({ order, isOpen, onDismiss }) => {
    return (
        <Modal isOpen={isOpen} onDismiss={onDismiss} scrollable={false}>
            <Box padding={8} paddingBottom={5}>
                <Heading level="uppercase" marginBottom={4}>
                    {translate('fuelOrder.orderedProducts')}
                </Heading>
                <FuelShowProductTable items={order.items} />
            </Box>
        </Modal>
    );
};
