import styled from 'styled-components';

import { CL_DARK_BLUE_2 } from '../../common/colors';
import { formatMoney } from '../../common/helpers/formatMoney.helper';
import { formatNumber } from '../../common/helpers/formatNumber.helper';
import { translate } from '../../common/helpers/translate.helper';
import { Td } from '../../common/Table/Td';

const TotalRow = styled.tr`
    background-color: ${CL_DARK_BLUE_2};

    td {
        font-weight: 600;
    }
`;

export const Total = ({ offer, showPrices }) => {
    return (
        <TotalRow>
            <Td />
            <Td colSpan={4}>{translate('order.itemtotal')}</Td>
            <Td colSpan={2}>
                {formatNumber({
                    number: offer.volume,
                })}
            </Td>
            {showPrices ? (
                <>
                    <Td>
                        {formatMoney({
                            value: offer.ppl.value,
                            currency: offer.ppl.currency,
                            maximumFractionDigits: 3,
                        })}
                    </Td>
                    <Td>
                        {formatMoney({
                            value: offer.total.value,
                            currency: offer.total.currency,
                            minimumFractionDigits: 2,
                        })}
                    </Td>
                </>
            ) : null}
        </TotalRow>
    );
};
