import { useQuery } from '@tanstack/react-query';
import moment from 'moment';

import { getScheduleList } from '../../../../common/api/clients/gateway.api';
import { queryKeys } from '../../../../common/api/queryKeys';
import { useRole } from '../../../../common/hooks/useRole';

export const PositionType = {
    VESSEL: 'VESSEL',
    NEXT_PORT: 'NEXT_PORT',
    LAST_PORT: 'LAST_PORT',
    ORDER_PORT: 'ORDER_PORT',
};

const findNextPortCall = (schedules) => {
    const nextPortCallDate = new Date(Math.min(...schedules.map((portCall) => new Date(portCall.eta))));
    return schedules.find((portCall) => portCall.eta === nextPortCallDate.toISOString());
};

const findLastPortCall = (schedules) => {
    const lastPortCallDate = new Date(Math.max(...schedules.map((portCall) => new Date(portCall.eta))));
    return schedules.find((portCall) => portCall.eta === lastPortCallDate.toISOString());
};

const mapOfferPortPositionData = (offer) => {
    return {
        type: PositionType.ORDER_PORT,
        port: offer.port,
        vessel: null,
        offerDateDelivery: offer.dateDelivery,
        position: {
            lat: offer.port.latitude,
            lng: offer.port.longitude,
        },
    };
};

const mapNextPortPositionData = (nextPort) => {
    return {
        type: PositionType.NEXT_PORT,
        port: nextPort,
        vessel: null,
        offerDateDelivery: null,
        position: {
            lat: nextPort.latitude,
            lng: nextPort.longitude,
        },
    };
};

const mapVesselPositionData = (vessel) => {
    return {
        type: PositionType.VESSEL,
        port: null,
        vessel: vessel,
        offerDateDelivery: null,
        position: {
            lat: vessel.locationData.location.latitude,
            lng: vessel.locationData.location.longitude,
        },
    };
};

const mapLastPortPositionData = (lastPort) => {
    return {
        type: PositionType.LAST_PORT,
        port: lastPort,
        vessel: null,
        offerDateDelivery: null,
        position: {
            lat: lastPort.latitude,
            lng: lastPort.longitude,
        },
    };
};
export const useOfferPositions = (offer) => {
    const role = useRole();

    const etdFrom = moment.utc(moment().subtract(1, 'month')).startOf('day').toISOString();

    const etdTo = moment.utc(moment().add(120, 'days')).endOf('day').toISOString();

    const vessel = offer.vessel;
    const offerId = offer.id;

    const enrichOrderPort = (positionData) => {
        if (offer.port?.latitude) {
            positionData.push({
                ...mapOfferPortPositionData(offer),
                id: positionData.length + 1,
                vesselId: vessel.id,
            });
        }
    };

    const enrichVesselLocation = (positionData) => {
        if (vessel.locationData) {
            positionData.push({
                ...mapVesselPositionData(vessel),
                id: positionData.length + 1,
            });
        }
    };

    const enrichPositionData = (schedule, positionData) => {
        const scheduleEntriesInFuture = schedule.filter((portCall) => new Date(portCall.eta) > new Date());

        if (!scheduleEntriesInFuture.length) {
            enrichOrderPort(positionData);
            return;
        }

        const nextPortCall = findNextPortCall(scheduleEntriesInFuture);
        const orderPortIsNextPort = offer.port?.locCode === nextPortCall.port?.locCode;

        if (orderPortIsNextPort) {
            positionData.push({
                ...mapOfferPortPositionData(offer),
                id: positionData.length + 1,
                vesselId: vessel.id,
                isAlsoNextPort: true,
            });
        } else {
            enrichOrderPort(positionData);

            if (nextPortCall.port?.latitude) {
                positionData.push({
                    ...mapNextPortPositionData(nextPortCall.port),
                    id: positionData.length + 1,
                    vesselId: vessel.id,
                    eta: nextPortCall.eta,
                });
            }
        }
    };

    const enrichLastPort = (schedule, positionData) => {
        const scheduleEntriesInPast = schedule.filter((portCall) => new Date(portCall.eta) < new Date());

        if (!scheduleEntriesInPast.length) {
            return;
        }

        const lastPortCall = findLastPortCall(scheduleEntriesInPast);

        if (lastPortCall.port?.latitude) {
            positionData.push({
                ...mapLastPortPositionData(lastPortCall.port),
                id: positionData.length + 1,
                vesselId: vessel.id,
                eta: lastPortCall.eta,
            });
        }
    };

    const fetchScheduleAndMapPositions = async ({ imo, etdFrom, etdTo, customerGroupId }) => {
        try {
            // We prevent the fetching of schedules for supplier at this point until there is a better solution
            const schedule =
                role.isSupplier() || offer.isFinished()
                    ? []
                    : await getScheduleList({
                          imo,
                          etdFrom,
                          etdTo,
                          customerGroupId,
                      });
            const positionData = [];

            enrichPositionData(schedule, positionData);
            enrichVesselLocation(positionData);
            enrichLastPort(schedule, positionData);

            return positionData;
        } catch {
            const positionData = [];

            enrichOrderPort(positionData);
            enrichVesselLocation(positionData);

            return positionData;
        }
    };

    return useQuery({
        queryKey: queryKeys.offerPositions(offerId),

        queryFn: () =>
            fetchScheduleAndMapPositions({
                imo: vessel.imo,
                customerGroupId: vessel?.customer?.customerGroupId,
                etdFrom,
                etdTo,
            }),
    });
};
