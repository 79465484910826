import { Button } from '../../cdl/Button/Button';
import { useDrawer } from '../../cdl/Drawer/useDrawer.hook';
import { translate } from '../../common/helpers/translate.helper';
import { IconPlus } from '../../common/icons/cdl/Plus';

import { WaiverForm } from './WaiverForm';

export interface Waiver {
    description: string;
    amount: string;
}

interface WaiverButtonProps {
    onSubmit: (waiver: Waiver) => void;
}

export const WaiverButton = ({ onSubmit }: WaiverButtonProps) => {
    const { push, pop } = useDrawer();

    function openModal() {
        push({
            content: (
                <WaiverForm
                    onSubmit={(waiver) => {
                        onSubmit(waiver);
                        pop();
                    }}
                />
            ),
        });
    }

    return (
        <Button type="button" leadingVisual={<IconPlus height={16} width={16} />} onClick={openModal}>
            {translate('waiverForm.button')}
        </Button>
    );
};
