import { ConsumptionCalculationBase } from '../../../../types/ConumptionCalculationBase';
import { FormikCheckbox } from '../../../cdl/Checkbox/FormikCheckbox';
import { Label, Legend } from '../../../cdl/Label/Label.tsx';
import { FormikRadioGroup } from '../../../cdl/RadioGroup/FormikRadioGroup';
import { Description, Radio, RadioGroupItem } from '../../../cdl/RadioGroup/RadioGroup';
import { FormikFormattedNumberInput } from '../../../common/FormattedNumberInput/FormikFormattedNumberInput';
import { translate } from '../../../common/helpers/translate.helper';
import { Box } from '../../../common/ui/Box';
import { FormikProductCategorySelect } from '../../VesselDetailPage/EditTanksModal/components/FormikProductCategorySelect';

interface SinglePresetFromProps {
    index: number;
    isDynamic: boolean;
}

export const SinglePresetForm = ({ index, isDynamic }: SinglePresetFromProps) => {
    return (
        <Box display="grid" rowGap={5} paddingY={4}>
            <FormikProductCategorySelect
                name={`presets[${index}].vesselTankCategory`}
                label={translate('consumption.editPresets.category')}
            />

            <FormikFormattedNumberInput
                name={`presets[${index}].fixedAverageDailyConsumption`}
                label={translate('consumption.editPresets.fixedDailyAverageConsumption')}
                trailingVisual={<div>L</div>}
                meta={isDynamic ? translate('global.optional') : null}
            />

            <FormikCheckbox
                name={`presets[${index}].excludePortCalls`}
                label={translate('consumption.editPresets.excludePortCalls.label')}
                description={translate('consumption.editPresets.excludePortCalls.description')}
            />

            <FormikRadioGroup name={`presets[${index}].consumptionCalculationBase`}>
                <Legend>{translate('consumption.editPresets.consumptionCalculationBase.label')}</Legend>

                <RadioGroupItem>
                    <Radio value={ConsumptionCalculationBase.DYNAMIC} />
                    <Label>{translate('consumption.editPresets.consumptionCalculationBase.DYNAMIC.label')}</Label>
                    <Description>
                        {translate('consumption.editPresets.consumptionCalculationBase.DYNAMIC.description')}
                    </Description>
                </RadioGroupItem>

                <RadioGroupItem>
                    <Radio value={ConsumptionCalculationBase.STATIC} />
                    <Label>{translate('consumption.editPresets.consumptionCalculationBase.STATIC.label')}</Label>
                    <Description>
                        {translate('consumption.editPresets.consumptionCalculationBase.STATIC.description')}
                    </Description>
                </RadioGroupItem>
            </FormikRadioGroup>
        </Box>
    );
};
