import { useField } from 'formik';
import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { OnChangeValue } from 'react-select';

import { AgentMessage } from '../../../types/AgentMessage';
import { Button } from '../../cdl/Button/Button';
import { Callout } from '../../cdl/Callout/Callout';
import { ComboBox, DefaultOption } from '../../cdl/ComboBox/ComboBox';
import { useDrawer } from '../../cdl/Drawer/useDrawer.hook';
import { Select } from '../../cdl/Select/Select';
import { translate } from '../../common/helpers/translate.helper';
import { useProductContext } from '../../common/hooks/useProductContext';
import { useRole } from '../../common/hooks/useRole';
import { IconPencil } from '../../common/icons/cdl/Pencil';
import { IconPlus } from '../../common/icons/cdl/Plus';
import { LabelText } from '../../common/LabelText/LabelText';
import { Box } from '../../common/ui/Box';
import { CreateAgentForm } from '../CreateAgentForm/CreateAgentForm';
import { UpdateAgentForm } from '../UpdateAgentForm';

import { AgentSingleValue } from './components/AgentSingleValue';
import { CustomAgentOption } from './components/CustomAgentOption';
import { useAgentList } from './hooks/useAgentList';

interface AgentOption extends DefaultOption {
    label: string;
    value: string;
    agent?: AgentMessage;
}

const createAgentOption = (agent: AgentMessage): AgentOption => {
    return {
        value: agent.id,
        label: agent.name,
        agent: agent,
    };
};

interface FormikAgentSelectFlowProps {
    name: string;
    customerId?: string;
    portId?: string;
    label: string;
}

export const FormikAgentSelectFlow = ({ name, customerId, portId, ...props }: FormikAgentSelectFlowProps) => {
    const [field, , helpers] = useField(name);
    const role = useRole();
    const { context } = useProductContext();

    const [inputAgentName, setInputAgentName] = useState('');

    const { push, pop } = useDrawer();
    const useAgentsQuery = useAgentList({
        customerIds: customerId ? [customerId] : [],
        portIds: portId ? [portId] : [],
    });

    const selectedAgentId = field.value;
    const selectedAgent = useAgentsQuery.data?.agents.find((agent: AgentMessage) => agent.id === selectedAgentId);

    const filterAgentByInput = (agent: AgentMessage) =>
        inputAgentName && inputAgentName !== ''
            ? agent.name.toLowerCase().includes(inputAgentName.toLowerCase())
            : true;

    const options: AgentOption[] = useAgentsQuery.data?.agents.filter(filterAgentByInput).map(createAgentOption) || [];

    const getAgentById = (id: string) => useAgentsQuery.data?.agents.find((agent: AgentMessage) => agent.id === id);
    const getSelectedValue = (): null | AgentOption => {
        if (!selectedAgentId) return null;
        const agent = getAgentById(selectedAgentId);
        if (!agent) return null;

        return createAgentOption(agent);
    };

    const onAgentFormSubmitSuccess = (agent?: AgentMessage) => {
        if (agent) {
            helpers.setValue(agent.id);
        }
        useAgentsQuery.refetch();
        pop();
    };

    const openCreateAgentModal = (option?: AgentOption) => {
        push({
            content: (
                <CreateAgentForm
                    data={{
                        name: option?.label || '',
                        customerId: customerId,
                        portIds: portId ? [portId] : [],
                    }}
                    onSuccess={onAgentFormSubmitSuccess}
                    comment={undefined}
                    showCompanySelect={role.isAdmin() || !role.isOneCompanyUser(context)}
                />
            ),
        });
    };

    const openUpdateAgentModal = () => {
        push({
            content: <UpdateAgentForm agentId={selectedAgentId} onSuccess={onAgentFormSubmitSuccess} />,
        });
    };

    if (!portId) {
        return (
            <div>
                <ComboBox disabled label={translate('selectAgent.label')} />

                <Box marginTop={3}>
                    <Callout variant="accent" description={translate('selectAgent.mandatoryFields')} />
                </Box>
            </div>
        );
    }

    if (useAgentsQuery.isInitialLoading || !useAgentsQuery.data) {
        return (
            <div>
                <LabelText>{translate('selectAgent.label')}</LabelText>
                <Skeleton style={{ lineHeight: '40px' }} />
            </div>
        );
    }

    if (!useAgentsQuery.data.agents.length) {
        return (
            <Box>
                <LabelText>{translate('selectAgent.label')}</LabelText>
                <Box display="flex" alignItems="center" height="40px">
                    <Button
                        type="button"
                        leadingVisual={<IconPlus width={16} height={16} />}
                        onClick={() => openCreateAgentModal()}
                    >
                        {translate('selectAgent.addAgent')}
                    </Button>
                </Box>
            </Box>
        );
    }

    const onInputChange = (input: string) => {
        setInputAgentName(input);
    };

    const onChange = (option: OnChangeValue<AgentOption, false>) => {
        if (option?.value === 'createAgent') {
            openCreateAgentModal(option);
        } else {
            helpers.setValue(option?.value);
        }
    };

    options.push({
        value: 'createAgent',
        label: inputAgentName || '',
    });

    return (
        <Select
            {...props}
            options={options}
            onChange={onChange}
            onInputChange={onInputChange}
            filterOption={() => true}
            value={getSelectedValue()}
            isClearable
            overrides={{
                Option: CustomAgentOption as any,
                SingleValue: AgentSingleValue as any,
            }}
            caption={
                selectedAgent && !selectedAgent.source ? (
                    <Button
                        emphasis="low"
                        type="button"
                        onClick={openUpdateAgentModal}
                        leadingVisual={<IconPencil height={16} width={16} />}
                    >
                        {translate('selectAgent.edit', {
                            additionalInfo: selectedAgent.name,
                        })}
                    </Button>
                ) : null
            }
        />
    );
};
