import { Error } from '../../common/Error/Error';
import { translate } from '../../common/helpers/translate.helper';
import { LoadingIndicator } from '../../common/LoadingIndicator/LoadingIndicator';
import { Box } from '../../common/ui/Box';

import { ContractForm } from './ContractForm';
import { useContractDetails } from './useContractDetails';
import { useContractEdit } from './useContractEdit';

export const EditContractForm = ({ id, onSuccess }) => {
    const { data, isPending, isError } = useContractDetails(id);
    const { mutate: updateContract, error } = useContractEdit(onSuccess);

    const errors = error?.response.data.errors;

    const handleSubmit = ({ data: contract }) => {
        updateContract(contract);
    };

    if (isPending) {
        return <LoadingIndicator variant="full-height" />;
    }

    if (isError) {
        return (
            <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                <Error />
            </Box>
        );
    }

    return (
        <ContractForm title={translate('contracts.editTitle')} onSubmit={handleSubmit} data={data} errors={errors} />
    );
};
