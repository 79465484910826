import { Button } from '../../../cdl/Button/Button';
import { Modal } from '../../../cdl/Modal/Modal';
import { Text } from '../../../cdl/Text/Text';
import { ButtonGroup } from '../../../common/buttons/ButtonGroup';
import { TextButton } from '../../../common/buttons/TextButton';
import { formatDateTime } from '../../../common/helpers/formatDateTime.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { Box } from '../../../common/ui/Box';

export const CreateOrderPopup = ({ isOpen, onDismiss, offer, onConfirm, isSubmitting }) => {
    return (
        <Modal isOpen={isOpen} onDismiss={onDismiss} scrollable={false}>
            <Box padding={6} display="grid" rowGap={5}>
                <Text variant="title">{translate('fuelOrder.offerList.confirmOrder.headline')}</Text>

                <Box>
                    <Text as="p" color="foreground.subtle">
                        {translate('fuelOrder.offerList.confirmOrder.offerSummary', {
                            supplierGroupName: offer.supplier.supplierGroup.name,
                            vessel: offer.vessel.name,
                            port: offer.port.name,
                            date: formatDateTime({
                                date: offer.dateDelivery,
                                format: 'll',
                            }),
                        })}
                    </Text>

                    <Text as="p" color="foreground.subtle">
                        {translate('fuelOrder.offerList.confirmOrder.gtc', {
                            supplierGroupName: offer.supplier.supplierGroup.name,
                        })}
                    </Text>

                    {offer.supplier.supplierGroup.gtcUrl ? (
                        <TextButton as="a" href={offer.supplier.supplierGroup.gtcUrl} target="_blank">
                            {translate('fuelOrder.offerList.confirmOrder.termsAndConditions')}
                        </TextButton>
                    ) : null}

                    <Text as="p" color="dark-blue.2">
                        {translate('fuelOrder.offerList.confirmOrder.remainingOffers')}
                    </Text>
                </Box>

                <ButtonGroup justifyContent="end">
                    <Button emphasis="high" onClick={onConfirm} isLoading={isSubmitting}>
                        {translate('fuelOrder.offerList.confirmOrder.confirm')}
                    </Button>
                </ButtonGroup>
            </Box>
        </Modal>
    );
};
