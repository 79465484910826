import { SurchargeMessage } from '../../../../../../../types/SurchargeMessage';
import { SurchargeType } from '../../../../../../../types/SurchargeType';
import { DefaultCell } from '../../../../../../cdl/TableBuilder/components/DefaultCell';
import { StyledDefaultTableBodyRow } from '../../../../../../cdl/TableBuilder/components/StyledDefaultTableBodyRow';
import { Text } from '../../../../../../cdl/Text/Text';
import { formatMoney } from '../../../../../../common/helpers/formatMoney.helper';
import { translate } from '../../../../../../common/helpers/translate.helper';
import { useEnums } from '../../../../../../common/hooks/useEnums';
import { Box } from '../../../../../../common/ui/Box';

import { getCellEmphasis } from './getCellEmphasis';

interface SurchargeRowProps {
    surcharge: SurchargeMessage;
    tableRowIndex: number;
    showPrices: boolean;
}

export const SurchargeRow = ({ surcharge, tableRowIndex, showPrices }: SurchargeRowProps) => {
    const { getHumanReadableValue } = useEnums();
    return (
        <StyledDefaultTableBodyRow
            alternateRowColors
            grow
            active={false}
            selectable={false}
            disabled={surcharge.deleted}
        >
            <DefaultCell numeric>{tableRowIndex + 1}</DefaultCell>
            <DefaultCell>
                <Box display="grid">
                    <Text color={surcharge.changes.nameChanged ? 'attention.foreground' : 'foreground.attention'}>
                        {surcharge.surchargeType === SurchargeType.CUSTOM
                            ? surcharge.name
                            : getHumanReadableValue('surchargeType', surcharge.surchargeType)}
                    </Text>
                    <Text variant="extraSmall" color={surcharge.deleted ? 'foreground.disabled' : 'foreground.subtle'}>
                        {translate(
                            surcharge.value.value > 0 ? 'order.surcharges.surcharge' : 'order.surcharges.waiver'
                        )}
                    </Text>
                </Box>
            </DefaultCell>

            {showPrices ? (
                <DefaultCell emphasis={getCellEmphasis(surcharge.changes.valueChanged)} colSpan={7} numeric>
                    {surcharge.value?.value
                        ? formatMoney({
                              value: surcharge.value.value,
                              currency: surcharge.value.currency,
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                          })
                        : '-'}
                </DefaultCell>
            ) : null}
        </StyledDefaultTableBodyRow>
    );
};
