import { Button } from '../../../cdl/Button/Button';
import { APPROVAL_STATE } from '../../../common/approvalState.constant';
import { InfoIconButton } from '../../../common/buttons/InfoIconButton';
import { translate } from '../../../common/helpers/translate.helper';
import { IconArrowBackUp } from '../../../common/icons/cdl/ArrowBackUp';

import { SendRequestAgainButton } from './SendRequestAgainButton';

export const OrderedRequestButton = ({ offerApprovalSettings, approvalRequests, onRequestApprovalButtonClicked }) => {
    if (!approvalRequests.length && offerApprovalSettings.requiresApproval) {
        return (
            <Button onClick={onRequestApprovalButtonClicked}>
                {translate('approvalRequest.buttons.requestApproval')}
            </Button>
        );
    }

    if (approvalRequests.length) {
        const latestApprovalRequest = approvalRequests[0];

        switch (latestApprovalRequest.state) {
            case APPROVAL_STATE.PENDING: {
                if (!offerApprovalSettings.requiresApproval) {
                    return null;
                }

                return <SendRequestAgainButton onClick={onRequestApprovalButtonClicked} />;
            }

            case APPROVAL_STATE.APPROVED:
                return null;
            default:
                return (
                    <InfoIconButton
                        icon={<IconArrowBackUp height={20} width={20} />}
                        onClick={onRequestApprovalButtonClicked}
                    >
                        {translate('approvalRequest.buttons.reRequestApproval')}
                    </InfoIconButton>
                );
        }
    }

    return null;
};
