import { formatFuelMoney } from '../helpers/formatFuelMoney.helper';
import { translate } from '../helpers/translate.helper';
import { useEnums } from '../hooks/useEnums';
import { Td } from '../Table/Td';
import { Tr } from '../Table/Tr';

const SurchargeLabel = ({ surcharge }) => {
    return surcharge.value.value > 0 ? translate('order.surcharges.surcharge') : translate('order.surcharges.waiver');
};

const Surcharge = ({ surcharge, position, showPrices }) => {
    const { getHumanReadableValue } = useEnums();

    const displayName =
        surcharge.surchargeType === 'CUSTOM'
            ? surcharge.name
            : getHumanReadableValue('surchargeType', surcharge.surchargeType);

    return (
        <Tr inactive={surcharge.deleted} focused={surcharge.added && !surcharge.deleted}>
            <Td>{position}</Td>
            <Td danger={surcharge.changes?.nameChanged && !surcharge.deleted}>{displayName}</Td>
            <Td colSpan={6} danger={surcharge.changes?.surchargeTypeChanged && !surcharge.deleted}>
                <SurchargeLabel surcharge={surcharge} />
            </Td>
            {showPrices ? (
                <Td danger={surcharge.changes?.valueChanged && !surcharge.deleted}>
                    {formatFuelMoney({
                        value: surcharge.value.value,
                        currency: surcharge.value.currency,
                    })}
                </Td>
            ) : null}
        </Tr>
    );
};

export const Surcharges = ({ surcharges, showPrices, offset }) => {
    return surcharges.map((surcharge, index) => (
        <Surcharge key={index} surcharge={surcharge} position={offset + index + 1} showPrices={showPrices} />
    ));
};
