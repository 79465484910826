import { translate } from '../common/helpers/translate.helper';
import { LoadingIndicator } from '../common/LoadingIndicator/LoadingIndicator';

import { KeyPortForm } from './KeyPortForm';
import { useKeyPortDetails } from './useKeyPortDetails';
import { useKeyPortEdit } from './useKeyPortEdit';

export const EditKeyPortForm = ({ keyPortId, onSuccess }) => {
    const { data, isPending } = useKeyPortDetails(keyPortId);
    const { mutate: updateKeyPort, error } = useKeyPortEdit(onSuccess);

    const errors = error?.response.data.errors;

    const handleSubmit = ({ data: keyPort }) => {
        updateKeyPort({ keyPort, keyPortId });
    };

    if (isPending) {
        return <LoadingIndicator variant="full-height" />;
    }

    return (
        <KeyPortForm
            title={translate('keyPorts.editTitle')}
            onSubmit={handleSubmit}
            errors={errors}
            data={data}
            update={true}
        />
    );
};
