import { Table as _Table } from '../Table/Table';
import { Td as _Td } from '../Table/Td';
import { Th as _Th } from '../Table/Th';
import { Tr as _Tr } from '../Table/Tr';

/**
 * @deprecated use CDL TableBuilder instead
 */

export const DataTable = ({
    columns,
    rows,
    onRowClick,
    isRowUnread,
    Tr = _Tr,
    Th = _Th,
    Td = _Td,
    Table = (props) => <_Table hover cursor {...props} />,
}) => {
    const onTdDivClick = (field) => (event) => {
        if (field.interactive) {
            event.stopPropagation();
        }
    };

    return (
        <Table>
            <thead>
                <Tr>
                    {columns.map((field) => (
                        <Th width={field.width} key={field.label}>
                            {field.label}
                        </Th>
                    ))}
                </Tr>
            </thead>
            <tbody>
                {rows.map((row) => (
                    <Tr key={row.id} onClick={() => onRowClick?.({ row })} unread={isRowUnread?.({ row })}>
                        {columns.map((field) => (
                            <Td key={`${field.label}.${row.id}`}>
                                <div onClick={onTdDivClick(field)}>{field.value(row)}</div>
                            </Td>
                        ))}
                    </Tr>
                ))}
            </tbody>
        </Table>
    );
};
