import { SelectBox } from '../../../common/SelectBox/SelectBox';
import { PriceBoxContent } from '../PriceBoxContent';

export const ComparePriceButton = ({ selected, price, onClick }) => {
    return (
        <SelectBox onClick={onClick} selected={selected} height="220px">
            <PriceBoxContent price={price} selected={selected} />
        </SelectBox>
    );
};
