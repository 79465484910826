import { useLocation, useNavigate } from '@tanstack/react-router';
import { Fragment, useState } from 'react';

import { useToasts } from '../../../cdl/Toast/useToasts';
import { ActionIconListItem } from '../../../common/ActionBar/ActionIconListItem';
import { ActionIconListWrapper } from '../../../common/ActionBar/ActionIconListWrapper';
import { translate } from '../../../common/helpers/translate.helper';
import { useRole } from '../../../common/hooks/useRole';
import { IconArrowRampRight3 } from '../../../common/icons/cdl/ArrowRampRight3';
import { IconCircleX } from '../../../common/icons/cdl/CircleX';
import { IconCopy } from '../../../common/icons/cdl/Copy';
import { IconExport } from '../../../common/icons/cdl/Export';
import { IconList } from '../../../common/icons/cdl/List';
import { IconListDetails } from '../../../common/icons/cdl/ListDetails';
import { IconPencil } from '../../../common/icons/cdl/Pencil';
import { OrderCancelPopup } from '../../../common/OrderCancelPopup/OrderCancelPopup';
import { useOrderExport } from '../../../offer/detail/common/hooks/useOrderExport';
import { useOrderRedispatch } from '../common/useOrderRedispatch';

import { ProductPopup } from './ProductPopup';
import { useOrderCancel } from './useOrderCancel';

export const OrderDetailActionBar = ({ order }) => {
    const [showCancelPopup, setShowCancelPopup] = useState(false);
    const [showProductPopup, setShowProductPopup] = useState(false);
    const { addErrorToast, addToast, addLoadingToast } = useToasts();

    const cancelOrderMutation = useOrderCancel(order);
    const redispatchOrderMutation = useOrderRedispatch();
    const { downloadPdf } = useOrderExport();

    const role = useRole();
    const navigate = useNavigate();
    const location = useLocation();

    const copy = (order) => {
        navigate({
            to: '/fuel/create',
            state: {
                order,
                originLocation: location,
            },
        });
    };

    const edit = (orderId) => {
        navigate({
            to: '/fuel/order/$id/edit',
            params: {
                id: orderId,
            },
        });
    };

    const onCancelPopupSubmit = async ({ cancelReason }) => {
        await cancelOrderMutation.cancelOrder(cancelReason);
        setShowCancelPopup(false);
    };

    const iconList = [];

    if (role.isCustomer() && role.hasWriteRights(order.customerId) && !order.isFinished()) {
        iconList.push(
            <ActionIconListItem
                onClick={() => {
                    edit(order.id);
                }}
                tooltip={translate('fuelOrder.actions.edit')}
                ariaLabel="Edit"
            >
                <IconPencil />
            </ActionIconListItem>
        );
    }

    if (role.isCustomer() && role.hasWriteRights(order.customerId)) {
        iconList.push(
            <ActionIconListItem
                onClick={() => {
                    copy(order);
                }}
                tooltip={translate('fuelOrder.actions.copy')}
            >
                <IconCopy />
            </ActionIconListItem>
        );
    }

    if (role.isAdmin() && order.isOpen()) {
        iconList.push(
            <ActionIconListItem
                onClick={() => navigate({ to: '/fuel/offers', search: { searchQuery: order.id } })}
                tooltip={translate('fuelOrder.actions.showOfferList')}
            >
                <IconList />
            </ActionIconListItem>
        );
    }

    if (role.isAdmin()) {
        iconList.push(
            <ActionIconListItem
                onClick={() => {
                    redispatchOrderMutation.mutate(order.id, {
                        onSuccess: () => {
                            addToast(translate('order.detail.redispatchSuccessToast'));
                        },
                        onError: () => {
                            addErrorToast(translate('order.detail.redispatchErrorToast'));
                        },
                    });
                }}
                tooltip={translate('fuelOrder.actions.redispatch')}
            >
                <IconArrowRampRight3 />
            </ActionIconListItem>
        );
    }

    iconList.push(
        <ActionIconListItem
            onClick={() => setShowProductPopup(true)}
            tooltip={translate('fuelOrder.actions.orderedProducts')}
        >
            <IconListDetails />
        </ActionIconListItem>
    );

    iconList.push(
        <ActionIconListItem
            onClick={() => {
                return addLoadingToast(
                    () => downloadPdf(order.id),
                    translate('offer.detail.header.actions.export.downloadingPdf'),
                    translate('offer.detail.header.actions.export.success'),
                    translate('global.genericToastError')
                );
            }}
            tooltip={translate('order.detail.header.actions.export')}
        >
            <IconExport />
        </ActionIconListItem>
    );

    if (role.isCustomer() && !order.isFinished() && role.hasWriteRights(order.customerId)) {
        iconList.push(
            <ActionIconListItem
                onClick={() => setShowCancelPopup(true)}
                tooltip={translate('fuelOrder.actions.cancelAll')}
            >
                <IconCircleX color="red" />
            </ActionIconListItem>
        );
    }

    return (
        <>
            <ActionIconListWrapper>
                {iconList.map((entry, index) => (
                    <Fragment key={index}>{entry}</Fragment>
                ))}
            </ActionIconListWrapper>

            {showCancelPopup ? (
                <OrderCancelPopup
                    isOpen={showCancelPopup}
                    onSubmit={onCancelPopupSubmit}
                    onDismiss={() => setShowCancelPopup(false)}
                    isSubmitting={cancelOrderMutation.isPending}
                />
            ) : null}

            <ProductPopup isOpen={showProductPopup} onDismiss={() => setShowProductPopup(false)} order={order} />
        </>
    );
};
