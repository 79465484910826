import { useEffect, useState } from 'react';

import { getCustomers } from '../../api/clients/company.api';
import { formatCompanyName } from '../../helpers/formatCompanyName.helper';
import { translate } from '../../helpers/translate.helper';
import { useProductContext } from '../../hooks/useProductContext';
import { useRole } from '../../hooks/useRole';
import { AsyncSearchSelect } from '../AsyncSearchSelect/AsyncSearchSelect';
import { useFormData } from '../Form/useFormData';

/**
 * @deprecated use CustomerCompanySelect instead
 * **/

const ContextCustomerCompanySelect = ({ onChange, companyId, fallbackContext }) => {
    const role = useRole();
    const { context } = useProductContext();
    const [selectedValue, setSelectedValue] = useState(null);

    const selectedContext = context ?? fallbackContext;

    useEffect(() => {
        if (companyId) {
            getCustomers({ ids: [companyId] })
                .then((response) => {
                    return response.map((company) => ({
                        value: company.id,
                        label: formatCompanyName({
                            company,
                            includeType: role.isAdmin(),
                        }),
                    }));
                })
                .then((options) => {
                    setSelectedValue(options[0]);
                });
        }
    }, [companyId, role]);

    const loadCompaniesByInput = (searchQuery) => {
        return new Promise((resolve) => {
            getCustomers({
                searchQuery,
                type: selectedContext,
            })
                .then((response) => {
                    return response.map((company) => ({
                        value: company.id,
                        label: formatCompanyName({
                            company,
                            includeType: role.isAdmin(),
                        }),
                    }));
                })
                .then((companies) => resolve(companies));
        });
    };

    function updateSelectedValue(option) {
        onChange(option.value);
    }

    return (
        <AsyncSearchSelect
            values={selectedValue}
            onChange={updateSelectedValue}
            loadOptions={loadCompaniesByInput}
            placeholder={translate('vessel.searchCompanyPlaceholder')}
        />
    );
};

export const FormContextCompanySelect = ({ dataPath, fallbackContext }) => {
    const { value, onChange } = useFormData(dataPath, {
        default: null,
    });
    const onChangeHandle = (value) => {
        onChange({ target: { value: value } });
    };

    return (
        <ContextCustomerCompanySelect onChange={onChangeHandle} companyId={value} fallbackContext={fallbackContext} />
    );
};
