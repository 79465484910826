import { get } from 'lodash';

import { Text } from '../../cdl/Text/Text.tsx';
import { Box } from '../ui/Box.tsx';
import { Flex } from '../ui/Flex.tsx';
import { Grid } from '../ui/Grid';

import { PopupButton } from './PopupButton';

export const CoreData = ({ data, focusFields, baseFields, columnWidth = 1 / 3 }) => {
    const getField = (path) => get(data, path);

    const hasFieldChanged = (field) => {
        if (!field.hasChanged) {
            return false;
        }

        return field.hasChanged(data);
    };

    const renderField = (field) => {
        const content = field.render ? field.render(getField(field.path), getField, data) : getField(field.path);

        const hasChanged = hasFieldChanged(field);

        return content ? (
            <Text color={hasChanged ? 'negative.emphasis' : 'inherit'}>{content}</Text>
        ) : (
            <Text color="dark-blue.3">{field.fallback || '-'}</Text>
        );
    };

    return (
        <Box>
            {focusFields ? (
                <Flex marginBottom={8}>
                    {focusFields.map((field, index) => {
                        return (
                            <Flex
                                key={index}
                                backgroundColor="clear-blue.9"
                                borderRadius={1}
                                marginRight={6}
                                flexDirection="column"
                            >
                                <Flex flexDirection="column" padding={5} height="100%" justifyContent="space-between">
                                    <Box>
                                        <Text
                                            size={12}
                                            weight="semi"
                                            color="dark-blue.2"
                                            display="block"
                                            marginBottom={4}
                                            caps
                                        >
                                            {field.label}
                                        </Text>

                                        {renderField(field)}
                                    </Box>

                                    {field.popup ? (
                                        <Box marginTop={6}>
                                            <PopupButton data={field.popup(getField(field.path), getField, data)} />
                                        </Box>
                                    ) : null}
                                </Flex>
                            </Flex>
                        );
                    })}
                </Flex>
            ) : null}

            {baseFields ? (
                <Grid>
                    {baseFields.map((field, index) => {
                        return (
                            <Box
                                width={columnWidth}
                                key={(focusFields?.length ? focusFields?.length : 0) + index}
                                marginBottom={6}
                                padding={0}
                            >
                                <Text size={12} weight="semi" color="dark-blue.3" display="block" marginBottom={2} caps>
                                    {field.label}
                                </Text>
                                <Text>{renderField(field)}</Text>
                            </Box>
                        );
                    })}
                </Grid>
            ) : null}
        </Box>
    );
};
