import moment from 'moment';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { Text } from '../../../../cdl/Text/Text';
import { formatDateTime } from '../../../../common/helpers/formatDateTime.helper';
import { translate } from '../../../../common/helpers/translate.helper';
import { PositionMarker } from '../../../../common/PositionMarker/PositionMarker';
import { Box } from '../../../../common/ui/Box';
import { get } from '../../../../common/ui/get';
import { theme } from '../../../../common/ui/theme';
import { Port } from '../../../../port/Port.tsx';
import { PositionType } from '../hooks/useOfferPositions';
import { PositionData } from '../types/PositionData';
import { getMarkerColor } from '../util/getMarkerColor';

const PositionItem = styled.li`
    display: flex;
    flex-direction: column;
    padding-left: 40px;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 16px;
        left: 17px;
        border-left: 2px dashed ${get('colors.background.muted')};
        height: 100%;
        width: 12px;
        z-index: 1;
    }

    &:first-of-type {
        &:before {
            top: 16px;
        }
    }

    &:last-of-type {
        &:before {
            border-left: none;
        }
    }

    &:only-of-type {
        &:before {
            border-left: none;
        }
    }

    &:hover {
        cursor: pointer;
        background-color: ${theme.colors.background.subtle};
    }
`;

const IconWrapper = styled(Box)`
    position: absolute;
    left: 11px;
    top: 12px;
    height: 14px;
    width: 14px;
    z-index: 2;
`;

interface PositionListItemWrapperProps {
    title: string;
    icon: ReactNode;
    children: ReactNode;
    disabled?: boolean;
}

const PositionListItemWrapper = ({ title, icon, children, disabled }: PositionListItemWrapperProps) => {
    return (
        <Box paddingBottom={4} paddingTop={4}>
            <IconWrapper>{icon}</IconWrapper>

            <Text variant="small" fontWeight="semiBold" color={disabled ? 'foreground.disabled' : 'foreground.default'}>
                {title}
            </Text>

            <Box display="flex" flexDirection="column" color={disabled ? 'foreground.disabled' : 'foreground.default'}>
                {children}
            </Box>
        </Box>
    );
};

interface PositionProps {
    position: PositionData;
    onClick: () => void;
}

export const Position = ({ position, onClick }: PositionProps) => {
    const markerColor = getMarkerColor(position.type);

    switch (position.type) {
        case PositionType.ORDER_PORT:
            return (
                <PositionItem onClick={onClick}>
                    <PositionListItemWrapper
                        icon={<PositionMarker color={markerColor} />}
                        title={
                            position.isAlsoNextPort
                                ? translate('offerPositions.orderAndNextPort')
                                : translate('offerPositions.orderPort')
                        }
                    >
                        <Port port={position.port} vesselId={position.vesselId} showTooltip />
                        <Text variant="small" color="foreground.subtle">
                            {translate('offerPositions.deliveryDate', {
                                date: formatDateTime({
                                    date: position.offerDateDelivery,
                                    format: 'll',
                                }),
                            })}
                        </Text>
                    </PositionListItemWrapper>
                </PositionItem>
            );

        case PositionType.VESSEL:
            return (
                <PositionItem onClick={onClick}>
                    <PositionListItemWrapper
                        title={translate('offerPositions.currentPosition')}
                        icon={<PositionMarker color={markerColor} />}
                    >
                        <Text variant="small" color="foreground.subtle">
                            {translate('offerPositions.updated', {
                                counter: moment(position.vessel.locationData.datePositionReceived).fromNow(),
                            })}
                        </Text>
                    </PositionListItemWrapper>
                </PositionItem>
            );

        case PositionType.NEXT_PORT:
            return (
                <PositionItem onClick={onClick}>
                    <PositionListItemWrapper
                        title={translate('offerPositions.nextPort')}
                        icon={<PositionMarker color={markerColor} />}
                    >
                        <Port port={position.port} vesselId={position.vesselId} showTooltip />
                        <Text variant="small" color="foreground.subtle">
                            {translate('offerPositions.eta', {
                                date: formatDateTime({
                                    date: position.eta!,
                                    timeZoneId: position.port.timeZoneId,
                                }),
                            })}
                        </Text>
                    </PositionListItemWrapper>
                </PositionItem>
            );

        case PositionType.LAST_PORT:
            return (
                <PositionItem onClick={onClick}>
                    <PositionListItemWrapper
                        title={translate('offerPositions.lastPort')}
                        icon={<PositionMarker color={markerColor} />}
                        disabled
                    >
                        <Port
                            port={position.port}
                            vesselId={position.vesselId}
                            highlightColor="dark-blue.5"
                            showTooltip
                        />
                        <Text variant="small">
                            {translate('offerPositions.eta', {
                                date: formatDateTime({
                                    date: position.eta!,
                                    timeZoneId: position.port.timeZoneId,
                                }),
                            })}
                        </Text>
                    </PositionListItemWrapper>
                </PositionItem>
            );
        default:
            return null;
    }
};
