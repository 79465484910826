import { Text } from '../../cdl/Text/Text.tsx';
import { Box } from '../ui/Box.tsx';

export const CoreDataInfo = ({ primary, secondary }) => {
    return (
        <Box>
            {primary ? <Text>{primary}</Text> : null}
            <Box>
                {secondary
                    .filter((value) => value)
                    .map((value, index) => {
                        return (
                            <Text key={index} size={12} display="block" marginTop={2}>
                                {value}
                            </Text>
                        );
                    })}
            </Box>
        </Box>
    );
};
