import { Button } from '../../../../cdl/Button/Button';
import { Modal } from '../../../../cdl/Modal/Modal';
import { Text } from '../../../../cdl/Text/Text';
import { useToasts } from '../../../../cdl/Toast/useToasts';
import { ButtonGroup } from '../../../../common/buttons/ButtonGroup';
import { DataSheet } from '../../../../common/DataSheet/DataSheet';
import { formatDateDelivery } from '../../../../common/helpers/formatDateDelivery.helper';
import { translate } from '../../../../common/helpers/translate.helper';
import { Box } from '../../../../common/ui/Box';
import { Port } from '../../../../port/Port';

export const ConfirmDeliveryPopup = ({ onConfirm, onDismiss, isOpen, offer, isSubmitting, children }) => {
    const { addToast, addErrorToast } = useToasts();

    const onSubmit = async () => {
        try {
            await onConfirm();
            addToast(translate('alert.state.DELIVERED.title'));
        } catch {
            addErrorToast(translate('global.genericToastError'));
        }
    };

    return (
        <Modal isOpen={isOpen} onDismiss={onDismiss} width={1000}>
            <Box padding={6} display="grid" rowGap={5}>
                <Text variant="title" as="h1">
                    {translate('order.confirmdelivery')}
                </Text>

                <Text color="foreground.subtle">{translate('order.confirmdeliverycopy')}</Text>

                <Box width="100%">
                    <DataSheet>
                        <DataSheet.Label>{translate('order.port')}</DataSheet.Label>
                        <Port port={offer.port} vesselId={offer.vesselId} />

                        <DataSheet.Label>{translate('order.dateDeliveryConfirmed')}</DataSheet.Label>
                        {formatDateDelivery({ date: offer.dateDelivery })}
                    </DataSheet>
                </Box>

                {children}

                <ButtonGroup justifyContent="end">
                    <Button emphasis="high" onClick={onSubmit} isLoading={isSubmitting}>
                        {translate('actions.confirm')}
                    </Button>
                </ButtonGroup>
            </Box>
        </Modal>
    );
};
