import { Button } from '../../../cdl/Button/Button';
import { Form } from '../../../common/form-elements/Form/Form';
import { translate } from '../../../common/helpers/translate.helper';
import { IconPlus } from '../../../common/icons/cdl/Plus';
import { Box } from '../../../common/ui/Box';

import { useCompareSelectionState } from './hooks/useCompareSelectionState';
import { OverlayPortPriceInformation } from './OverlayPortPriceInformation';
import { usePriceCompareOverlay } from './usePriceCompareOverlay';

export const OverlayPriceInfoSection = ({ items, portIds, customerId, schedules }) => {
    const { state, actions, isFetching, priceMap } = usePriceCompareOverlay({
        items,
        portIds,
        customerId,
    });

    const { selection, setSelection } = useCompareSelectionState();

    const onChange = ({ data }) => actions.syncData(data);
    const onPortAddedClick = () => actions.addPort();
    const onRemovePortButtonClick = (portId) => (index) => {
        actions.removePort(index);
        if (portId === selection.portId) {
            setSelection({
                portId: null,
                supplierIds: [],
            });
        }
    };

    const onSelectBoxClick = (portId, supplierId) => {
        if (selection.portId !== portId) {
            selection.portId = portId;
            selection.supplierIds = [supplierId];
        } else {
            if (selection.supplierIds.includes(supplierId)) {
                const index = selection.supplierIds.indexOf(supplierId);
                if (index !== -1) {
                    selection.supplierIds.splice(index, 1);
                }
            } else {
                selection.supplierIds.push(supplierId);
            }
        }

        setSelection({ ...selection });
    };

    const _onPortAddedClick = (event) => {
        event.preventDefault();

        onPortAddedClick();
    };

    return (
        <Form data={state} onChange={onChange}>
            <Box marginTop={6} padding={8} backgroundColor="white">
                <Box>
                    {state.portInformation.map((portInformation, index) => {
                        const supplierData = priceMap?.[portInformation.portId];

                        const showRemoveIcon = Object.keys(state.portInformation).length > 1;

                        return (
                            <OverlayPortPriceInformation
                                key={portInformation.id}
                                index={index}
                                onRemovePortButtonClick={onRemovePortButtonClick(portInformation.portId)}
                                isFetching={isFetching}
                                supplierData={supplierData}
                                portId={portInformation.portId}
                                showRemoveIcon={showRemoveIcon}
                                onSelectBoxClick={onSelectBoxClick}
                                schedules={schedules}
                            />
                        );
                    })}

                    <Box width="200px" style={{ flexShrink: 0 }}>
                        <Button leadingVisual={<IconPlus width={16} height={16} />} onClick={_onPortAddedClick}>
                            {translate('compare.addPort')}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Form>
    );
};
