import { useQuery, useQueryClient } from '@tanstack/react-query';

import { AssignmentGatewayMessage } from '../../../../types/AssignmentGatewayMessage.ts';
import { AssignmentStatus } from '../../../../types/AssignmentMessage.ts';
import { getAssignmentList } from '../../../common/api/clients/gateway.api';
import { queryKeys } from '../../../common/api/queryKeys';

export const useAssignmentList = (customerId?: string, portId?: string, testOrder?: boolean) => {
    const queryClient = useQueryClient();

    const query = useQuery({
        queryKey: queryKeys.assignments({ customerIds: [customerId] }),
        queryFn: () => getAssignmentList({ customerIds: [customerId] }),
        enabled: !!customerId,
        select: (assignments: AssignmentGatewayMessage[]) => {
            return assignments
                .filter((assignment) => {
                    // Lubes assignments do not have port limitations and should not be filtered out
                    if (!assignment.portLimitations?.length) {
                        return true;
                    }

                    // If there is no provided portId, no need to filter.
                    if (!portId) {
                        return true;
                    }

                    return assignment.portLimitations.some((limitationPortId) => limitationPortId === portId);
                })
                .filter((assignment) => assignment.supplier?.testCompany === testOrder)
                .filter((assignment) => assignment.status === AssignmentStatus.CONFIRMED);
        },
    });

    const prefetch = (customerId) => {
        queryClient.prefetchQuery({
            queryKey: queryKeys.assignments({ customerIds: [customerId] }),
            queryFn: () => getAssignmentList({ customerIds: [customerId] }),
        });
    };

    return {
        ...query,
        prefetch,
    };
};
