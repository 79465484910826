import React from 'react';
import styled from 'styled-components';

import { CategoryState } from '../../../types/ReachInformationMessage';
import { Text } from '../../cdl/Text/Text';
import { Tooltip } from '../../cdl/Tooltip/Tooltip';
import { translate } from '../../common/helpers/translate.helper';
import { useFuelVolumeFormat } from '../../common/hooks/useFuelVolumeFormat';
import { Box } from '../../common/ui/Box';

interface RobTooltipLabelProps {
    label: string;
    value: number | undefined;
}

const BoxWithCenteredText = styled(Box)`
    text-align: center;
`;

const RobTooltipLabel = ({ label, value }: RobTooltipLabelProps) => {
    const formatVolume = useFuelVolumeFormat(false);
    return (
        <BoxWithCenteredText display="flex" flexDirection="column" gap={1}>
            <Text>{label}</Text>
            {value ? <Text weight="bold">{formatVolume({ volume: value })}</Text> : null}
        </BoxWithCenteredText>
    );
};

interface DemandRemainingOnBoardProps {
    volume: number;
    categoryState?: CategoryState;
    safetyReserve?: number;
    warningLimit?: number;
}

export const DemandRemainingOnBoard = ({
    volume,
    categoryState,
    safetyReserve,
    warningLimit,
}: DemandRemainingOnBoardProps) => {
    const formatVolume = useFuelVolumeFormat(false);

    const getTextColor = () => {
        switch (categoryState) {
            case CategoryState.SAFETY_RESERVE:
            case CategoryState.WARNING_LIMIT:
                return 'attention.foreground';

            case CategoryState.OUT_OF_REACH:
                return 'negative.foreground';

            case CategoryState.IN_REACH:
            case undefined:
                return 'foreground.default';
        }
    };
    const getTooltipLabel = () => {
        switch (categoryState) {
            case CategoryState.SAFETY_RESERVE:
                return translate('demand.onSafetyReserveTooltip');

            case CategoryState.WARNING_LIMIT:
                return translate('demand.warningLimitTooltip');

            case CategoryState.OUT_OF_REACH:
                return translate('demand.outOfReachTooltip');

            case CategoryState.IN_REACH:
            case undefined:
                return undefined;
        }
    };
    const getFontWeight = () => {
        switch (categoryState) {
            case CategoryState.SAFETY_RESERVE:
            case CategoryState.WARNING_LIMIT:
            case CategoryState.OUT_OF_REACH:
                return 'semiBold';

            case CategoryState.IN_REACH:
            case undefined:
                return 'normal';
        }
    };

    const getLimitValue = () => {
        switch (categoryState) {
            case CategoryState.SAFETY_RESERVE:
                return safetyReserve;

            case CategoryState.WARNING_LIMIT:
                return warningLimit;
            default:
                return undefined;
        }
    };

    const tooltipLabel = getTooltipLabel();

    if (!tooltipLabel) {
        return (
            <Text variant="small" fontWeight={getFontWeight()} color={getTextColor()}>
                {formatVolume({ volume })}
            </Text>
        );
    }

    return (
        <Tooltip label={<RobTooltipLabel label={tooltipLabel} value={getLimitValue()} />}>
            <Text variant="small" fontWeight={getFontWeight()} color={getTextColor()}>
                {formatVolume({ volume })}
            </Text>
        </Tooltip>
    );
};
