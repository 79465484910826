import { useNavigate } from '@tanstack/react-router';
import { useState } from 'react';

import { Callout } from '../../../cdl/Callout/Callout';
import { Text } from '../../../cdl/Text/Text';
import { FormContextCompanySelect } from '../../../common/form-elements/CompanySelect/ContextCustomerCompanySelect';
import { Form } from '../../../common/form-elements/Form/Form';
import { translate } from '../../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle';
import { useProductContext } from '../../../common/hooks/useProductContext';
import { useRole } from '../../../common/hooks/useRole';
import { Page } from '../../../common/Page/Page';
import { PageGrid } from '../../../common/PageGrid';
import { Box } from '../../../common/ui/Box';

import { ItemsTable } from './ItemsTable';
import { PriceInfoSection } from './PriceInfoSection';
import { usePriceCompareOverview } from './usePriceCompareOverview';
import { FormVesselSelect } from '../../../vessel/VesselSelect';
import { ProductContext } from '../../../../types/ProductContext';
import { Label } from '../../../common/form-elements/Label/Label';
import { getScheduleList } from '../../../common/api/clients/gateway.api';
import moment from 'moment/moment';
import { generateRandomId } from '../generateRandomId';

export const CompareOverview = () => {
    const [valid, setValid] = useState(false);
    useDocumentTitle(translate('compare.title'));

    const role = useRole();
    const { context } = useProductContext();
    const navigate = useNavigate();
    const { state, actions, priceMap, isFetching } = usePriceCompareOverview({
        enabled: valid,
    });

    const addItem = () => actions.addItem();
    const onRemoveItemClick = (index) => actions.removeItem(index);
    const onPortAddedClick = () => actions.addPort();
    const onRemovePortButtonClick = (index) => actions.removePort(index);
    const onChange = ({ data }) => {
        if (data.vessel?.id !== state.vessel?.id) {
            handleVesselChange(data.vessel);
            return;
        }

        if (data.customerId !== state.customerId && state.vessel?.id) {
            actions.syncData({ ...data, vessel: null, portInformation: [] });
            actions.addPort();
            return;
        }

        actions.syncData(data);
    };

    const handleVesselChange = async (vessel) => {
        if (!vessel) {
            actions.syncData({ ...state, vessel: null, portInformation: [] });
            actions.addPort();
            return;
        }
        const etdFrom = moment.utc(moment()).startOf('day').toISOString();
        const etdTo = moment.utc(moment().add(120, 'days')).endOf('day').toISOString();

        const scheduleList = await getScheduleList({
            imo: vessel.imo,
            etdFrom,
            etdTo,
            customerGroupId: vessel.customer?.customerGroupId,
        });

        const schedulePortIds = scheduleList
            .filter((schedule) => schedule.port)
            .map((schedule) => {
                return { portId: schedule.port.id, id: generateRandomId() };
            });
        if (schedulePortIds.length) {
            actions.syncData({ ...state, vessel, portInformation: schedulePortIds });
        } else {
            actions.syncData({ ...state, vessel });
        }
    };

    const onEnquiryButtonClick = (portId) => {
        navigate({
            to: '/orders/new',
            state: {
                items: state.items,
                portId,
            },
        });
    };

    return (
        <Page>
            <PageGrid>
                <Text variant="headline" as="h1">
                    {translate('compare.title')}
                </Text>

                <Callout
                    heading={translate('compare.page.bar.headline')}
                    description={translate('compare.page.bar.description')}
                />

                <Form data={state} onChange={onChange} isValid={setValid}>
                    <Box display="flex" gap={4}>
                        {!role.isOneCompanyUser(context) ? (
                            <Box minWidth="300px" maxWidth="33%">
                                <Label label={translate('compare.company')} required>
                                    <FormContextCompanySelect dataPath="customerId" fallbackContext="LUBES" />
                                </Label>
                            </Box>
                        ) : null}
                        <Box display="flex" gap={4} alignItems="flex-end">
                            <Box minWidth="300px" maxWidth="33%">
                                <FormVesselSelect
                                    label={translate('compare.vessel')}
                                    dataPath="vessel"
                                    context={ProductContext.LUBES}
                                    customerIds={[state.customerId]}
                                    disabled={!state.customerId}
                                    isClearable
                                />
                            </Box>
                        </Box>
                    </Box>

                    <Box marginTop={8}>
                        <ItemsTable
                            items={state.items}
                            onRemoveItemClick={onRemoveItemClick}
                            onAddItemClick={addItem}
                        />
                    </Box>

                    <Box marginTop={8}>
                        <PriceInfoSection
                            isFetching={isFetching}
                            portInformation={state.portInformation}
                            customerId={state.customerId}
                            priceMap={priceMap}
                            onRemovePortButtonClick={onRemovePortButtonClick}
                            onEnquiryButtonClick={onEnquiryButtonClick}
                            onPortAddedClick={onPortAddedClick}
                        />
                    </Box>
                </Form>
            </PageGrid>
        </Page>
    );
};
