import { ReactNode } from 'react';
import styled from 'styled-components';

import { Popover, PopoverProps } from '../../cdl/Popover/Popover';
import { IconInfoSquare } from '../icons/cdl/InfoSquare';

const TriggerButton = styled.button`
    width: 16px;
    height: 16px;
    background: transparent;
    color: inherit;
    border: none;
    padding: 0;

    &:hover {
        background: transparent;
    }
`;

interface InfoPopoverProps extends Omit<PopoverProps, 'trigger'> {
    children: ReactNode;
    width?: string;
}

export const InfoPopover = ({ align = 'center', side = 'top', children, ...rest }: InfoPopoverProps) => {
    return (
        <Popover
            align={align}
            side={side}
            {...rest}
            trigger={
                <TriggerButton
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                >
                    <IconInfoSquare height={16} width={16} />
                </TriggerButton>
            }
        >
            {children}
        </Popover>
    );
};
