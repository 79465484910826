import { ApprovalRequestMessage, DeclinedApprovalRequestMessage } from '../../../types/ApprovalRequestMessage';
import { OfferModel } from '../model/OfferModel';

import { ApprovalRequestBasicContent } from './ApprovalRequestBasicContent';
import { DeclinedStatusBar } from './DeclinedStatusBar';

interface DeclinedStateProps {
    offer: OfferModel;
    approvalRequests: ApprovalRequestMessage[];
    declinedApprovalRequest: DeclinedApprovalRequestMessage;
}

export const DeclinedState = ({ offer, approvalRequests, declinedApprovalRequest }: DeclinedStateProps) => {
    return (
        <ApprovalRequestBasicContent approvalRequests={approvalRequests} offer={offer}>
            <DeclinedStatusBar approvalRequest={declinedApprovalRequest} />
        </ApprovalRequestBasicContent>
    );
};
