import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import styled from 'styled-components';

import { StyledInput } from '../../common/form-elements/TextInput/Input';

// https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
const GrowingTextAreaWrapper = styled.div`
    display: grid;
    max-height: 15vh;
    word-break: break-word;

    &::after {
        content: attr(data-replicated-value) ' ';
        visibility: hidden;

        white-space: pre-wrap;
        max-height: inherit;
        outline: none;
        padding: 2px 10px;
        font: inherit;
        grid-area: 1 / 1 / 2 / 2;
    }
    textarea {
        white-space: pre-wrap;
        max-height: inherit;
        outline: none;
        padding: 2px 10px;
        font: inherit;
        grid-area: 1 / 1 / 2 / 2;

        resize: none;
        overflow-x: hidden;
    }
`;

export const GrowingTextArea = forwardRef(function GrowingTextAreaComponent(props, ref) {
    const { value, onChange, placeholder, disabled, onKeyDown } = props;

    const inputRef = useRef(null);

    useEffect(() => {
        inputRef.current.parentNode.dataset.replicatedValue = value;
    }, [value]);

    useImperativeHandle(ref, () => ({
        focus: () => {
            inputRef.current.focus();
        },
    }));

    return (
        <GrowingTextAreaWrapper>
            <StyledInput
                as="textarea"
                ref={inputRef}
                paddingRight={5}
                paddingLeft={5}
                paddingY={4}
                border={0}
                borderRadius={1}
                onChange={onChange}
                value={value}
                placeholder={placeholder}
                disabled={disabled}
                onKeyDown={onKeyDown}
            />
        </GrowingTextAreaWrapper>
    );
});
