import { useState } from 'react';
import styled from 'styled-components';

import { Button } from '../../cdl/Button/Button';
import { Input } from '../../cdl/Input/Input';
import { Modal } from '../../cdl/Modal/Modal';
import { Text } from '../../cdl/Text/Text';
import { isValidEmailAddress } from '../helpers/isValidEmailAddress.helper';
import { translate } from '../helpers/translate.helper';
import { Box } from '../ui/Box';

const EmailAddressForm = styled.form`
    margin-top: 24px;
`;

export const EmailAddressPopup = (props) => {
    const [emailAddress, setEmailAddress] = useState('');

    const onSubmit = (event) => {
        event.preventDefault();
        props.onSubmit({ emailAddress: emailAddress });
    };

    const isEmailAddressValid = isValidEmailAddress(emailAddress);

    return (
        <Modal isOpen={props.open} onDismiss={props.onDismiss} scrollable={false}>
            <Box padding={6}>
                <Text variant="title" as="h2" marginBottom={4}>
                    {props.headline}
                </Text>

                <Text variant="body">{props.description}</Text>

                <EmailAddressForm onSubmit={onSubmit}>
                    <Input
                        type="email"
                        label={translate('approvalRequest.requestApprovalPopup.emailAddressInput.label')}
                        value={emailAddress}
                        onChange={(e) => setEmailAddress(e.target.value)}
                    />

                    <Box display="flex" justifyContent="end" marginTop={6}>
                        <Button emphasis="high" disabled={!isEmailAddressValid}>
                            {props.submitButtonText}
                        </Button>
                    </Box>
                </EmailAddressForm>
            </Box>
        </Modal>
    );
};
