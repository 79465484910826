import { AverageConsumptionQuality } from './AverageConsumption';
import { ConsumptionCalculationBase } from './ConumptionCalculationBase';
import { FuelStockMessage } from './FuelStockMessage';
import { Iso8601DateTimeString } from './Iso8601DateTimeString';
import { ProductGroup } from './ProductGroup';
import { CategoryState } from './ReachInformationMessage';
import { SulphurContent } from './SulphurContent';

export interface DemandInformationStop {
    keyPort: boolean;
    currentPort: boolean;
    portLocode: string;
    scheduleId: string;
    categories: DemandInformationCategory[];
    eta: Iso8601DateTimeString;
    portName: string;
}

export interface DemandInformationLatestStocks
    extends Pick<FuelStockMessage, 'productGroup' | 'volume' | 'dateCreated'> {
    sulphurContent: string;
}

interface DemandOfferItem {
    productId: string;
    tankCategory?: string;
    productGroup?: string;
    sulphurContent?: string;
}

interface DemandInformationOffers {
    id: string;
    items: DemandOfferItem[];
}

export interface DemandInformationCategory {
    safetyReserve?: number;
    tankThreshold?: number;
    remainingVolume?: number;
    daysLeft?: number;
    averageDailyConsumption?: number;
    categoryName: string;
    sulphurContent: SulphurContent;
    productGroup: ProductGroup;
    categoryState?: CategoryState;
    maximumIntake?: number;
    tankCategory?: string;
    calculatedAverageDailyConsumption?: number;
    consumptionCalculationBase?: ConsumptionCalculationBase;
    qualityCategory?: AverageConsumptionQuality;
}

export enum VesselState {
    OPEN = 'OPEN',
    PLANNED = 'PLANNED',
    ORDERED = 'ORDERED',
}

export interface DemandInformationVessel {
    id: string;
    imo: string;
    name: string;
    email?: string;
    state: VesselState;
    categories: DemandInformationCategory[];
    stops: DemandInformationStop[];
    latestStocks: DemandInformationLatestStocks[];
    offers: DemandInformationOffers[];
    bunkerMetricRecommendation?: BunkerMetricRecommendation;
    bunkerStops?: BunkerStop[];
    customerId: string;
}

export interface DemandInformationMessage {
    vessels: DemandInformationVessel[];
}

interface BunkerMetricRecommendation {
    caseNumber: string;
    solutionNumber: string;
}

export interface BunkerStop {
    id: string;
}
