import { translate } from '../common/helpers/translate.helper';
import { LoadingIndicator } from '../common/LoadingIndicator/LoadingIndicator';

import { useAgentDetails } from './detail/hooks/useAgentDetails.js';

import { AgentForm } from './AgentForm/AgentForm';
import { useAgentEdit } from './useAgentEdit';

export const UpdateAgentForm = ({ agentId, onSuccess }) => {
    const agentQuery = useAgentDetails(agentId);
    const agentEditMutation = useAgentEdit(onSuccess);

    const handleSubmit = ({ data, event }) => {
        event.preventDefault();
        agentEditMutation.mutate({ agent: data, agentId });
    };

    if (agentQuery.isPending) {
        return <LoadingIndicator variant="full-height" />;
    }

    return <AgentForm title={translate('updateAgentForm.title')} onSubmit={handleSubmit} agent={agentQuery.data} />;
};
