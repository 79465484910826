import { useQuery } from '@tanstack/react-query';
import { Fragment, useState } from 'react';

import { Button } from '../../../cdl/Button/Button';
import { Callout } from '../../../cdl/Callout/Callout';
import { useDrawer } from '../../../cdl/Drawer/useDrawer.hook';
import { getAgentList } from '../../../common/api/clients/agent.api';
import { Select } from '../../../common/form-elements/Select/Select';
import { translate } from '../../../common/helpers/translate.helper';
import { useUpdateEffect } from '../../../common/hooks/useUpdateEffect';
import { IconPlus } from '../../../common/icons/cdl/Plus';
import { Box } from '../../../common/ui/Box.tsx';
import { CreateAgentForm } from '../../CreateAgentForm/CreateAgentForm';
import { UpdateAgentForm } from '../../UpdateAgentForm';

import { AgentSelectItem } from './AgentSelectItem';
import { AgentSelectSelectedItem } from './AgentSelectSelectedItem';
import { DeprecatedAgentSelect } from './DeprecatedAgentSelect';
import { EditAgentButton } from './EditAgentButton';

const createAgentOption = (agent) => ({
    label: <AgentSelectItem agent={agent} />,
    name: agent.name,
    value: agent.id,
});

const createSelectedAgentOption = (agent) => ({
    label: <AgentSelectSelectedItem agent={agent} />,
    value: agent.id,
});

/**
 * @deprecated use new AgentSelect and FormikAgentSelectFlow instead
 **/

export const DeprecatedAgentSelectFlow = ({
    customerId,
    portId,
    agentId,
    vesselId,
    onAgentSelect,
    creationComment,
}) => {
    const [selectedAgentId, setSelectedAgentId] = useState(agentId);
    const [inputAgentName, setInputAgentName] = useState(null);
    const { push, pop } = useDrawer();
    const { data, isLoading, refetch } = useQuery({
        queryKey: ['agents', portId, customerId],
        queryFn: () =>
            getAgentList({
                portIds: [portId],
                customerIds: [customerId],
            }),

        enabled: !!portId && !!vesselId,
    });

    const selectAgent = (agentId) => {
        setSelectedAgentId(agentId);
        onAgentSelect(agentId);
    };

    const filterAgentByInput = (agent) =>
        inputAgentName && inputAgentName !== ''
            ? agent.name.toLowerCase().includes(inputAgentName.toLowerCase())
            : true;

    const options = data ? data.agents.filter(filterAgentByInput).map(createAgentOption) : [];

    const getAgentById = (id) => data.agents.find((agent) => agent.id === id);
    const getSelectedValue = () => {
        if (!selectedAgentId) return null;
        const agent = getAgentById(selectedAgentId);
        if (!agent) return null;

        return createSelectedAgentOption(agent);
    };

    useUpdateEffect(() => {
        selectAgent(null);
    }, [portId, customerId]);

    useUpdateEffect(() => {
        selectAgent(agentId);
    }, [agentId]);

    /*
     * Handlers
     */

    const onAgentFormSubmitSuccess = (data) => {
        pop();
        refetch();

        if (data) {
            selectAgent(data.id);
        }
    };

    const openCreateAgentModal = () => {
        push({
            content: (
                <CreateAgentForm
                    data={{
                        name: inputAgentName || '',
                        customerId: customerId,
                        portIds: portId ? [portId] : [],
                    }}
                    comment={creationComment}
                    onSuccess={onAgentFormSubmitSuccess}
                />
            ),
        });
    };

    const openUpdateAgentModal = (event) => {
        event.preventDefault();

        push({
            content: <UpdateAgentForm agentId={selectedAgentId} onSuccess={onAgentFormSubmitSuccess} />,
        });
    };

    const onSelectChange = (newData) => {
        if (!newData) {
            selectAgent(null);
            return;
        }

        if (newData.value === 'createAgent') {
            openCreateAgentModal();
            return;
        }

        selectAgent(newData.value);
    };

    const onInputChange = (input) => {
        setInputAgentName(input);
    };

    /**
     * Views
     */

    if (!portId || !vesselId) {
        return (
            <Fragment>
                <Select isDisabled placeholder={translate('selectAgent.placeholder')} />

                <Box marginTop={3}>
                    <Callout variant="accent" description={translate('selectAgent.mandatoryFields')} />
                </Box>
            </Fragment>
        );
    }

    if (isLoading || !data) {
        return null;
    }

    if (!data.agents.length) {
        return (
            <Button leadingVisual={<IconPlus width={16} height={16} />} onClick={openCreateAgentModal}>
                {translate('selectAgent.addAgent')}
            </Button>
        );
    }

    const selectedAgent = getAgentById(selectedAgentId);

    const makeEditText = () => {
        if (!selectedAgent.address.zipCode || !selectedAgent.address.city || !selectedAgent.address.country) {
            return translate('selectAgent.edit', {
                additionalInfo: selectedAgent.name,
            });
        }

        return translate('selectAgent.edit', {
            additionalInfo: `${selectedAgent.name} (${selectedAgent.address.zipCode} ${selectedAgent.address.city}, ${selectedAgent.address.country})`,
        });
    };

    return (
        <Fragment>
            <DeprecatedAgentSelect
                options={options}
                onChange={onSelectChange}
                onInputChange={onInputChange}
                value={getSelectedValue()}
            />

            {selectedAgent && !selectedAgent.source ? (
                <EditAgentButton onClick={openUpdateAgentModal} marginTop={3}>
                    {makeEditText()}
                </EditAgentButton>
            ) : null}
        </Fragment>
    );
};
