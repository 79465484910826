import { ProductTable } from '../../../product/ProductTable/ProductTable';
import { ConfirmDeliveryPopup } from '../common/components/ConfirmDeliveryPopup';

export const LubesConfirmDeliveryPopup = ({ onDismiss, offer, onConfirm, isSubmitting, isOpen }) => {
    return (
        <ConfirmDeliveryPopup
            onDismiss={onDismiss}
            isOpen={isOpen}
            offer={offer}
            onConfirm={onConfirm}
            isSubmitting={isSubmitting}
        >
            <ProductTable offer={offer} showPrices={false} />
        </ConfirmDeliveryPopup>
    );
};
