import { SamplekitMessage } from '../../../../../../../types/SamplekitMessage';
import { DefaultCell } from '../../../../../../cdl/TableBuilder/components/DefaultCell';
import { StyledDefaultTableBodyRow } from '../../../../../../cdl/TableBuilder/components/StyledDefaultTableBodyRow';
import { Text } from '../../../../../../cdl/Text/Text';
import { formatMoney } from '../../../../../../common/helpers/formatMoney.helper';
import { translate } from '../../../../../../common/helpers/translate.helper';
import { Box } from '../../../../../../common/ui/Box';

import { getCellEmphasis } from './getCellEmphasis';

interface SampleKitRowProps {
    sampleKit: SamplekitMessage;
    tableRowIndex: number;
    showPrices: boolean;
}

export const SampleKitRow = ({ sampleKit, tableRowIndex, showPrices }: SampleKitRowProps) => {
    return (
        <StyledDefaultTableBodyRow
            alternateRowColors
            grow
            active={false}
            selectable={false}
            disabled={sampleKit.deleted}
        >
            <DefaultCell numeric>{tableRowIndex + 1}</DefaultCell>
            <DefaultCell>
                <Box display="grid">
                    <Text color={sampleKit.changes.nameChanged ? 'attention.foreground' : 'foreground.default'}>
                        {sampleKit.name || '-'}
                    </Text>
                    <Text variant="extraSmall" color={sampleKit.deleted ? 'foreground.disabled' : 'foreground.subtle'}>
                        {translate('order.samplekit.title')}
                    </Text>
                </Box>
            </DefaultCell>
            <DefaultCell colSpan={2}>{translate('order.samplekit.packtype')}</DefaultCell>
            <DefaultCell emphasis={getCellEmphasis(sampleKit.changes.quantityChanged)} numeric>
                {sampleKit.quantity || '-'}
            </DefaultCell>
            {showPrices ? (
                <DefaultCell emphasis={getCellEmphasis(sampleKit.changes.valueChanged)} colSpan={4} numeric>
                    {sampleKit.value?.value
                        ? formatMoney({
                              value: sampleKit.value?.value,
                              currency: sampleKit.value.currency,
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                          })
                        : '-'}
                </DefaultCell>
            ) : (
                <DefaultCell colSpan={4} numeric></DefaultCell>
            )}
        </StyledDefaultTableBodyRow>
    );
};
