import { CoreData } from '../../common/CoreData/CoreData';
import { formatDateDelivery } from '../../common/helpers/formatDateDelivery.helper';
import { translate } from '../../common/helpers/translate.helper';
import { useEnums } from '../../common/hooks/useEnums';
import { usePaymentTermsFormat } from '../../common/hooks/usePaymentTermsFormat';

const useAnonymousAdditionalDataFields = () => {
    const { getHumanReadableValue } = useEnums();
    const formatPaymentTerms = usePaymentTermsFormat();

    return {
        deliveryDate: {
            label: translate('global.coreData.deliveryDate'),
            path: 'dateDelivery',
            render: (dateDelivery) => {
                return formatDateDelivery({ date: dateDelivery });
            },
        },
        supplyMode: {
            label: translate('global.coreData.supplyMode'),
            path: 'supplyMode',
            render: (supplyMode) => {
                return getHumanReadableValue('supplyMode', supplyMode);
            },
        },
        paymentTerms: {
            label: translate('global.coreData.paymentTerms'),
            render: (paymentTerms, _, offer) => {
                return offer.paymentTermReference
                    ? formatPaymentTerms(offer.paymentTermReference, offer.paymentTermReferenceDays)
                    : '-';
            },
        },
        vendorReference: {
            label: translate('global.coreData.vendorReference'),
            path: 'vendorReference',
        },
    };
};

export const AnonymousQuotationAdditionalData = ({ offer }) => {
    const fields = useAnonymousAdditionalDataFields();

    return (
        <CoreData
            data={offer}
            baseFields={[fields.deliveryDate, fields.supplyMode, fields.paymentTerms, fields.vendorReference]}
            columnWidth={1 / 2}
        />
    );
};
