import { useField } from 'formik';

import { DeprecatedPortSelect } from '../../../port/DeprecatedPortSelect';

/**
 * @deprecated use FormikPortSelect instead
 */
export const DeprecatedFormikPortSelect = (props) => {
    const [field, , helpers] = useField(props.name);

    const onChange = (portId, option) => {
        helpers.setValue(portId);
        props.onPortSelect?.(option.port);
    };

    return <DeprecatedPortSelect {...props} onChange={onChange} portId={field.value} />;
};
