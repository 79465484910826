import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import ReactAsyncSelect from 'react-select/async';

import { callAll } from '../../helpers/callAll';
import { IconX } from '../../icons/cdl/X';
import { useFormData } from '../Form/useFormData';
import { Label } from '../Label/Label';

import { selectStyles } from './Select.styles';

const CustomCancelIcon = ({ innerProps }) => {
    return (
        <div {...innerProps}>
            <IconX style={{ cursor: 'pointer', marginRight: '6px' }} />
        </div>
    );
};

/**
 * @deprecated use CDL Select instead
 **/

export const Select = ({ label, required, onChange, value, isHighlight, ...props }) => {
    const [isDirty, setIsDirty] = useState(false);
    const [isValid, setIsValid] = useState(required ? !isEmpty(value) : true);

    useEffect(() => {
        setIsValid(required ? !isEmpty(value) : true);
    }, [required, value]);

    const validate = (option) => required && setIsValid(option.value !== '');

    return (
        <Label label={label} required={required}>
            <ReactSelect
                styles={selectStyles}
                className={[isHighlight ? 'highlight' : '', isDirty ? 'dirty' : '', isValid ? 'valid' : 'invalid'].join(
                    ' '
                )}
                onBlur={() => setIsDirty(true)}
                components={{
                    ClearIndicator: CustomCancelIcon,
                }}
                onChange={callAll(validate, onChange)}
                value={value}
                {...props}
            />
        </Label>
    );
};

export const SelectAsync = ({ label, value, loadOptions, ...props }) => {
    const [options, setOptions] = useState([]);
    const selectedValue = Array.isArray(value)
        ? options.filter((o) => value.includes(o.value))
        : options.find((o) => o.value === value);

    const loadOptionsWrapper = (input = value, callback) => {
        loadOptions(input || value, (result) => {
            setOptions(result);
            callback(result);
        });
    };

    return (
        <Label label={label}>
            <ReactAsyncSelect value={selectedValue} loadOptions={loadOptionsWrapper} styles={selectStyles} {...props} />
        </Label>
    );
};

export const FormSelect = ({ dataPath, options = [], ...props }) => {
    const { value, onChange } = useFormData(dataPath);
    const selectedValue = options.find((o) => o.value === value);
    const onChangeHandle = (option) => {
        if (!option) {
            return;
        }
        onChange({ target: { value: option.value } });
        props.onChange?.(option);
    };

    return <Select {...props} onChange={onChangeHandle} value={selectedValue} options={options} />;
};

export const FormSelectAsync = ({ dataPath, ...props }) => {
    const { value, onChange } = useFormData(dataPath);
    const onChangeHandle = (option) => {
        if (props.isMulti) {
            onChange({
                target: { value: option.map((o) => o.value) },
            });
        } else {
            onChange({ target: { value: option.value } });
        }
    };

    return <SelectAsync key={value} onChange={onChangeHandle} value={value} {...props} />;
};
