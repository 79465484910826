import { Text } from '../../cdl/Text/Text';
import { Box } from '../ui/Box';

export const DoubleLineOption = ({ headline, subline, innerProps, getStyles, className, ...props }) => {
    return (
        <Box {...innerProps} style={getStyles('option', props)} className={className}>
            <Text weight="semi">{headline}</Text>

            <Box>
                <Text color="dark-blue.2" size={12}>
                    {subline}
                </Text>
            </Box>
        </Box>
    );
};
