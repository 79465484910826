import { DemandInformationMessage } from '../../../../types/DemandInformationMessage';
import { Iso8601DateTimeString } from '../../../../types/Iso8601DateTimeString';
import { ProductContext } from '../../../../types/ProductContext';
import { SortingKey } from '../../../demand/components/SortingKey';
import { authorizedAxiosInstance } from '../instances/AuthorizedAxiosInstance';

export const getDemandInformation = async (
    sortingKey: SortingKey,
    productContext: ProductContext,
    useBunkerMetricRecommendations: boolean,
    groupId?: string
): Promise<DemandInformationMessage> => {
    const params = new URLSearchParams();
    params.append('sortingKey', sortingKey);
    params.append('context', productContext);

    if (useBunkerMetricRecommendations) {
        params.append('useBunkerMetricRecommendations', 'true');
    }

    if (groupId) {
        params.append('groupId', groupId);
    }

    const response = await authorizedAxiosInstance.get<DemandInformationMessage>(`/v2/demand-information?${params}`);

    return response.data;
};

interface ShareDemandRequestMessage {
    receiverEmailAddresses: string[];
    copyToSelf: boolean;
    fromDate: Iso8601DateTimeString;
    toDate: Iso8601DateTimeString;
}

interface ShareDemandInformationConfig {
    vesselId: string;
    requestMessage: ShareDemandRequestMessage;
}

export const shareDemandInformation = async ({
    vesselId,
    requestMessage,
}: ShareDemandInformationConfig): Promise<void> => {
    await authorizedAxiosInstance.post(`/v2/demand-information/${vesselId}/share`, requestMessage);
};
