import { Box } from '../../ui/Box';
import { useFormData } from '../Form/useFormData';
import { Label } from '../Label/Label';
import { StyledInput } from '../TextInput/Input';

/**
 * @deprecated use CDL Textarea instead
 **/

export const Textarea = ({ label, icon, annotation, required, ...input }) => {
    return (
        <Label label={label} annotation={annotation} required={required}>
            <Box position="relative">
                <StyledInput
                    as="textarea"
                    paddingRight={5}
                    paddingLeft={icon ? 7 : 5}
                    paddingY={4}
                    border={1}
                    borderRadius={1}
                    required={required}
                    style={{
                        resize: 'vertical',
                        height: '100px',
                    }}
                    {...input}
                />
            </Box>
        </Label>
    );
};

export const FormTextarea = ({ dataPath, transformValue, ...props }) => {
    const bindFormData = useFormData(dataPath, { transformValue });
    return <Textarea {...bindFormData} {...props} />;
};
