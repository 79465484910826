import { Fragment } from 'react';

import { Modal } from '../../cdl/Modal/Modal';
import { Text } from '../../cdl/Text/Text';
import { DataSheet } from '../../common/DataSheet/DataSheet';
import { Box } from '../../common/ui/Box';
import { useMakeContractPopupInformation } from '../../demand/prePlanning/components/BunkerSolutionDetails/components/BunkerSolutionStopTable/hooks/useMakeContractPopupInformation';

export const ContractPopup = ({ contractProduct, contractProductGroup, onDismiss, open }) => {
    const makeContractPopupInformation = useMakeContractPopupInformation();
    const product = contractProductGroup.products.find((product) => {
        return product.productId === contractProduct.productId;
    });

    const makeContractProductHeading = (contractProduct) => {
        const specificContractProductData = [
            contractProduct.contract.supplier.name,
            contractProduct.contract.reference,
            contractProduct.product.name,
        ];
        return specificContractProductData.join(' | ');
    };

    const contractData = makeContractPopupInformation({
        contract: contractProduct.contract,
        contractProduct: product,
        contractProductGroup: contractProductGroup,
    });

    return (
        <Modal onDismiss={onDismiss} isOpen={open} scrollable={false}>
            <Box padding={9}>
                <Text size={20} weight="semi">
                    {makeContractProductHeading(contractProduct)}
                </Text>
                <Box marginTop={7}>
                    <DataSheet>
                        {contractData.map((data, index) => (
                            <Fragment key={index}>
                                <DataSheet.Label>{data.label}</DataSheet.Label>
                                <span>{data.value}</span>
                            </Fragment>
                        ))}
                    </DataSheet>
                </Box>
            </Box>
        </Modal>
    );
};
