import { TextButton } from '../../../common/buttons/TextButton.tsx';
import { IconPencil } from '../../../common/icons/cdl/Pencil';
import { Box } from '../../../common/ui/Box.tsx';

export const EditAgentButton = ({ children, ...rest }) => {
    return (
        <Box paddingY={3}>
            <TextButton {...rest}>
                <IconPencil />
                {children}
            </TextButton>
        </Box>
    );
};
