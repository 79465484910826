import { BOLD_BLUEISH_100 } from '../../colors';
import { CL_DARK_BLUE_40 } from '../../colors';
import { IconCircle1 } from '../../icons/cdl/Circle1';
import { IconCircle2 } from '../../icons/cdl/Circle2';
import { IconCircleCheck } from '../../icons/cdl/CircleCheck';

export const StepMarker = ({ step, active }) => {
    const color = active ? BOLD_BLUEISH_100 : CL_DARK_BLUE_40;

    switch (step) {
        case 1:
            return <IconCircle1 color={color} />;
        case 2:
            return <IconCircle2 color={color} />;
        case 3:
            return <IconCircleCheck color={color} />;
        default:
            return null;
    }
};
