import { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import zxcvbn from 'zxcvbn';

import { Text } from '../../cdl/Text/Text.tsx';
import { translate } from '../../common/helpers/translate.helper';
import { IconInfoCircle } from '../../common/icons/cdl/InfoCircle';
import { Box } from '../../common/ui/Box.tsx';
import { Flex } from '../../common/ui/Flex.tsx';
import { get as themeGet } from '../../common/ui/get';
import { theme } from '../../common/ui/theme';

import { ProgressBar } from './ProgressBar';

const WarningBox = styled(Box)`
    text-align: left;
    user-select: none;
`;
const SuggestionList = styled(Box)``;
const SuggestionItem = styled(Box)``;

const colors = [
    theme.colors.negative.emphasis,
    theme.colors.negative.emphasis,
    theme.colors.attention.emphasis,
    theme.colors.positive.emphasis,
    theme.colors.positive.emphasis,
];

const Bounce = keyframes`
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1.0);
    }
`;

const ProgressWrap = styled(Flex)`
    color: ${themeGet('colors.clear-blue.5')};
    cursor: pointer;

    &.is-full {
        animation-name: ${Bounce};
        animation-duration: 150ms;
        animation-delay: 175ms;
        animation-timing-function: ease-in-out;
    }

    &.is-open,
    &:hover {
        color: ${themeGet('colors.clear-blue.0')};
    }
`;

export const StrengthMeter = ({ text = '' }) => {
    const [showFeedback, setShowFeedback] = useState(false);
    const { score, feedback } = zxcvbn(text);

    return (
        <div>
            <ProgressWrap
                alignItems="center"
                onClick={() => setShowFeedback(!showFeedback)}
                className={[showFeedback ? 'is-open' : null, score === 4 ? 'is-full' : null].join(' ')}
            >
                <ProgressBar progress={text === '' ? 0 : (score + 1) / 5} color={colors[score]} marginRight={4} />
                <IconInfoCircle />
            </ProgressWrap>

            {showFeedback ? (
                <WarningBox backgroundColor="clear-blue.9" borderRadius={1} border={1} marginTop={4} padding={6}>
                    <Text as="p">{translate('passwordStrength.feedbackText')}</Text>
                    {score === 4 ? (
                        <Text color="confirming-green.0" marginTop={4} weight="bold" as="p">
                            {translate('passwordStrength.strongPassword')}
                        </Text>
                    ) : null}

                    {feedback.warning ? (
                        <Text color="signal-red.0" weight="bold" marginTop={4}>
                            Warning: {feedback.warning}
                        </Text>
                    ) : null}

                    {feedback.suggestions?.length > 0 ? (
                        <SuggestionList as="ul" marginTop={4} paddingLeft={6} marginBottom={0}>
                            {feedback.suggestions.map((suggestion, key) => (
                                <SuggestionItem as="li" key={key}>
                                    <Text display="inline">{suggestion}</Text>
                                </SuggestionItem>
                            ))}
                        </SuggestionList>
                    ) : null}
                </WarningBox>
            ) : null}
        </div>
    );
};
