import { useField } from 'formik';
import { useCallback } from 'react';

import { ProductContext } from '../../../types/ProductContext';
import { Callout } from '../../cdl/Callout/Callout';
import { FormikTextInput } from '../../common/form-elements/formik/FormikTextInput';
import { translate } from '../../common/helpers/translate.helper';
import { Box } from '../../common/ui/Box';
import { validateOrderCreation, validateOrderUpdate } from '../../common/api/clients/order.api';

export const FormikBuyerReferenceInput = (props) => {
    const validate = useCallback(
        async (buyerReference) => {
            try {
                const validationRequest = {
                    type: props.type ?? ProductContext.FUEL,
                    vesselId: props.vesselId,
                    buyerReference: buyerReference ? buyerReference : null,
                };
                const response = props.orderId
                    ? await validateOrderUpdate(validationRequest, props.orderId)
                    : await validateOrderCreation(validationRequest);

                const buyerReferenceError = response.validationErrors.find((error) => error.path === 'buyerReference');

                if (!buyerReferenceError) {
                    return undefined;
                }

                return buyerReferenceError.message;
            } catch {
                return undefined;
            }
        },
        [props.orderId, props.type, props.vesselId]
    );

    const [field, meta] = useField({
        name: props.name,
        validate,
    });

    const disabled = !props.vesselId;

    const placeholder = disabled
        ? translate('fuelEnquiry.first.buyerReferenceDisabledPlaceholder')
        : translate('fuelEnquiry.first.buyerReferencePlaceholder');

    return (
        <Box>
            <FormikTextInput
                label={translate('fuelEnquiry.first.buyerReferenceLabel')}
                placeholder={placeholder}
                disabled={disabled}
                {...field}
                {...props}
            />
            {meta.error ? (
                <Box marginTop="1px">
                    <Callout variant="negative" description={meta.error} />
                </Box>
            ) : null}
        </Box>
    );
};
