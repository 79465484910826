import { useState } from 'react';

import { AssignmentMessage } from '../../../../types/AssignmentMessage';
import { Button } from '../../../cdl/Button/Button';
import { useToasts } from '../../../cdl/Toast/useToasts';
import { translate } from '../../../common/helpers/translate.helper';
import { useRole } from '../../../common/hooks/useRole';
import { IconPlus } from '../../../common/icons/cdl/Plus';
import { useAssignmentList } from '../../common/hooks/useAssignmentList';
import { OrderModel } from '../../model/OrderModel';

import { RequestSuppliersModal } from './RequestSuppliersModal';

interface RequestSuppliersButtonProps {
    order: OrderModel;
}

export const RequestSuppliersButton = (props: RequestSuppliersButtonProps) => {
    const [showModal, setShowModal] = useState(false);
    const role = useRole();
    const { addErrorToast } = useToasts();

    const assignmentQuery = useAssignmentList(props.order.customerId, props.order.portId, props.order.testOrder);

    if (!assignmentQuery.data) {
        return null;
    }

    const allSuppliersRequested = assignmentQuery.data.every((assignment: AssignmentMessage) => {
        return props.order.receiverSupplierIds?.includes(assignment.supplierId);
    });
    const moreSuppliersToInvite = !allSuppliersRequested || !props.order.spot;

    const buttonHidden =
        !moreSuppliersToInvite ||
        role.isAdmin() ||
        !role.hasWriteRights(props.order.customerId) ||
        props.order.isFinished();

    if (buttonHidden) {
        return null;
    }

    return (
        <>
            <Button onClick={() => setShowModal(true)} leadingVisual={<IconPlus height={16} width={16} />}>
                {translate('order.detail.requestSuppliers.button')}
            </Button>
            {showModal ? (
                <RequestSuppliersModal
                    assignments={assignmentQuery.data}
                    order={props.order}
                    onClose={(error) => {
                        if (error) {
                            addErrorToast(translate('order.detail.requestSuppliers.errorToast'));
                        }
                        setShowModal(false);
                    }}
                />
            ) : null}
        </>
    );
};
