import { useState } from 'react';

import { Button } from '../../../cdl/Button/Button';
import { Checkbox } from '../../../cdl/Checkbox/Checkbox';
import { Modal } from '../../../cdl/Modal/Modal';
import { Text } from '../../../cdl/Text/Text';
import { ButtonGroup } from '../../../common/buttons/ButtonGroup';
import { translate } from '../../../common/helpers/translate.helper';
import { Box } from '../../../common/ui/Box';

export const CreateNewOrderFromContractPopup = ({ onDismiss, isOpen, isSubmitting, onCreateNewEnquiry, order }) => {
    const [cancelExistingOrder, setCancelExistingOrder] = useState(false);

    const handleCreateEnquiry = () => {
        onCreateNewEnquiry({ cancelExistingOrder });
    };

    return (
        <Modal onDismiss={onDismiss} isOpen={isOpen} scrollable={false}>
            <Box padding={6} display="grid" rowGap={5}>
                <Text variant="title">{translate('fuelOrder.offerList.createEnquiryFromContract.headline')}</Text>

                <Text color="foreground.subtle" as="p">
                    {translate('fuelOrder.offerList.createEnquiryFromContract.popupDescription')}
                </Text>

                {!order.isFinished() ? (
                    <Text color="foreground.subtle" as="p">
                        {translate('fuelOrder.offerList.createEnquiryFromContract.cancelExistingOrderDescription')}
                    </Text>
                ) : null}

                {!order.isFinished() ? (
                    <Checkbox
                        checked={cancelExistingOrder}
                        onChange={() => setCancelExistingOrder((current) => !current)}
                        label={translate('fuelOrder.offerList.createEnquiryFromContract.cancelExistingOrderLabel')}
                    />
                ) : null}

                <ButtonGroup justifyContent="end">
                    <Button emphasis="high" isLoading={isSubmitting} onClick={handleCreateEnquiry}>
                        {translate('fuelOrder.offerList.createEnquiryFromContract.confirmCreateEnquiry')}
                    </Button>
                </ButtonGroup>
            </Box>
        </Modal>
    );
};
