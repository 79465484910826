import { Text } from '../../../cdl/Text/Text.tsx';
import { translate } from '../../../common/helpers/translate.helper';
import { Box } from '../../../common/ui/Box.tsx';

import { AgentTitle } from './AgentTitle';

export const AgentSelectItem = ({ agent }) => {
    return (
        <Box>
            <AgentTitle name={agent.name} source={agent.source} />
            {agent.contact.emailAddress || agent.contact.phoneNumber ? (
                <Box>
                    <Text color="dark-blue.2" size={12}>
                        {[
                            agent.contact.emailAddress
                                ? `${translate('global.contact.email')}: ${agent.contact.emailAddress}`
                                : null,
                            agent.contact.phoneNumber
                                ? `${translate('global.contact.phone')}: ${agent.contact.phoneNumber}`
                                : null,
                        ]
                            .filter((value) => value)
                            .join(', ')}
                    </Text>
                </Box>
            ) : null}
        </Box>
    );
};
