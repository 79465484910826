import { FormikLegacyEnumOptionSelect } from '../../../../common/form-elements/formik/FormikLegacyEnumOptionSelect';
import { translate } from '../../../../common/helpers/translate.helper';

export const FormikFuelSupplyTypeSelect = (props) => {
    return (
        <FormikLegacyEnumOptionSelect
            enumType="supplyMode"
            name={props.name}
            required
            label={translate('offer.quotationProcess.fuel.supplyMode')}
            context="FUEL"
        />
    );
};
