import { useFormikContext } from 'formik';

import { Error } from '../../common/Error/Error';
import { translate } from '../../common/helpers/translate.helper';
import { IconFileInvoice } from '../../common/icons/cdl/FileInvoice';
import { LoadingIndicator } from '../../common/LoadingIndicator/LoadingIndicator';
import { TableEmptyState } from '../../common/TableEmptyState/TableEmptyState';
import { Box } from '../../common/ui/Box';
import { usePlattsCredentials } from '../../company/customer/detail/integrations/hooks/usePlattsCredentials';

import { ContractProductBox } from './ContractProductBox';
import { ContractProductsWrapper } from './ContractProductsWrapper';
import { useContractProducts } from './hooks/useContractProducts';

export const ContractProducts = ({ isSelected, onContractButtonClick, customerId }) => {
    const { values } = useFormikContext();

    const { data, isPending, isError } = useContractProducts({
        portId: values.portId,
        eta: values.eta,
        etd: values.etd,
    });
    const plattsCredentialsQuery = usePlattsCredentials(customerId);

    if (isPending) {
        return <LoadingIndicator />;
    }

    if (isError) {
        return <Error />;
    }

    const contractProducts = data.contracts;

    if (!contractProducts.length) {
        return (
            <TableEmptyState
                text={translate('fuelEnquiry.second.contractProducts.emptyState')}
                Icon={IconFileInvoice}
            />
        );
    }

    return (
        <ContractProductsWrapper>
            {contractProducts.map((contractProduct, index) => {
                return (
                    <Box key={index}>
                        <ContractProductBox
                            selected={isSelected(contractProduct)}
                            onClick={() => onContractButtonClick(contractProduct)}
                            contractProduct={contractProduct}
                            hasPlattsCredentials={!!plattsCredentialsQuery.data}
                            customerId={customerId}
                        />
                    </Box>
                );
            })}
        </ContractProductsWrapper>
    );
};
