import { ProductContext } from '../../../../../types/ProductContext';
import { LubesUpdateOrderRequest } from '../../../../common/api/clients/order.api';
import { OrderState } from '../../../model/OrderModel';
import { FormikCreateEnquiryValues } from '../LubesCreateEnquiryFlow';

export const buildOrderRequest = (values: FormikCreateEnquiryValues, state: OrderState): LubesUpdateOrderRequest => {
    return {
        state: state,
        vesselId: values.vesselId,
        agentId: values.agentId || null,
        buyerReference: values.buyerReference ? values.buyerReference : null,
        comment: values.comment || null,
        dateDelivery: values.dateDelivery ? values.dateDelivery : null,
        items: values.items
            .filter((item) => item.productId || item.units)
            .map((item) => ({
                productId: item.productId || null,
                packType: item.packType,
                unitSize: item.unitSize,
                units: item.units ? Number(item.units) : null,
                unit: item.unit,
                volume: item.volume || null,
                deleted: item.deleted,
            })),
        portId: values.portId || null,
        receiverSupplierIds: values.receiverSupplierIds?.length ? values.receiverSupplierIds : null,
        samplekits: values.samplekits?.length
            ? values.samplekits.filter((sampleKit) => sampleKit.name || sampleKit.quantity)
            : null,
        spot: values.spot,
        chosenOfferId: null,
        cancelReason: null,
        type: ProductContext.LUBES,
    };
};
