import { InitialEnquiryModal } from '../common/components/InitialEnquiryModal';
import { useInitialEnquiry } from '../common/hooks/useInitialEnquiry';
import { LubesReadOnlyProductTable } from '../../../order/detail/lubes/components/LubesReadOnlyTable/LubesReadOnlyProductTable';
import Skeleton from 'react-loading-skeleton';
import { Box } from '../../../common/ui/Box';

export const LubesInitialEnquiryPopup = ({ isOpen, onDismiss, offerId }) => {
    const initialEnquiryQuery = useInitialEnquiry(offerId);
    const offer = initialEnquiryQuery.data;

    return (
        <InitialEnquiryModal
            isLoading={initialEnquiryQuery.isPending}
            offer={offer}
            isOpen={isOpen}
            onDismiss={onDismiss}
        >
            {initialEnquiryQuery.isPending ? (
                <Box display="grid" gap={5}>
                    <Skeleton height={32} />
                    <Skeleton height={32} />
                    <Skeleton height={32} />
                </Box>
            ) : null}
            {initialEnquiryQuery.isError ? <Error /> : null}
            {offer ? (
                <LubesReadOnlyProductTable
                    items={offer.items}
                    surcharges={offer.surcharges}
                    sampleKits={offer.samplekits}
                    totals={{ volume: offer.volume }}
                    showPrices={false}
                />
            ) : null}
        </InitialEnquiryModal>
    );
};
