import { DefaultCell } from '../../../../../../cdl/TableBuilder/components/DefaultCell';
import { StyledDefaultTableBodyRow } from '../../../../../../cdl/TableBuilder/components/StyledDefaultTableBodyRow';
import { formatLubesPrice } from '../../../../../../common/helpers/formatLubesPrice.helper';
import { formatLubesTotalPrice } from '../../../../../../common/helpers/formatLubesTotal.helper';
import { formatNumber } from '../../../../../../common/helpers/formatNumber.helper';
import { useEnums } from '../../../../../../common/hooks/useEnums';
import { ItemModel } from '../../../../../../common/models/ItemModel';

import { getCellEmphasis } from './getCellEmphasis';
import { ProductDisplay } from './ProductDisplay';

interface ItemRowProps {
    item: ItemModel;
    tableRowIndex: number;
    showPrices: boolean;
}

export const ItemRow = ({ item, tableRowIndex, showPrices }: ItemRowProps) => {
    const { getHumanReadableValue } = useEnums();

    return (
        <StyledDefaultTableBodyRow
            grow={true}
            alternateRowColors={true}
            active={false}
            selectable={false}
            disabled={item.deleted}
        >
            <DefaultCell numeric>{tableRowIndex + 1}</DefaultCell>
            <DefaultCell width="35%" truncate={false}>
                <ProductDisplay item={item} />
            </DefaultCell>
            <DefaultCell emphasis={getCellEmphasis(item.changes.packTypeChanged)}>
                {getHumanReadableValue('packType', item.packType)}
            </DefaultCell>
            <DefaultCell emphasis={getCellEmphasis(item.changes.unitSizeChanged)} numeric>
                {formatNumber({ number: item.unitSize, minimumFractionDigits: 0, maximumFractionDigits: 2 })}
            </DefaultCell>
            <DefaultCell emphasis={getCellEmphasis(item.changes.unitsChanged)} numeric>
                {item.units ? formatNumber({ number: item.units, maximumFractionDigits: 0 }) : '-'}
            </DefaultCell>
            <DefaultCell numeric>
                {item.volume ? formatNumber({ number: item.volume, maximumFractionDigits: 3 }) : '-'}
            </DefaultCell>
            <DefaultCell emphasis={getCellEmphasis(item.changes.unitChanged)}>
                {getHumanReadableValue('productUnit', item.unit)}
            </DefaultCell>
            {showPrices ? (
                <DefaultCell emphasis={getCellEmphasis(item.changes.priceChanged)} numeric>
                    {item.isCompleteWithPrices() ? formatLubesPrice(item.price!) : '-'}
                </DefaultCell>
            ) : null}
            {showPrices ? (
                <DefaultCell numeric>
                    {item.isCompleteWithPrices() ? formatLubesTotalPrice(item.total) : '-'}
                </DefaultCell>
            ) : null}
        </StyledDefaultTableBodyRow>
    );
};
