import { formatMoney } from '../../common/helpers/formatMoney.helper';
import { translate } from '../../common/helpers/translate.helper';
import { useEnums } from '../../common/hooks/useEnums';
import { Td } from '../../common/Table/Td';
import { Tr } from '../../common/Table/Tr';

const Surcharge = ({ surcharge, position, showPrices }) => {
    const { getHumanReadableValue } = useEnums();
    const label =
        surcharge.value.value > 0 ? translate('order.surcharges.surcharge') : translate('order.surcharges.waiver');

    const displayName =
        surcharge.surchargeType === 'CUSTOM'
            ? surcharge.name
            : getHumanReadableValue('surchargeType', surcharge.surchargeType);

    return (
        <Tr inactive={surcharge.deleted} focused={surcharge.added && !surcharge.deleted}>
            <Td>{position}</Td>
            <Td colSpan={7} danger={surcharge.changes && surcharge.changes.nameChanged && !surcharge.deleted}>
                <div>
                    <span>{displayName}</span>
                    <br />
                    <small>{label}</small>
                </div>
            </Td>
            {showPrices ? (
                <Td danger={surcharge.changes && surcharge.changes.valueChanged && !surcharge.deleted}>
                    {formatMoney({
                        value: surcharge.value.value,
                        currency: surcharge.value.currency,
                        minimumFractionDigits: 2,
                    })}
                </Td>
            ) : null}
        </Tr>
    );
};

export const Surcharges = ({ surcharges, offset, showPrices }) => {
    return surcharges.map((surcharge, index) => (
        <Surcharge key={index} surcharge={surcharge} position={offset + index + 1} showPrices={showPrices} />
    ));
};
