import { useState } from 'react';

import { Button } from '../../cdl/Button/Button';
import { Modal } from '../../cdl/Modal/Modal';
import { Text } from '../../cdl/Text/Text';
import { TextArea } from '../../cdl/TextArea/TextArea';
import { translate } from '../helpers/translate.helper';
import { useRole } from '../hooks/useRole';
import { Box } from '../ui/Box';

const selectWordingPair = (userType, offerState) => {
    switch (userType) {
        case 'CUSTOMER': {
            return {
                headline: translate('offer.detail.header.actions.cancelPopup.customer.headline'),
                description: translate('offer.detail.header.actions.cancelPopup.customer.description'),
                buttonText: translate('offer.detail.header.actions.cancelPopup.customer.button'),
            };
        }
        case 'SUPPLIER': {
            switch (offerState) {
                case 'ENQUIRY': {
                    return {
                        headline: translate('offer.detail.header.actions.cancelPopup.enquiry.headline'),
                        description: translate('offer.detail.header.actions.cancelPopup.enquiry.description'),
                        buttonText: translate('offer.detail.header.actions.cancelPopup.enquiry.button'),
                    };
                }
                case 'QUOTED': {
                    return {
                        headline: translate('offer.detail.header.actions.cancelPopup.quoted.headline'),
                        description: translate('offer.detail.header.actions.cancelPopup.quoted.description'),
                        buttonText: translate('offer.detail.header.actions.cancelPopup.quoted.button'),
                    };
                }
                default: {
                    return {
                        headline: translate('offer.detail.header.actions.cancelPopup.order.headline'),
                        description: translate('offer.detail.header.actions.cancelPopup.order.description'),
                        buttonText: translate('offer.detail.header.actions.cancelPopup.order.button'),
                    };
                }
            }
        }
    }
};

export const OfferCancelPopup = ({ isOpen, onSubmit, onDismiss, isSubmitting, offerState }) => {
    const role = useRole();

    const [cancelReason, setCancelReason] = useState('');

    const _onSubmit = (event) => {
        event.preventDefault();

        onSubmit({
            cancelReason,
        });
    };

    const { headline, description, placeholder, buttonText } = selectWordingPair(role.user.userType, offerState);

    return (
        <Modal isOpen={isOpen} size="medium" scrollable={false} onDismiss={onDismiss} dismissible={false}>
            <Box display="grid" gap={5} padding={6}>
                <Text variant="title">{headline}</Text>

                <Text variant="small" color="foreground.muted">
                    {description}
                </Text>

                <form onSubmit={_onSubmit}>
                    <Box display="flex" flexDirection="column" gap={6} marginTop={6}>
                        <TextArea
                            label={translate('offer.detail.header.actions.cancelPopup.reasonLabel')}
                            placeholder={placeholder}
                            onChange={(e) => setCancelReason(e.target.value)}
                            value={cancelReason}
                        />

                        <Box display="flex" justifyContent="end" gap={4}>
                            <Button emphasis="medium" type="button" onClick={onDismiss}>
                                {translate('btn.goBack')}
                            </Button>
                            <Button
                                emphasis="high"
                                type="submit"
                                destructive
                                disabled={!cancelReason}
                                isLoading={isSubmitting}
                            >
                                {buttonText}
                            </Button>
                        </Box>
                    </Box>
                </form>
            </Box>
        </Modal>
    );
};
