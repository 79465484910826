import { Text } from '../../../cdl/Text/Text.tsx';
import { AgentImportedLabel } from '../../../common/AgentImportedLabel/AgentImportedLabel';
import { Box } from '../../../common/ui/Box';
import { Flex } from '../../../common/ui/Flex.tsx';

export const AgentTitle = ({ name, source }) => {
    return (
        <Flex alignItems="center">
            <Text size={14} weight="semi">
                {name}
            </Text>
            {source ? (
                <Box marginLeft={4}>
                    <AgentImportedLabel />
                </Box>
            ) : null}
        </Flex>
    );
};
