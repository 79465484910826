import css from '@styled-system/css';
import styled from 'styled-components';

import { Text } from '../../cdl/Text/Text';
import { DiamondLogo } from '../icons/DiamondLogo/DiamondLogo';
import { Box } from '../ui/Box';
import { Flex } from '../ui/Flex';

import { Header } from './Header';

const StyledHeader = styled(Header)(
    css({
        paddingY: '20px',
        borderBottom: 1,
        boxShadow: 'medium',
    })
);

const HeaderItem = styled(Flex)(
    css({
        height: '100%',
        paddingX: 7,
        borderRight: 1,
        alignItems: 'center',
        ':last-of-type': {
            borderRight: 'none',
        },
    })
);

/**
 * @deprecated use OverlayHeader instead
 **/

export const LogoHeader = ({ title, subTitle, centerElement, rightElement }) => {
    return (
        <StyledHeader
            leftElement={
                <Flex position="relative" alignItems="center">
                    <HeaderItem>
                        <Box paddingY={4}>
                            <DiamondLogo />
                        </Box>
                    </HeaderItem>

                    {title ? (
                        <HeaderItem>
                            <Text size={24} weight="semi">
                                {title}
                            </Text>
                            {subTitle ? (
                                <Text size={24} weight="semi" color="dark-blue.3" marginLeft={3}>
                                    {subTitle}
                                </Text>
                            ) : null}
                        </HeaderItem>
                    ) : null}
                </Flex>
            }
            centerElement={centerElement}
            rightElement={rightElement ? <HeaderItem>{rightElement}</HeaderItem> : null}
        />
    );
};
