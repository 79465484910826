import Skeleton from 'react-loading-skeleton';

import { Button } from '../../../cdl/Button/Button';
import { translate } from '../../../common/helpers/translate.helper';
import { useRole } from '../../../common/hooks/useRole';
import { IconPlus } from '../../../common/icons/cdl/Plus';
import { Box } from '../../../common/ui/Box';
import { FormPortSelectWithSchedules } from '../../../port/DeprecatedPortSelectWithSchedules';
import { ComparePriceWrapper } from '../overlay/ComparePriceWrapper';
import { PortPriceRow } from '../PortPriceRow';
import { RemoveLineIcon } from '../RemoveLineIcon';

import { AddKeyPort } from './AddKeyPort';
import { PriceBox } from './PriceBox';

const EnquiryButton = ({ isFetching, supplierData, onButtonClick }) => {
    if (isFetching) {
        return (
            <Box width="155px">
                <Skeleton height="40px" />
            </Box>
        );
    }

    if (supplierData) {
        return (
            <Button
                leadingVisual={<IconPlus width={16} height={16} />}
                emphasis="high"
                onClick={onButtonClick}
                isLoading={isFetching}
            >
                {translate('compare.createEnquiry')}
            </Button>
        );
    }

    return <Box width="155px" />;
};

export const PortPriceInformation = ({
    index,
    onEnquiryButtonClick,
    onRemovePortButtonClick,
    isFetching,
    supplierData,
    portId,
    showRemoveIcon,
    customerId,
}) => {
    const role = useRole();

    const _onEnquiryButtonClick = (portId) => (event) => {
        event.preventDefault();

        onEnquiryButtonClick(portId);
    };

    const _onRemovePortButtonClick = (index) => (event) => {
        event.preventDefault();

        onRemovePortButtonClick(index);
    };

    return (
        <PortPriceRow>
            {showRemoveIcon ? <RemoveLineIcon onClick={_onRemovePortButtonClick(index)} /> : null}
            <Box position="relative" width="180px" style={{ flexShrink: 0 }}>
                <FormPortSelectWithSchedules dataPath={`portInformation[${index}].portId`} label={null} />

                {portId && role.hasWriteRights(customerId) && role.isCustomer() ? (
                    <Box position="absolute" marginTop={3}>
                        <AddKeyPort portId={portId} />
                    </Box>
                ) : null}
            </Box>

            <ComparePriceWrapper isFetching={isFetching} supplierData={supplierData}>
                {supplierData
                    ? Object.keys(supplierData).map((supplierId) => {
                          const priceInfo = supplierData[supplierId];

                          return <PriceBox price={priceInfo} key={supplierId} />;
                      })
                    : []}
            </ComparePriceWrapper>

            {role.isCustomer() && !role.usesCloselinkLite() && role.hasWriteRights(customerId) ? (
                <Box style={{ flexShrink: 0 }}>
                    <EnquiryButton
                        isFetching={isFetching}
                        supplierData={supplierData}
                        onButtonClick={_onEnquiryButtonClick(portId)}
                    />
                </Box>
            ) : null}
        </PortPriceRow>
    );
};
