import { Button } from '../cdl/Button/Button';
import { useDrawer } from '../cdl/Drawer/useDrawer.hook';
import { translate } from '../common/helpers/translate.helper';
import { Section } from '../common/Section/Section';

import { GeneralInformationDatasheet } from './GeneralInformationDatasheet';
import { GeneralInformationForm } from './GeneralInformationForm';

export const GeneralInformation = ({ companyDetails, onEditSubmit }) => {
    const { push, pop } = useDrawer();

    const onSubmit = ({ event, data }) => {
        event.preventDefault();
        onEditSubmit(data);
        pop();
    };

    const openGeneralInformationSettingsModal = () => {
        push({
            content: <GeneralInformationForm data={companyDetails} onSubmit={onSubmit} />,
        });
    };

    return (
        <Section
            title={translate('company.generalInformation.title')}
            actions={
                <Button onClick={openGeneralInformationSettingsModal}>
                    {translate('company.generalInformation.edit')}
                </Button>
            }
            fullHeight={false}
        >
            <GeneralInformationDatasheet companyData={companyDetails} />
        </Section>
    );
};
