import styled from 'styled-components';

import { BunkerSchedule, BunkerScheduleCosts } from '../../../../../../types/BunkerCaseSolutionResponse';
import { Tag } from '../../../../../cdl/Tag/Tag';
import { Text } from '../../../../../cdl/Text/Text';
import { Tooltip } from '../../../../../cdl/Tooltip/Tooltip';
import { formatDateTime } from '../../../../../common/helpers/formatDateTime.helper';
import { formatFuelMoney } from '../../../../../common/helpers/formatFuelMoney.helper';
import { translate } from '../../../../../common/helpers/translate.helper';
import { IconArrowRight } from '../../../../../common/icons/cdl/ArrowRight';
import { IconChevronDown } from '../../../../../common/icons/cdl/ChevronDown';
import { IconChevronRight } from '../../../../../common/icons/cdl/ChevronRight';
import { IconInfoCircle } from '../../../../../common/icons/cdl/InfoCircle';
import { Box } from '../../../../../common/ui/Box';
import { get } from '../../../../../common/ui/get';
import { getBunkerModeText } from '../util/getBunkerModeText';

import { BunkerStopWarningsSummary } from './BunkerStopWarningsSummary';
import { PurchaseSummary } from './PurchaseSummary';

const StyledHeader = styled.div<{ isOpen: boolean }>`
    display: grid;
    grid-template-columns: 32px 2fr 2fr 280px 5fr 80px 80px 2fr;
    gap: ${get('space.4')}px;
    align-items: center;
    cursor: pointer;
    height: 48px;
    padding-left: 7px;
    padding-right: 15px;
    background-color: ${(props) => (props.isOpen ? get('colors.background.subtle') : get('colors.background.default'))};
    border: 1px solid ${(props) => (props.isOpen ? get('colors.border.muted') : get('colors.border.subtle'))};
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-left-radius: ${(props) => (props.isOpen ? '0px' : '6px')};
    border-bottom-right-radius: ${(props) => (props.isOpen ? '0px' : '6px')};

    transition:
        background-color 100ms ease-out,
        border 100ms ease-out;

    &:hover {
        border-color: ${get('colors.border.muted')};
    }
`;

function hasAdditionalCosts(bunkerSchedule: BunkerSchedule): boolean {
    return Object.keys(bunkerSchedule.bunkerScheduleCosts).some((key) => {
        const costs = bunkerSchedule.bunkerScheduleCosts[key as keyof BunkerScheduleCosts];

        return costs ? costs.value > 0 : false;
    });
}

interface BunkerSolutionStopAccordionHeaderProps {
    bunkerSchedule: BunkerSchedule;
    purchaseCategories: Map<string, { productCategory: string; sulphurContent: string }>;
    isOpen: boolean;
    includesPurchasesAtStop: boolean;
    onClick: () => void;
}

export const BunkerSolutionStopAccordionHeader = ({
    bunkerSchedule,
    purchaseCategories,
    includesPurchasesAtStop,
    isOpen,
    onClick,
}: BunkerSolutionStopAccordionHeaderProps) => {
    const hasSurcharges = hasAdditionalCosts(bunkerSchedule);

    return (
        <StyledHeader isOpen={isOpen} onClick={onClick}>
            <Box display="flex" justifyContent="center" color="foreground.subtle">
                {isOpen ? <IconChevronDown width={16} height={16} /> : <IconChevronRight width={16} height={16} />}
            </Box>

            <Box display="flex" gap={2} alignItems="center" overflow="hidden">
                <Text
                    variant="small"
                    fontWeight={includesPurchasesAtStop ? 'semiBold' : 'normal'}
                    style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                >
                    {bunkerSchedule.port.name}
                </Text>

                {bunkerSchedule.inducedCall ? (
                    <Tooltip label={translate('prePlanning.solutionDetails.induced')}>
                        <Text color="foreground.default">
                            <IconInfoCircle width={16} height={16} />
                        </Text>
                    </Tooltip>
                ) : null}
            </Box>

            <Box overflow="hidden">
                <Text
                    variant="fieldLabel"
                    style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                >
                    {getBunkerModeText(bunkerSchedule.mode)}
                </Text>
            </Box>
            <Box display="grid" gridTemplateColumns="1fr 16px 1fr" gap={4} alignItems="center">
                <Text as="p" variant="small" color="foreground.muted">
                    {formatDateTime({ date: bunkerSchedule.eta, format: 'MMM D, h:mm A' })}
                </Text>
                <Box color="foreground.subtle">
                    <IconArrowRight width={16} height={16} />
                </Box>
                <Text as="p" variant="small" color="foreground.muted">
                    {formatDateTime({ date: bunkerSchedule.etd, format: 'MMM D, h:mm A' })}
                </Text>
            </Box>
            <div>
                {bunkerSchedule.purchases.length > 0 ? (
                    <PurchaseSummary purchases={bunkerSchedule.purchases} purchaseCategories={purchaseCategories} />
                ) : null}
            </div>

            <BunkerStopWarningsSummary categoryConsumptions={bunkerSchedule.categoryConsumption} />

            <div>
                {hasSurcharges ? (
                    <Tag variant="default">{translate('prePlanning.solutionDetails.surcharge')}</Tag>
                ) : null}
            </div>

            <div>
                {bunkerSchedule.totalCosts?.value > 0 ? (
                    <Text as="p" variant="small" fontWeight="semiBold" textAlign="right">
                        {formatFuelMoney(bunkerSchedule.totalCosts)}
                    </Text>
                ) : null}
            </div>
        </StyledHeader>
    );
};
