import { useOfferMarkRead } from './useOfferMarkRead';
import { OverviewReadButton } from '../../../common/buttons/OverviewReadButton/OverviewReadButton';
import { Box } from '../../../common/ui/Box';

export const OfferActions = ({ offer }) => {
    const { mutate: markRead } = useOfferMarkRead();

    const onMarkRead = (offer) => {
        markRead({ offer, offerId: offer.id, read: !offer.supplierRead });
    };

    return (
        <Box display="flex" justifyContent="flex-end" width="100%">
            <OverviewReadButton onClick={() => onMarkRead(offer)} isRead={offer.supplierRead} />
        </Box>
    );
};
