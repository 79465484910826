import { useProductContext } from '../../common/hooks/useProductContext';
import { TabSwitch } from '../../common/TabSwitch/TabSwitch';
import { Route as RouteLubes } from '../../routes/_app._lubes.statistics';
import { Route as RouteFuel } from '../../routes/_app.fuel.statistics';

import { StatisticTab } from './StatisticTab';

export const SupplierStatisticsTabSwitch = () => {
    const { context } = useProductContext();
    const Route = context === 'FUEL' ? RouteFuel : RouteLubes;
    const search = Route.useSearch();
    const navigate = Route.useNavigate();

    return (
        <TabSwitch
            selectedContext={search.tab}
            onSelect={(tab: StatisticTab) => {
                navigate({
                    search: (previousSearch) => ({
                        ...previousSearch,
                        tab,
                    }),
                });
            }}
            totals={{
                [StatisticTab.GENERAL]: null,
                [StatisticTab.CUSTOMERS]: null,
                [StatisticTab.PORTS]: null,
                [StatisticTab.PRODUCTS]: null,
                [StatisticTab.PRODUCT_GROUPS]: null,
            }}
        />
    );
};
