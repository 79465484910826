import React from 'react';
import styled from 'styled-components';

import { DemandInformationCategory } from '../../../types/DemandInformationMessage';
import { Text } from '../../cdl/Text/Text';
import { Tooltip } from '../../cdl/Tooltip/Tooltip';
import { translate } from '../../common/helpers/translate.helper';
import { useEnums } from '../../common/hooks/useEnums';
import { Box } from '../../common/ui/Box';

interface DemandInformationCategoryWithDaysLeft extends DemandInformationCategory {
    daysLeft: number;
}

interface DaysLeftSailingProps {
    categories: DemandInformationCategory[];
}

const BoxWithCenteredText = styled(Box)`
    text-align: center;
`;

const DaysLeftTooltipLabel = ({ category }: { category: string }) => {
    const { getHumanReadableValue } = useEnums();
    return (
        <BoxWithCenteredText display="flex" flexDirection="column" gap={1}>
            <Text>{translate('demand.daysLeftTooltip')}</Text>
            <Text weight="bold">{getHumanReadableValue('vesselTankCategory', category)}</Text>
        </BoxWithCenteredText>
    );
};

export const DemandVesselDaysLeftSailing = ({ categories }: DaysLeftSailingProps) => {
    const validCategories: { name: string; daysLeft: number }[] = categories
        .filter((category): category is DemandInformationCategoryWithDaysLeft => category.daysLeft !== undefined)
        .map((category) => ({
            name: category.categoryName,
            daysLeft: category.daysLeft!,
        }));

    if (!validCategories.length) {
        return <div />;
    }

    const categoryMinDaysLeft = validCategories.reduce((minCategory, currentCategory) => {
        return currentCategory.daysLeft < minCategory.daysLeft ? currentCategory : minCategory;
    }, validCategories[0]);

    const leastDaysLeft = categoryMinDaysLeft.daysLeft!;
    const daysLeftString =
        leastDaysLeft === 1
            ? translate('demand.dayLeft')
            : translate('demand.daysLeft', {
                  days: leastDaysLeft.toString(),
              });

    return (
        <Tooltip label={<DaysLeftTooltipLabel category={categoryMinDaysLeft.name} />} showArrow={true}>
            <span>
                <Text variant="fieldLabel">{daysLeftString}</Text>
            </span>
        </Tooltip>
    );
};
