import { useField } from 'formik';

import { FormikFuelProductSelect } from '../../form-elements/formik/FormikFuelProductSelect';

import { useProductTableConfig } from './useProductTableConfig';

export const ProductNameDisplay = (props) => {
    const [field] = useField(props.name);
    const item = field.value;
    const { fetchProducts } = useProductTableConfig();

    if (item.deleted || props.isContract || !props.editable) {
        return item.product.name;
    }

    return (
        <FormikFuelProductSelect
            name={`${props.name}.productId`}
            onSelect={props.onSelect}
            fetchProducts={fetchProducts}
        />
    );
};
