import { VesselMessage } from '../../../../types/VesselMessage';
import { formatAddress } from '../../../common/helpers/formatAddress.helper';
import { formatCompanyName } from '../../../common/helpers/formatCompanyName.helper';
import { formatDate } from '../../../common/helpers/formatDate.helper';
import { formatVessel } from '../../../common/helpers/formatVessel.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { useRole } from '../../../common/hooks/useRole';
import { Box } from '../../../common/ui/Box';
import { YesOrNo } from '../../../common/YesOrNo/YesOrNo';
import { VesselActiveFlag } from '../../VesselOverview/ActiveFlag/VesselActiveFlag';

import { VesselDetail } from './VesselDetail';

interface FuelVesselDetailDataSheetProps {
    vessel: VesselMessage;
}

export const FuelVesselDetails = ({ vessel }: FuelVesselDetailDataSheetProps) => {
    const role = useRole();

    return (
        <Box display="grid" gap={5}>
            {!role.isOneCompanyUser() ? (
                <VesselDetail
                    label={translate('order.company')}
                    value={formatCompanyName({
                        company: vessel.customer,
                        includeType: role.isAdmin(),
                    })}
                />
            ) : null}

            <VesselDetail label={translate('order.vessel')} value={formatVessel({ vessel })} />
            <VesselDetail label={translate('vessel.types')} value={vessel.types?.join(', ')} />
            <VesselDetail label={translate('vessel.services')} value={vessel.services?.join(', ')} />
            <VesselDetail label={translate('vessel.lastedit')} value={formatDate({ date: vessel.dateUpdated })} />
            <VesselDetail label={translate('company.invoiceVAT')} value={vessel.invoiceVAT} />
            <VesselDetail
                label={translate('global.status')}
                value={<VesselActiveFlag active={vessel.active} dateActiveUpdated={vessel.dateActiveUpdated} />}
            />
            <VesselDetail label={translate('global.contact.email')} value={vessel.email} />
            <VesselDetail
                label={translate('global.address.invoiceaddress')}
                value={formatAddress({ address: vessel.invoiceAddress })}
            />
            {role.isAdmin() ? (
                <>
                    <VesselDetail label={translate('vessel.paying')} value={<YesOrNo value={vessel.paying} />} />
                    <VesselDetail
                        label={translate('vessel.directOrderAllowed')}
                        value={<YesOrNo value={vessel.directOrderAllowed} />}
                    />
                </>
            ) : null}
        </Box>
    );
};
