import { useField } from 'formik';

import { FormikTextInput } from '../../form-elements/formik/FormikTextInput';
import { translate } from '../../helpers/translate.helper';

export const PhysicalSupplierDisplay = (props) => {
    const [field] = useField(props.name);
    const item = field.value;

    if (item.deleted || !props.editable) {
        return item.physicalSupplierName || '-';
    }

    return (
        <FormikTextInput
            name={`${props.name}.physicalSupplierName`}
            placeholder={translate('offer.quotationProcess.fuel.physicalSupplierPlaceholder')}
        />
    );
};
