import { CompanyMessage } from '../../../types/CompanyMessage';
import { formatCompanyName } from '../helpers/formatCompanyName.helper';
import { ListingWithMore } from '../ListingWithMore/ListingWithMore';

interface CompanyListingProps {
    companies?: CompanyMessage[];
    showCount?: number;
    includeType?: boolean;
}

export const CompanyListing = ({ companies, showCount, includeType }: CompanyListingProps) => {
    if (!companies) {
        return null;
    }
    const formattedCompanies = companies
        .map((company) => formatCompanyName({ company, includeType }))
        .filter(Boolean) as string[];

    return <ListingWithMore showCount={showCount}>{formattedCompanies}</ListingWithMore>;
};
