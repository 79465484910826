import { ButtonGroup } from '../../common/buttons/ButtonGroup';
import { FormikDebug } from '../../common/form-elements/formik/FormikDebug';
import { translate } from '../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../common/hooks/useDocumentTitle';
import { IconCircle2 } from '../../common/icons/cdl/Circle2';
import { StatusBar } from '../../common/StatusBar/StatusBar';
import { StatusBarType } from '../../common/StatusBar/StatusBarType';
import { Box } from '../../common/ui/Box';
import { CardVariation } from '../../common/ui/CardVariation';
import { Flex } from '../../common/ui/Flex';

import { FormikFuelEnquiryHeader } from './FormikFuelEnquiryHeader';
import { FuelContractProducts } from './FuelContractProducts';
import { FuelStocksCard } from './FuelStocksCard';
import { useAssignmentPrefetching } from './hooks/useAssignmentPrefetching';
import { useContractPrefetching } from './hooks/useContractPrefetching';
import { useEnquiryCreation } from './hooks/useEnquiryCreation';
import { NextButton } from './NextButton';
import { PreviousButton } from './PreviousButton';
import { ProductTable } from './ProductTable';
import { SaveAsDraftButton } from './SaveAsDraftButton';

export const SecondStep = ({ isValid, values }) => {
    const { setPage } = useEnquiryCreation();
    useContractPrefetching('contractId');
    useAssignmentPrefetching('customerId');
    useDocumentTitle(translate('fuelEnquiry.createNewTitle'));

    return (
        <Box>
            <FormikFuelEnquiryHeader title={translate('fuelEnquiry.createNewTitle')} />

            <Box padding={6} display="grid" gap={6}>
                <CardVariation paddingX={0} paddingY={0}>
                    <StatusBar
                        headline={translate('fuelEnquiry.second.statusBar.headline')}
                        subHeadline={translate('fuelEnquiry.second.statusBar.subHeadline')}
                        type={StatusBarType.WHITE}
                        icon={<IconCircle2 />}
                    />
                </CardVariation>

                {values.vesselId ? <FuelStocksCard vesselId={values.vesselId} /> : null}

                <CardVariation>
                    <FuelContractProducts />
                </CardVariation>

                <CardVariation>
                    <ProductTable />

                    <Flex marginTop={6} justifyContent="center">
                        <ButtonGroup>
                            <PreviousButton onClick={() => setPage(1)} />
                            <SaveAsDraftButton />
                            <NextButton onClick={() => setPage(3)} disabled={!isValid} />
                        </ButtonGroup>
                    </Flex>
                </CardVariation>

                <FormikDebug />
            </Box>
        </Box>
    );
};
