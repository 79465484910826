import { Button } from '../cdl/Button/Button';
import { useDrawer } from '../cdl/Drawer/useDrawer.hook';
import { IconButton } from '../cdl/IconButton/IconButton';
import { Text } from '../cdl/Text/Text';
import { ButtonGroup } from '../common/buttons/ButtonGroup';
import { HeaderWithLine } from '../common/HeaderWithLine/HeaderWithLine';
import { formatVessel } from '../common/helpers/formatVessel.helper';
import { translate } from '../common/helpers/translate.helper';
import { useUpdateEffect } from '../common/hooks/useUpdateEffect';
import { IconPencil } from '../common/icons/cdl/Pencil';
import { IconPlus } from '../common/icons/cdl/Plus';
import { IconTrash } from '../common/icons/cdl/Trash';
import { Box } from '../common/ui/Box';

import { VesselLimitationForm } from './VesselLimitationForm';

export const FormVesselLimitationsSection = ({ vessels, onSubmit, groupId, context }) => {
    const { push, pop } = useDrawer();

    useUpdateEffect(() => onSubmit([]), [context, groupId]);

    const alreadyExists = (newVessel) => {
        return vessels.some((vessel) => vessel.id === newVessel.id);
    };

    const onVesselLimitationAddSubmit = ({ data: newVessel }) => {
        if (!alreadyExists(newVessel)) {
            onSubmit(vessels.concat(newVessel));
        }
        pop();
    };

    const onVesselLimitationEditSubmit = ({ data: newVessel, oldVessel }) => {
        const index = vessels.indexOf(oldVessel);
        const updatedLimitations = [...vessels];

        if (!alreadyExists(newVessel)) {
            updatedLimitations.splice(index, 1, newVessel);
            onSubmit(updatedLimitations);
        }
        pop();
    };

    const openVesselLimitationModal = (vessel) => {
        push({
            width: '15vw',
            offsetParent: false,
            content: vessel ? (
                <VesselLimitationForm
                    title={translate('keyPorts.editVesselLimitation')}
                    onSubmit={onVesselLimitationEditSubmit}
                    data={vessel}
                    groupId={groupId}
                    context={context}
                />
            ) : (
                <VesselLimitationForm
                    title={translate('keyPorts.addVesselLimitation')}
                    onSubmit={onVesselLimitationAddSubmit}
                    groupId={groupId}
                    context={context}
                />
            ),
        });
    };

    const deleteVesselLimitation = (vesselToDelete) => {
        onSubmit(vessels.filter((vessel) => vessel.id !== vesselToDelete.id));
    };

    return (
        <Box marginTop={5}>
            <HeaderWithLine>
                <Text variant="subtitle1" as="h2">
                    {translate('keyPorts.vesselLimitation.headline')}
                </Text>
            </HeaderWithLine>

            <Box display="flex" flexDirection="column" alignItems="start" rowGap={5} marginTop={5}>
                <Text>{translate('keyPorts.vesselLimitation.text')}</Text>

                {vessels?.length ? (
                    <>
                        {vessels.map((vessel, index) => (
                            <Box
                                key={index}
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                marginBottom={3}
                                width="100%"
                            >
                                <Text>{formatVessel({ vessel })}</Text>

                                <ButtonGroup>
                                    <IconButton
                                        Icon={IconPencil}
                                        type="button"
                                        onClick={() =>
                                            openVesselLimitationModal({
                                                vessel,
                                            })
                                        }
                                    />
                                    <IconButton
                                        Icon={IconTrash}
                                        destructive
                                        type="button"
                                        onClick={() => deleteVesselLimitation(vessel)}
                                    />
                                </ButtonGroup>
                            </Box>
                        ))}
                    </>
                ) : null}

                <Button
                    type="button"
                    onClick={() => openVesselLimitationModal()}
                    disabled={!groupId || !context}
                    leadingVisual={<IconPlus height={16} width={16} />}
                >
                    {translate('keyPorts.addVesselLimitation')}
                </Button>
            </Box>
        </Box>
    );
};
