export const Triangle = ({ direction = 'up', ...rest }) => {
    return (
        <svg
            width="4"
            height="3"
            viewBox="0 0 4 3"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
                transform: direction === 'up' ? 'rotate(180deg) scale(1.5)' : 'scale(1.5)',
            }}
            {...rest}
        >
            <path d="M2 3L3.73205 0L0.267949 0L2 3Z" fill="currentColor" />
        </svg>
    );
};
