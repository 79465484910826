import { formatMoney } from '../../common/helpers/formatMoney.helper';
import { formatNumber } from '../../common/helpers/formatNumber.helper';
import { translate } from '../../common/helpers/translate.helper';
import { Td } from '../../common/Table/Td';
import { Tr } from '../../common/Table/Tr';

const SampleKit = ({ sampleKit, position, showPrices }) => {
    return (
        <Tr inactive={sampleKit.deleted} focused={sampleKit.added && !sampleKit.deleted}>
            <Td>
                <span>{position}</span>
            </Td>
            <Td colSpan={1} danger={sampleKit.changes && sampleKit.changes.nameChanged && !sampleKit.deleted}>
                <div>
                    <span>{sampleKit.name}</span> <br />
                    <small>{translate('order.samplekit.title')}</small>
                </div>
            </Td>
            <Td colSpan={2}>{translate('order.samplekit.packtype')}</Td>
            <Td colSpan={4} danger={sampleKit.changes && sampleKit.changes.quantityChanged && !sampleKit.deleted}>
                {formatNumber({
                    number: sampleKit.quantity,
                })}
            </Td>
            {showPrices ? (
                <Td colSpan={2} danger={sampleKit.changes && sampleKit.changes.valueChanged && !sampleKit.deleted}>
                    <span>
                        {sampleKit.value
                            ? formatMoney({
                                  value: sampleKit.value.value,
                                  currency: sampleKit.value.currency,
                                  minimumFractionDigits: 2,
                              })
                            : translate('global.notavailable')}
                    </span>
                </Td>
            ) : null}
        </Tr>
    );
};

export const SampleKits = ({ sampleKits, offset, showPrices }) => {
    return sampleKits.map((sampleKit, index) => (
        <SampleKit sampleKit={sampleKit} position={offset + index + 1} showPrices={showPrices} key={index} />
    ));
};
