import { Tooltip } from '../../cdl/Tooltip/Tooltip';
import { translate } from '../../common/helpers/translate.helper';
import { Table } from '../../common/Table/Table';
import { Tr } from '../../common/Table/Tr';

import { Products } from './Products';
import { SampleKits } from './SampleKits';
import { Surcharges } from './Surcharges';
import { Total } from './Total';

const HeaderRow = ({ showPrices }) => {
    return (
        <Tr>
            <th>{translate('order.pos')}</th>
            <th>{translate('order.product')}</th>
            <th>{translate('order.packtype')}</th>
            <th>{translate('order.unitsize')}</th>
            <th>{translate('order.units')}</th>
            <th>{translate('order.volume')}</th>
            <th>{translate('order.productunit')}</th>
            {showPrices ? (
                <>
                    <Tooltip label={translate('order.ppltooltip')}>
                        <th>{translate('order.ppl')}</th>
                    </Tooltip>

                    <th>{translate('order.itemtotal')}</th>
                </>
            ) : null}
        </Tr>
    );
};

/**
 * @deprecated use LubesReadOnlyProductTable instead
 **/

export const ProductTable = ({ offer, showPrices = true }) => {
    return (
        <Table>
            <thead>
                <HeaderRow showPrices={showPrices} />
            </thead>
            <tbody>
                <Products items={offer.items} showPrices={showPrices} />
                <SampleKits sampleKits={offer.samplekits} offset={offer.items.length} showPrices={showPrices} />
                <Surcharges
                    surcharges={offer.surcharges}
                    offset={offer.items.length + offer.samplekits.length}
                    showPrices={showPrices}
                />
                <Total offer={offer} showPrices={showPrices} />
            </tbody>
        </Table>
    );
};
