import { Button } from '../../cdl/Button/Button';
import { useDrawer } from '../../cdl/Drawer/useDrawer.hook';
import { translate } from '../../common/helpers/translate.helper';
import { useProductContext } from '../../common/hooks/useProductContext';
import { useRole } from '../../common/hooks/useRole';
import { CreateAgentForm } from '../CreateAgentForm/CreateAgentForm';

export const CreateAgentButton = () => {
    const { push, pop } = useDrawer();
    const role = useRole();
    const { context } = useProductContext();

    const companyId = role.isOneCompanyUser(context)
        ? role.getCompaniesWithType(context).map((company) => company.id)[0]
        : null;

    const openCreateAgentModal = () => {
        push({
            content: (
                <CreateAgentForm
                    data={{
                        name: '',
                        portIds: [],
                        customerId: companyId,
                    }}
                    onSuccess={pop}
                    comment={undefined}
                    showCompanySelect={role.isAdmin() || !role.isOneCompanyUser(context)}
                />
            ),
        });
    };

    return (
        <Button emphasis="high" onClick={openCreateAgentModal}>
            {translate('agents.createAgent')}
        </Button>
    );
};
