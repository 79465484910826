import { translate } from '../../common/helpers/translate.helper';
import { ListingWithMore } from '../../common/ListingWithMore/ListingWithMore';

export const ProductListing = ({ productGroups }) => {
    const createProductNamesMap = (productGroups) => {
        const productNamesList = [];
        productGroups.forEach((productGroup) => {
            productGroup.products.forEach((productData) => {
                if (!productData.product) {
                    return;
                }

                const productName = `${productData.product.name} ${translate(
                    'sulphurContent.' + productData.product.sulphurContent
                )}`;

                if (!productNamesList.some((name) => name === productName)) productNamesList.push(productName);
            });
        });
        return productNamesList;
    };

    return <ListingWithMore showCount={1}>{createProductNamesMap(productGroups)}</ListingWithMore>;
};
