import { DefaultOption } from '../../cdl/ComboBox/ComboBox';
import { FormikLegacyEnumOptionSelect } from '../../common/form-elements/formik/FormikLegacyEnumOptionSelect';
import { translate } from '../../common/helpers/translate.helper';

interface FormikFuelIsoSpecSelectProps {
    name: string;
    label?: string;
}

export const FormikFuelIsoSpecSelect = (props: FormikFuelIsoSpecSelectProps) => {
    return (
        <FormikLegacyEnumOptionSelect
            enumType="fuelIsoSpec"
            name={props.name}
            label={props.label}
            context="FUEL"
            placeholder={translate('order.isoPlaceholder')}
            sort={(options: DefaultOption[]) => options.reverse()}
            isClearable
        />
    );
};
