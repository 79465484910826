import { useField, useFormikContext } from 'formik';

import { IconButton } from '../../../cdl/IconButton/IconButton';
import { FormikSwitch } from '../../../cdl/Switch/FormikSwitch';
import { FormikFuelMoneyInput } from '../../form-elements/formik/FormikFuelMoneyInput';
import { formatFuelMoney } from '../../helpers/formatFuelMoney.helper';
import { translate } from '../../helpers/translate.helper';
import { IconX } from '../../icons/cdl/X';
import { Td } from '../../Table/Td';
import { Tr } from '../../Table/Tr';
import { Box } from '../../ui/Box';
import { SurchargeWaiverDisplay } from '../common/SurchargeWaiverDisplay';

import { SurchargeName } from './SurchargeName';
import { useProductTableConfig } from './useProductTableConfig';

export const SurchargeRow = (props) => {
    const { values: offer } = useFormikContext();
    const [field] = useField(props.name);
    const surcharge = field.value;

    const { showPrices, editable, editSurcharges, showPhysicalSupplierColumn, showCounterOffers } =
        useProductTableConfig();

    let emptyColumns = 5;

    if (showPhysicalSupplierColumn) {
        emptyColumns++;
    }

    if (showPrices) {
        emptyColumns++;

        if (showCounterOffers && offer.hasOpenCounterOffers()) {
            emptyColumns++;
        }
    }

    const canEditPrices = editable && editSurcharges;

    return (
        <Tr
            key={props.name}
            inactive={surcharge.deleted}
            focused={surcharge.added && !surcharge.deleted}
            style={{
                height: '64px',
            }}
        >
            <Td>
                <SurchargeName name={props.name} editable={editable && editSurcharges} />
            </Td>
            <Td colSpan={emptyColumns}>
                <SurchargeWaiverDisplay value={surcharge.value ? Number(surcharge.value.value) : null} />
            </Td>
            {showPrices ? (
                <Td>
                    {surcharge.deleted || !canEditPrices ? (
                        formatFuelMoney({
                            value: surcharge.value.value,
                            currency: surcharge.value.currency,
                        })
                    ) : (
                        <FormikFuelMoneyInput
                            placeholder={translate('offer.quotationProcess.fuel.pricePlaceholder')}
                            name={`${props.name}.value`}
                            allowNegativeValue
                            required
                        />
                    )}
                </Td>
            ) : null}
            {editable ? (
                <Td>
                    {editSurcharges ? (
                        <Box display="flex" justifyContent="center">
                            {surcharge.new ? (
                                <IconButton type="button" Icon={IconX} onClick={props.onRowRemoveClicked} />
                            ) : (
                                <FormikSwitch name={`${props.name}.deleted`} invert />
                            )}
                        </Box>
                    ) : null}
                </Td>
            ) : null}
        </Tr>
    );
};
