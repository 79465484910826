import { CoreData } from '../../../../common/CoreData/CoreData';
import { formatDateTime } from '../../../../common/helpers/formatDateTime.helper';
import { formatPort } from '../../../../common/helpers/formatPort.helper';
import { formatVessel } from '../../../../common/helpers/formatVessel.helper';
import { translate } from '../../../../common/helpers/translate.helper';

export const FuelOfferSummary = ({ offer }) => {
    const fieldDefinition = {
        vessel: {
            label: translate('offer.quotationProcess.fuel.vessel'),
            path: 'vessel',
            render: function render(vessel) {
                if (!vessel) {
                    return null;
                }
                return formatVessel({
                    vessel,
                });
            },
        },
        port: {
            label: translate('offer.quotationProcess.fuel.port'),
            path: 'port',
            render: function render(port) {
                if (!port) {
                    return null;
                }
                return formatPort({ port });
            },
        },
        agent: {
            label: translate('offer.quotationProcess.fuel.agent'),
            path: 'agentInformation',
            render: function render(agent) {
                if (!agent) {
                    return null;
                }
                return agent.name;
            },
        },
        eta: {
            label: translate('offer.quotationProcess.fuel.eta'),
            path: 'eta',
            render: function render(eta) {
                if (!eta) {
                    return null;
                }
                return formatDateTime({
                    date: new Date(eta),
                });
            },
        },
        etd: {
            label: translate('offer.quotationProcess.fuel.etd'),
            path: 'etd',
            render: function render(etd) {
                if (!etd) {
                    return null;
                }
                return formatDateTime({
                    date: new Date(etd),
                });
            },
        },
        buyerReference: {
            label: translate('offer.quotationProcess.fuel.buyerReference'),
            path: 'buyerReference',
        },
    };

    const fields = [
        fieldDefinition.vessel,
        fieldDefinition.port,
        fieldDefinition.agent,
        fieldDefinition.eta,
        fieldDefinition.etd,
        fieldDefinition.buyerReference,
    ];

    return <CoreData columnWidth={1 / 3} data={offer} baseFields={fields} />;
};
