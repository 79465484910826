import { useState } from 'react';

import { Button } from '../../cdl/Button/Button';
import { DrawerFormLayout } from '../../cdl/Drawer/DrawerFormLayout';
import { IconButton } from '../../cdl/IconButton/IconButton';
import { Text } from '../../cdl/Text/Text';
import { Form } from '../../common/form-elements/Form/Form';
import { FormFuelProductSelect } from '../../common/form-elements/FuelProductSelect/FuelProductSelect';
import { FormFormattedNumberInput } from '../../common/form-elements/NumberInputs/FormattedNumberInput';
import { FormFuelMoneyInput } from '../../common/form-elements/NumberInputs/FormFuelMoneyInput';
import { FormPricingCodeSelect } from '../../common/form-elements/PricingCodeSelect/PricingCodeSelect';
import { FormSelect } from '../../common/form-elements/Select/Select';
import { FormTextInput } from '../../common/form-elements/TextInput/TextInput';
import { HeaderWithLine } from '../../common/HeaderWithLine/HeaderWithLine';
import { translate } from '../../common/helpers/translate.helper';
import { useEnums } from '../../common/hooks/useEnums';
import { IconPlus } from '../../common/icons/cdl/Plus';
import { IconTrash } from '../../common/icons/cdl/Trash';
import { Box } from '../../common/ui/Box';
import { generateRandomId } from '../../order/compare/generateRandomId';

import { getPricingCodeFrequencies } from './utils/getPricingCodeFrequencies';
import {
    checkProductGroupValidity,
    checkProductValidity,
    checkTenderProductGroupValidity,
    checkTenderProductValidity,
} from './validateContract';

const t = (key) => translate(`contracts.product.${key}`);
const pt = (key) => translate(`contracts.product.placeholder.${key}`);

export const ProductForm = ({
    data = { products: [{ productId: generateRandomId() }] },
    onSubmit: onSubmitCallback,
    contract,
    title,
}) => {
    const [formState, setFormState] = useState(data);
    const { enums, getHumanReadableValue } = useEnums();

    const products = formState?.products;
    const pricingCodeFrequencies = getPricingCodeFrequencies(contract.pricingDate);

    const isValid = contract.tender.enabled
        ? checkTenderProductGroupValidity(contract, formState)
        : checkProductGroupValidity(contract, formState);

    const createOptionsFromEnum = (enumType) => {
        return Object.keys(enums[enumType]).map((key) => ({
            label: getHumanReadableValue(enumType, key),
            value: key,
        }));
    };

    const isoSpecOptions = Object.keys(enums).length ? createOptionsFromEnum('fuelIsoSpec') : [];

    const addProduct = () => {
        products.push({ productId: generateRandomId() });
        setFormState({ ...formState, products });
    };

    const removeProduct = (index) => {
        products.splice(index, 1);
        setFormState({ ...formState, products });
    };

    const onSubmit = ({ data, event }) => {
        event.preventDefault();
        onSubmitCallback(data);
    };

    const onChange = ({ data }) => {
        setFormState(data);
    };

    return (
        <Form height="100%" data={formState} onSubmit={onSubmit} onChange={onChange}>
            <DrawerFormLayout title={title} isSubmitButtonDisabled={!isValid}>
                {products?.map((productData, index) => {
                    return (
                        <Box
                            padding={0}
                            marginBottom={products.length === index + 1 ? 4 : 8}
                            key={productData.productId}
                        >
                            <Box display="grid" rowGap={4}>
                                <HeaderWithLine>
                                    <Text as="h2" variant="subtitle1">
                                        {translate('contracts.product.productHeader', {
                                            productCount: index + 1,
                                        })}
                                    </Text>

                                    {products.length > 1 ? (
                                        <IconButton
                                            emphasis="medium"
                                            Icon={IconTrash}
                                            destructive
                                            onClick={() => removeProduct(index)}
                                        />
                                    ) : null}
                                </HeaderWithLine>

                                <FormFuelProductSelect
                                    dataPath={`products[${index}].product`}
                                    label={t('product')}
                                    placeholder={pt('product')}
                                    required
                                />

                                <FormPricingCodeSelect
                                    dataPath={`products[${index}].pricingCode`}
                                    conversionRateDataPath={`products[${index}].conversionRate`}
                                    frequencies={pricingCodeFrequencies}
                                    negativeCaption={
                                        productData.pricingCode &&
                                        pricingCodeFrequencies.length &&
                                        !pricingCodeFrequencies.includes(productData.pricingCode.frequency)
                                            ? translate('contracts.product.invalidPricingCodeError')
                                            : null
                                    }
                                    required={!contract.tender.enabled}
                                />

                                <FormFuelMoneyInput
                                    dataPath={`products[${index}].priceDifference.value`}
                                    label={t('differential')}
                                    placeholder={pt('differential')}
                                    annotation={t('differentialAnnotation')}
                                    allowNegativeValue
                                    required={!contract.tender.enabled}
                                />

                                <FormTextInput
                                    type="number"
                                    step="any"
                                    dataPath={`products[${index}].conversionRate`}
                                    label={translate('contracts.product.conversionRate')}
                                    placeholder={translate('contracts.product.placeholder.conversionRate')}
                                    disabled={({ products }) => !products[index]?.pricingCode}
                                    required={!contract.tender.enabled}
                                />

                                <FormSelect
                                    placeholder={translate('contracts.product.placeholder.isoSpec')}
                                    dataPath={`products[${index}].isoSpec`}
                                    options={isoSpecOptions}
                                    label={translate('contracts.product.isoSpec')}
                                />
                            </Box>
                        </Box>
                    );
                })}

                <Box display="flex" justifyContent="flex-end" marginBottom={6}>
                    <Button
                        type="button"
                        onClick={() => addProduct()}
                        disabled={
                            contract.tender.enabled
                                ? !checkTenderProductValidity(contract, formState)
                                : !checkProductValidity(contract, formState)
                        }
                        leadingVisual={<IconPlus height={16} width={16} />}
                    >
                        {t('addAlternateProduct')}
                    </Button>
                </Box>

                <Text as="h2" variant="subtitle1">
                    {t('totalVolumes')}
                </Text>

                <FormFormattedNumberInput
                    dataPath="contractVolume"
                    label={t('volume')}
                    placeholder={pt('volume')}
                    annotation={t('volumeAnnotation')}
                    required
                />

                <FormFormattedNumberInput
                    dataPath="minStemSize"
                    label={t('minStemSize')}
                    placeholder={pt('minStemSize')}
                    annotation={t('minStemSizeAnnotation')}
                    required={!contract.tender.enabled}
                />

                <FormFormattedNumberInput
                    dataPath="minContractVolume"
                    label={t('minVolume')}
                    placeholder={pt('minVolume')}
                    annotation={t('minVolumeAnnotation')}
                />

                <FormFormattedNumberInput
                    dataPath="maxContractVolume"
                    label={t('maxVolume')}
                    placeholder={pt('maxVolume')}
                    annotation={t('maxVolumeAnnotation')}
                />
            </DrawerFormLayout>
        </Form>
    );
};
