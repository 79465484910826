import { ProductContext } from '../../../../../types/ProductContext';
import { Link } from '../../../../cdl/Link/Link';
import { Text } from '../../../../cdl/Text/Text';
import { DataSheet } from '../../../../common/DataSheet/DataSheet';
import { createdBy } from '../../../../common/helpers/createdBy.helper';
import { formatCompanyName } from '../../../../common/helpers/formatCompanyName.helper';
import { formatDateDelivery } from '../../../../common/helpers/formatDateDelivery.helper';
import { formatVessel } from '../../../../common/helpers/formatVessel.helper';
import { translate } from '../../../../common/helpers/translate.helper';
import { useRole } from '../../../../common/hooks/useRole';
import { MultilineParagraph } from '../../../../common/MultilineParagraph/MultilineParagraph';
import { YesOrNo } from '../../../../common/YesOrNo/YesOrNo';
import { Port } from '../../../../port/Port';
import { OrderModel } from '../../../model/OrderModel';

interface OrderDetailsProps {
    order: OrderModel;
}

export const OrderDetails = ({ order }: OrderDetailsProps) => {
    const role = useRole();

    return (
        <DataSheet>
            <DataSheet.Label>{translate('order.detail.coreData.vessel')}</DataSheet.Label>
            <Text aria-label="Vessel name">
                <Link to="/vessel/$id" params={{ id: order.vesselId }} size="small">
                    {formatVessel({ vessel: order.vessel })}
                </Link>
            </Text>

            {order.dateDelivery ? (
                <>
                    <DataSheet.Label>{translate('order.detail.coreData.dateDelivery')}</DataSheet.Label>
                    <Text>{formatDateDelivery({ date: order.dateDelivery })}</Text>
                </>
            ) : null}

            {order.port ? (
                <>
                    <DataSheet.Label>{translate('order.detail.coreData.port')}</DataSheet.Label>
                    <Port port={order.port} />
                </>
            ) : null}

            {order.agentInformation?.name ? (
                <>
                    <DataSheet.Label>{translate('order.detail.coreData.agent')}</DataSheet.Label>
                    <Text>{order.agentInformation.name}</Text>
                </>
            ) : null}

            {order.buyerReference ? (
                <>
                    <DataSheet.Label>{translate('order.detail.coreData.buyerReference')}</DataSheet.Label>
                    <Text>{order.buyerReference} </Text>
                </>
            ) : null}

            {!role.isOneCompanyUser(ProductContext.LUBES) ? (
                <>
                    <DataSheet.Label>{translate('order.detail.coreData.company')}</DataSheet.Label>
                    <Text>{formatCompanyName({ company: order.customer, includeType: role.isAdmin() })}</Text>
                </>
            ) : null}

            <DataSheet.Label>{translate('order.detail.coreData.dateCreated')}</DataSheet.Label>
            <Text>{createdBy({ order })}</Text>

            <DataSheet.Label>{translate('order.detail.coreData.spotSuppliers')}</DataSheet.Label>
            <Text>
                <YesOrNo value={!!order.spot} />
            </Text>

            {role.isAdmin() && order.comment ? (
                <>
                    <DataSheet.Label>{translate('order.detail.coreData.comment')}</DataSheet.Label>
                    <Text>
                        <MultilineParagraph>{order.comment}</MultilineParagraph>
                    </Text>
                </>
            ) : null}

            {order.creationComment ? (
                <>
                    <DataSheet.Label>{translate('order.detail.coreData.creationComment')}</DataSheet.Label>
                    <Text>
                        <MultilineParagraph>{order.creationComment}</MultilineParagraph>
                    </Text>
                </>
            ) : null}
        </DataSheet>
    );
};
