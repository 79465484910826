import { Table } from '../../Table/Table';

import { ProductTableContext } from './ProductTableContext';
import { TableContent } from './TableContent';
import { TableHeaderRow } from './TableHeaderRow';

export const FormikFuelProductTable = ({ config }) => (
    <ProductTableContext.Provider
        value={{
            showPrices: true,
            editable: true,
            showPhysicalSupplierColumn: true,
            showCounterOffers: true,
            showEnergyContentColumn: true,
            editEnergyContent: true,
            editSurcharges: true,
            editPrices: true,
            ...config,
        }}
    >
        <Table>
            <thead>
                <TableHeaderRow />
            </thead>
            <tbody>
                <TableContent />
            </tbody>
        </Table>
    </ProductTableContext.Provider>
);
