import { linkOptions, useLocation } from '@tanstack/react-router';
import moment from 'moment/moment';

import { ProductContext } from '../../../types/ProductContext';
import { TableBuilder } from '../../cdl/TableBuilder/TableBuilder';
import { TableBuilderColumn } from '../../cdl/TableBuilder/TableBuilderColumn';
import { Tooltip } from '../../cdl/Tooltip/Tooltip';
import { formatCompanyName } from '../../common/helpers/formatCompanyName.helper';
import { formatDate } from '../../common/helpers/formatDate.helper';
import { formatVessel } from '../../common/helpers/formatVessel.helper';
import { translate } from '../../common/helpers/translate.helper';
import { useRole } from '../../common/hooks/useRole';
import { IconAlertTriangle } from '../../common/icons/cdl/AlertTriangle';
import { IconCheck } from '../../common/icons/cdl/Check';
import { OfferStatusTag } from '../../common/OfferStatusTag/OfferStatusTag';
import { Box } from '../../common/ui/Box';
import { theme } from '../../common/ui/theme';
import { UnreadIndicator } from '../../order/common/UnreadIndicator';
import { OrderModel } from '../../order/model/OrderModel';
import { OfferCount } from '../../order/OrderOverview/lubes/OfferCount';
import { Port } from '../../port/Port';
import { CONTEXT_TYPE } from '../../user/model/ContextType';

interface SearchResultOrdersTableProps {
    orders: OrderModel[];
}

export const SearchResultOrderTable = ({ orders }: SearchResultOrdersTableProps) => {
    const location = useLocation();
    const role = useRole();

    const buildRowLink = (order: OrderModel) => {
        switch (order.type) {
            case ProductContext.FUEL:
                if (order.isClosed() && order.chosenOfferId) {
                    return linkOptions({ to: '/fuel/offer/$id', params: { id: order.chosenOfferId } });
                }

                if (order.isDraft() && !role.isAdmin() && role.hasWriteRights(order.customerId)) {
                    return linkOptions({
                        to: '/fuel/create',
                        search: { orderId: order.id },
                        state: { originLocation: location },
                    });
                }

                return linkOptions({ to: '/fuel/order/$id', params: { id: order.id } });

            case ProductContext.LUBES:
                if (order.isClosed() && order.chosenOfferId) {
                    return linkOptions({ to: '/offer/$id', params: { id: order.chosenOfferId } });
                }

                if (order.isDraft() && !role.isAdmin() && role.hasWriteRights(order.customerId)) {
                    return linkOptions({ to: '/orders/draft/$id', params: { id: order.id } });
                }

                return linkOptions({ to: '/order/$id', params: { id: order.id } });
        }
    };

    return (
        <TableBuilder data={orders} rowLink={buildRowLink} grow={false}>
            {role.isCustomer() ? (
                <TableBuilderColumn<OrderModel> header="" width="1%">
                    {(order) => <Box width="8px">{!order.read ? <UnreadIndicator /> : null}</Box>}
                </TableBuilderColumn>
            ) : null}

            <TableBuilderColumn<OrderModel> header={translate('order.buyerreferenceshort')} width="10%" truncate>
                {(order) => order.buyerReference || '-'}
            </TableBuilderColumn>

            {!role.isOneCompanyUser() ? (
                <TableBuilderColumn<OrderModel>
                    header={role.isCustomer() ? translate('order.company') : translate('order.customer')}
                    width="15%"
                    truncate
                >
                    {(order) =>
                        order.customer
                            ? formatCompanyName({ company: order.customer, includeType: role.isAdmin() })
                            : null
                    }
                </TableBuilderColumn>
            ) : null}

            <TableBuilderColumn<OrderModel> header={translate('order.vessel')} width="15%" truncate>
                {(order) => formatVessel({ vessel: order.vessel, short: true })}
            </TableBuilderColumn>

            <TableBuilderColumn<OrderModel> header={translate('order.port')} width="20%">
                {(order) => {
                    if (!order.port) {
                        return null;
                    }
                    return <Port port={order.port} vesselId={order.vesselId} locode={false} showTooltip />;
                }}
            </TableBuilderColumn>

            <TableBuilderColumn<OrderModel> header={translate('order.dateDeliveryShort')} width="10%">
                {(order) => {
                    if (!order.dateDelivery) {
                        return null;
                    }

                    const today = moment.utc(moment()).startOf('day');
                    if (moment(order.dateDelivery) < today) {
                        return (
                            <Box display="flex" flexDirection="row" alignItems="center" columnGap={4}>
                                {formatDate({
                                    date: order.dateDelivery,
                                    timeZone: 'UTC',
                                })}
                                <Tooltip label={translate('order.outdatedtooltip')}>
                                    <div>
                                        <IconAlertTriangle
                                            height={16}
                                            width={16}
                                            color={theme.colors.foreground.subtle}
                                        />
                                    </div>
                                </Tooltip>
                            </Box>
                        );
                    }

                    return formatDate({
                        date: order.dateDelivery,
                        timeZone: 'UTC',
                    });
                }}
            </TableBuilderColumn>

            <TableBuilderColumn<OrderModel> header={translate('order.status')} width="10%">
                {(order) => {
                    if (!order.offerState) {
                        return null;
                    }
                    return <OfferStatusTag state={order.offerState} variant="customer" />;
                }}
            </TableBuilderColumn>

            {role.isAdmin() ? (
                <TableBuilderColumn<OrderModel> header={translate('order.detail.spot')} width="5%">
                    {(order) => {
                        if (order.type === CONTEXT_TYPE.LUBES && order.spot) {
                            return <IconCheck height={16} width={16} />;
                        }
                        return null;
                    }}
                </TableBuilderColumn>
            ) : null}

            <TableBuilderColumn<OrderModel> header={translate('order.offercount')} width="5%">
                {(order) => (
                    <OfferCount
                        offerCount={order.offerCount}
                        totalOffers={order.receiverSupplierIds.length}
                        includesSpot={order.spot}
                    />
                )}
            </TableBuilderColumn>
        </TableBuilder>
    );
};
