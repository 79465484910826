import { Tooltip } from '../../cdl/Tooltip/Tooltip';
import { Port } from '../../port/Port';
import { translate } from '../helpers/translate.helper';
import { Box } from '../ui/Box';

export const PortListing = ({ ports }) => {
    const shownListItems = ports.slice(0, 1);

    const formattedPortsTooltip = ports.map((listItem, index) => (
        <div key={index}>
            <Port highlightColor="white" port={listItem}>
                {listItem}
            </Port>
        </div>
    ));

    if (ports.length <= 1) {
        return <Port port={ports[0]} showTooltip />;
    }

    return (
        <Tooltip label={formattedPortsTooltip}>
            <Box>
                <Port port={shownListItems[0]} />
                <span>
                    {translate('global.seeMore', {
                        count: ports.length - 1,
                    })}
                </span>
            </Box>
        </Tooltip>
    );
};
