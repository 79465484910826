import { useQuery } from '@tanstack/react-query';

import { Text } from '../../../cdl/Text/Text';
import { getFuelProducts } from '../../api/clients/product.api';
import { queryKeys } from '../../api/queryKeys';
import { DoubleLineOption } from '../../DoubleLineOption/DoubleLineOption';
import { translate } from '../../helpers/translate.helper';
import { useEnums } from '../../hooks/useEnums';
import { useFormData } from '../Form/useFormData';
import { Select } from '../Select/Select';

const t = (k) => translate(`contracts.product.${k}`);
const pt = (k) => t(`placeholder.${k}`);

const FuelProductValue = ({ product }) => {
    const translatedSulphur = translate(`sulphurContent.${product.sulphurContent}`);

    return (
        <Text>
            {product.name} {translatedSulphur}
        </Text>
    );
};

const FuelProductOption = ({ data: { product }, ...props }) => {
    const {
        enums: { productGroup, sulphurContent },
        isPending,
    } = useEnums();

    if (isPending) return null;

    const group = productGroup[product.group].humanReadableValue;
    const sulphur = sulphurContent[product.sulphurContent].humanReadableValue;

    return <DoubleLineOption headline={props.label} subline={`${group} ${sulphur}`} {...props} />;
};

const filterOption = (option, searchQuery) => {
    const product = option.data.product;
    const label = option.data.label;

    // Split by space, get rid of empty values.
    const searchValues = searchQuery.split(' ').filter(Boolean);

    return searchValues.every((value) => {
        return (
            product.group.toLowerCase().includes(value.toLowerCase()) ||
            product.name.toLowerCase().includes(value.toLowerCase()) ||
            product.sulphurContent.toLowerCase().includes(value.toLowerCase()) ||
            label.toLowerCase().includes(value.toLowerCase())
        );
    });
};

export const FuelProductSelect = ({ label, value, onChange, required, fetchProducts = getFuelProducts }) => {
    const { data: products = [], isPending } = useQuery({
        queryKey: queryKeys.fuelProducts(),
        queryFn: () => fetchProducts(),
    });

    const formattedOptions = products.map((product) => {
        const translatedSulphurContent = translate(`sulphurContent.${product.sulphurContent}`);

        return {
            label: `${product.name} ${translatedSulphurContent}`,
            value: product.id,
            product,
        };
    });

    const updateSelectedValue = (option) => {
        onChange(option);
    };

    const selectedValue = formattedOptions.find((option) => option.value === value);

    return (
        <Select
            label={label}
            filterOption={filterOption}
            options={formattedOptions}
            value={selectedValue}
            placeholder={isPending ? pt('productsLoading') : pt('products')}
            onChange={updateSelectedValue}
            isPending={isPending}
            required={required}
            components={{
                Option: FuelProductOption,
            }}
        />
    );
};

export const FormFuelProductSelect = ({ dataPath, required, ...props }) => {
    const { value, onChange } = useFormData(dataPath);
    const onChangeHandle = (option) => {
        onChange({
            target: { value: option.product },
        });
    };

    return (
        <FuelProductSelect key={value.id} value={value.id} onChange={onChangeHandle} required={required} {...props} />
    );
};
