import useComponentSize from '@rehooks/component-size';
import { useState } from 'react';

import { SliderWrapper } from '../../common/Slider/SliderWrapper';
import { Box } from '../../common/ui/Box';
import { Flex } from '../../common/ui/Flex';

import { ContractProductSlider } from './ContractProductsSlider';

const getSlidesPerViewByWidth = (width) => {
    const boxWidth = 260;
    const gapBetweenBoxes = 32;
    const navigationElementsAndPadding = 192;

    const widthWithoutNavigation = width - navigationElementsAndPadding;
    const boxWidthAndSpace = boxWidth + gapBetweenBoxes / 2;

    return Math.floor(widthWithoutNavigation / boxWidthAndSpace);
};

export const ContractProductsWrapper = ({ children: contractProductButtons }) => {
    const [sliderWrapperRef, setSliderWrapperRef] = useState(null);
    const { width } = useComponentSize({ current: sliderWrapperRef });
    const slidesPerView = getSlidesPerViewByWidth(width);

    if (contractProductButtons.length > slidesPerView) {
        return (
            <SliderWrapper ref={setSliderWrapperRef} paddingLeft={7} paddingRight={7}>
                <Box>
                    <ContractProductSlider slidesPerView={slidesPerView}>
                        {contractProductButtons.map((button, index) => (
                            <Box
                                key={index}
                                width="260px"
                                border={1}
                                borderRadius={1}
                                marginTop={5}
                                marginBottom={7}
                                marginX="auto"
                            >
                                {button}
                            </Box>
                        ))}
                    </ContractProductSlider>
                </Box>
            </SliderWrapper>
        );
    }

    return (
        <SliderWrapper ref={setSliderWrapperRef} paddingLeft={7} paddingRight={7}>
            <Flex flexDirection="row">
                {contractProductButtons.map((button, index) => (
                    <Box
                        key={index}
                        width="260px"
                        border={1}
                        borderRadius={1}
                        marginTop={5}
                        marginBottom={7}
                        marginRight={7}
                    >
                        {button}
                    </Box>
                ))}
            </Flex>
        </SliderWrapper>
    );
};
