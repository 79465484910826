import { animated, config, useTransition } from '@react-spring/web';
import useComponentSize from '@rehooks/component-size';
import { ReactNode, useRef } from 'react';

import { Box } from '../../common/ui/Box';
import { colors } from '../../common/ui/colors';

import { CollapsableDescription } from './components/CollapsableDescription';
import { CollapsableHeader } from './components/CollapsableHeader';
import { CollapsableTitle } from './components/CollapsableTitle';

interface CollapsableProps {
    isOpen: boolean;
    children?: ReactNode | (() => ReactNode);
    header: ReactNode;
}

export const Collapsable = (props: CollapsableProps) => {
    const collapsableBodyRef = useRef<HTMLDivElement>(null);
    const { height } = useComponentSize(collapsableBodyRef);

    const closedState = {
        opacity: 0,
        height: 0,
        borderBottomWidth: 0,
    };

    const openState = {
        opacity: 1,
        height: height + 1, //To fit content within the bottom border
        borderBottomWidth: 1,
    };

    const contentAnimation = useTransition(props.isOpen, {
        from: closedState,
        leave: closedState,
        update: openState,
        config: {
            ...config.default,
            tension: 300,
            clamp: true,
        },
    });

    return (
        <div>
            {props.header}
            {contentAnimation((style, item) => {
                if (!item) {
                    return null;
                }

                let dynamicChildren: ReactNode;

                if (typeof props.children === 'function') {
                    dynamicChildren = props.children();
                } else {
                    dynamicChildren = props.children;
                }

                return (
                    <animated.div
                        style={{
                            border: `1px solid ${colors.border.muted}`,
                            borderBottomLeftRadius: '6px',
                            borderBottomRightRadius: '6px',
                            borderTop: 'none',
                            overflow: 'hidden',
                            ...style,
                        }}
                    >
                        <Box ref={collapsableBodyRef}>{dynamicChildren}</Box>
                    </animated.div>
                );
            })}
        </div>
    );
};

Collapsable.Header = CollapsableHeader;
Collapsable.Title = CollapsableTitle;
Collapsable.Description = CollapsableDescription;
