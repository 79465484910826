import { animated, useTransition } from '@react-spring/web';
import useComponentSize from '@rehooks/component-size';
import { css } from '@styled-system/css';
import { forwardRef, useRef } from 'react';
import styled from 'styled-components';

import { queryKeys } from '../../../common/api/queryKeys';
import { FuelShowOfferProductTable } from '../../../common/FuelShowOfferProductTable/FuelShowOfferProductTable';
import { Box } from '../../../common/ui/Box';
import { Flex } from '../../../common/ui/Flex';
import { OfferChat } from '../../../offer/OfferChat/OfferChat';
import { OfferCompareAttachments } from '../common/OfferCard/OfferCompareAttachments';
import { OfferListItemGrid } from '../common/OfferCard/OfferListItemGrid';

import { OfferActions } from './OfferCard/OfferActions';
import { OfferCardContainer } from './OfferCard/OfferCardContainer';
import { OfferSummary } from './OfferCard/OfferSummary';

const OfferChatWrapper = styled(Box)(
    css({
        width: '20%',
        height: '100%',
        position: 'absolute',
        right: 0,
        borderLeft: 1,
    })
);

const OfferAccordion = styled(Flex)(
    css({
        flexDirection: 'column',
        width: '100%',
        marginBottom: 5,
    })
);

const OfferAccordionContentWrapper = styled(Flex)(
    css({
        backgroundColor: 'white',
        width: 'calc(100% - 16px)',
        marginX: 'auto',
        borderBottomLeftRadius: 1,
        borderBottomRightRadius: 1,
        border: 1,
        borderTop: 'none',
        zIndex: '1',
        position: 'relative',
    })
);

export const OfferListItem = forwardRef((props, ref) => {
    const accordionContentRef = useRef();
    const { height } = useComponentSize(accordionContentRef);

    const contentAnimation = useTransition(props.expanded, {
        from: {
            opacity: 0,
            height: 0,
        },
        enter: {
            opacity: 1,
            height: height,
        },
        leave: {
            opacity: 0,
            height: 0,
        },
        update: {
            height: height,
            opacity: 1,
        },
    });

    return (
        <OfferAccordion ref={ref}>
            <OfferCardContainer>
                <OfferListItemGrid>
                    <OfferSummary offer={props.offer} onClick={props.onClick} isBestPrice={props.isBestPrice} />
                    <OfferActions offer={props.offer} order={props.order} />
                </OfferListItemGrid>
            </OfferCardContainer>

            {contentAnimation((style, item) =>
                item ? (
                    <animated.div style={{ ...style, overflow: 'hidden' }}>
                        <OfferAccordionContentWrapper ref={accordionContentRef}>
                            <Flex width="80%" minHeight="400px" padding={7} flexDirection="column">
                                <FuelShowOfferProductTable
                                    showPrices={props.offer.isOrder() || props.offer.isQuotedEnquiry()}
                                    offer={props.offer}
                                />

                                <Box marginTop={6} flex="auto">
                                    <OfferCompareAttachments
                                        offer={props.offer}
                                        invalidationQueryKey={queryKeys.fuelOrderOffers()}
                                    />
                                </Box>
                            </Flex>

                            <OfferChatWrapper>
                                <OfferChat offer={props.offer} />
                            </OfferChatWrapper>
                        </OfferAccordionContentWrapper>
                    </animated.div>
                ) : null
            )}
        </OfferAccordion>
    );
});

OfferListItem.displayName = 'OfferListItem';
