import { useEffect, useState } from 'react';

import { Caption } from '../../../cdl/Caption/Caption.tsx';
import { getPricingCode, getPricingCodes } from '../../api/clients/fuelPricingCodes.api';
import { DoubleLineOption } from '../../DoubleLineOption/DoubleLineOption';
import { translate } from '../../helpers/translate.helper';
import { AsyncSearchSelect } from '../AsyncSearchSelect/AsyncSearchSelect';
import { useFormData } from '../Form/useFormData';
import { Label } from '../Label/Label';

const PricingCodeOption = ({ data: { pricingCode }, ...props }) => {
    return <DoubleLineOption headline={pricingCode.code} subline={pricingCode.name} {...props} />;
};

function PricingCodeSelect({ value, onChange, frequencies, negativeCaption, ...props }) {
    const [selectedValue, setSelectedValue] = useState(null);

    const loadPricingCodesBySearchQuery = (searchQuery) => {
        return new Promise((resolve) => {
            getPricingCodes(frequencies, searchQuery)
                .then((response) => {
                    return response.content.map((pricingCode) => ({
                        label: pricingCode.code,
                        value: pricingCode.id,
                        pricingCode,
                    }));
                })
                .then((pricingCodes) => resolve(pricingCodes));
        });
    };

    const loadPricingCodeById = (id) => {
        return new Promise((resolve) => {
            getPricingCode(id)
                .then((pricingCode) => {
                    return {
                        label: pricingCode.code,
                        value: pricingCode.id,
                        pricingCode,
                    };
                })
                .then((pricingCode) => resolve(pricingCode));
        });
    };

    useEffect(() => {
        let isMounted = true;
        if (value?.id) {
            loadPricingCodeById(value.id).then((result) => {
                if (isMounted) setSelectedValue(result);
            });
            return () => {
                isMounted = false;
            };
        }
    }, [value]);

    const updateSelectedValue = (option) => {
        setSelectedValue(option);
        if (onChange) {
            onChange(option);
        }
    };

    return (
        <Label label={translate('contracts.product.pricingCode')} required={props.required}>
            <AsyncSearchSelect
                placeholder={translate('contracts.product.placeholder.pricingCode')}
                values={selectedValue}
                onChange={updateSelectedValue}
                loadOptions={loadPricingCodesBySearchQuery}
                components={{
                    Option: PricingCodeOption,
                }}
                {...props}
            />

            {negativeCaption ? <Caption $negative>{negativeCaption}</Caption> : null}
        </Label>
    );
}

export const FormPricingCodeSelect = ({ dataPath, conversionRateDataPath, frequencies, negativeCaption, ...props }) => {
    const { value, onChange } = useFormData(dataPath, { default: { id: '' } });
    const { onChange: conversionRateOnChange } = useFormData(conversionRateDataPath);

    const onChangeHandle = (option) => {
        onChange({ target: { value: option.pricingCode } });
        conversionRateOnChange({
            target: { value: option.pricingCode.conversionRate },
        });
    };

    return (
        <PricingCodeSelect
            key={value.id}
            value={value}
            onChange={onChangeHandle}
            frequencies={frequencies}
            negativeCaption={negativeCaption}
            {...props}
        />
    );
};
