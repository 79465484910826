import { CreateOrderRequest, LubesUpdateOrderRequest } from '../../common/api/clients/order.api';

import { OrderModel } from './OrderModel';

const toCreateOrderRequest = (order: OrderModel): CreateOrderRequest => {
    return {
        agentId: order.agentId || null,
        buyerReference: order.buyerReference || null,
        comment: order.comment,
        portId: order.portId,
        receiverSupplierIds: order.receiverSupplierIds,
        samplekits: order.samplekits,
        spot: order.spot,
        state: order.state,
        vesselId: order.vesselId,
        chosenOfferId: order.chosenOfferId || null,
        type: order.type,
    };
};

export const toLubesUpdateOrderRequest = (order: OrderModel): LubesUpdateOrderRequest => {
    return {
        ...toCreateOrderRequest(order),
        dateDelivery: order.dateDelivery || null,
        items: order.items,
        cancelReason: order.cancelReason || null,
    };
};
