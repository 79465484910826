import { useField } from 'formik';

import { DatePicker, DatePickerProps } from '../../../cdl/DatePicker/DatePicker';

interface FormikDatePickerProps extends Pick<DatePickerProps, 'label' | 'markAsRequired' | 'caption'> {
    name: string;
}

export const FormikDatePicker = ({ name, ...rest }: FormikDatePickerProps) => {
    const [field, meta, helpers] = useField(name);
    const millisecondsInMinute = 60_000;

    const onDateChange = (date: Date | undefined) => {
        helpers.setTouched(true);
        if (date) {
            const timezoneOffset = date.getTimezoneOffset() * millisecondsInMinute * -1;
            const formattedDate = new Date(date.getTime() + timezoneOffset).toISOString();

            helpers.setValue(formattedDate);
        } else {
            helpers.setValue(null);
        }
    };

    const formatUtcIsoStringToLocalDate = (isoDate: string) => {
        const date = new Date(isoDate);
        const timezoneOffset = date.getTimezoneOffset() * millisecondsInMinute * -1;

        return new Date(date.getTime() - timezoneOffset);
    };

    return (
        <DatePicker
            {...rest}
            date={field.value ? formatUtcIsoStringToLocalDate(field.value) : undefined}
            onDateChange={onDateChange}
            negative={meta.touched && !!meta.error}
            caption={meta.touched && meta.error ? meta.error : rest.caption}
            onBlur={() => helpers.setTouched(true)}
        />
    );
};
