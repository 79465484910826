import { Form, Formik, FormikErrors } from 'formik';

import { ProductContext } from '../../../types/ProductContext';
import { SurchargeType } from '../../../types/SurchargeType';
import { Button } from '../../cdl/Button/Button';
import { FormikInput } from '../../cdl/Input/FormikInput';
import { Text } from '../../cdl/Text/Text';
import { FormikDebug } from '../../common/form-elements/formik/FormikDebug';
import { FormikEnumOptionSelect } from '../../common/form-elements/formik/FormikEnumOptionSelect';
import { translate } from '../../common/helpers/translate.helper';
import { useEnums } from '../../common/hooks/useEnums';
import { FormikMoneyInput } from '../../common/MoneyInput/FormikMoneyInput';
import { Box } from '../../common/ui/Box';

import { Surcharge } from './SurchargeButton';

interface SurchargeFormProps {
    onSubmit: (surcharge: Surcharge) => void;
    data?: Surcharge;
}

interface SurchargeFormState {
    surchargeType: SurchargeType | null;
    amount: string;
    customOption: string;
}

export const SurchargeForm = ({ onSubmit, data }: SurchargeFormProps) => {
    const { getHumanReadableValue } = useEnums();

    const initialValues: SurchargeFormState = {
        surchargeType: data?.surchargeType || null,
        amount: data?.amount || '',
        customOption: data?.customOption || '',
    };

    const validateForm = (values: SurchargeFormState): FormikErrors<SurchargeFormState> => {
        const errors: FormikErrors<SurchargeFormState> = {};

        if (!values.surchargeType) {
            errors.surchargeType = translate('surchargeForm.errors.typeRequired');
        }

        if (!values.amount) {
            errors.amount = translate('surchargeForm.errors.amountRequired');
        }

        if (Number(values.amount) === 0) {
            errors.amount = translate('surchargeForm.errors.amountZero');
        }

        return errors;
    };

    const handleSubmit = (values: SurchargeFormState) => {
        if (values.surchargeType) {
            const custom = values.customOption || getHumanReadableValue('surchargeType', SurchargeType.CUSTOM);

            const surcharge: Surcharge = {
                surchargeType: values.surchargeType,
                amount: values.amount,
                customOption: values.surchargeType === SurchargeType.CUSTOM ? custom : undefined,
            };
            onSubmit(surcharge);
        }
    };

    return (
        <Formik initialValues={initialValues} validate={validateForm} onSubmit={handleSubmit}>
            {({ isSubmitting, values }) => (
                <Form style={{ height: '100%' }}>
                    <Box display="grid" gridTemplateRows="auto 1fr auto" height="100%" gridTemplateColumns="100%">
                        <Box padding={7} paddingBottom={6}>
                            <Text variant="title" marginBottom={3} as="h1">
                                {translate('surchargeForm.title')}
                            </Text>
                            <Text variant="body" color="foreground.muted" as="p">
                                {translate('surchargeForm.description.choose')}
                                <br />
                                {translate('surchargeForm.description.total')}
                            </Text>
                        </Box>

                        <Box paddingX={7} display="flex" flexDirection="column" gap={5} overflowY="auto">
                            <FormikEnumOptionSelect
                                name="surchargeType"
                                enumType="surchargeType"
                                context={ProductContext.LUBES}
                                label={translate('surchargeForm.labels.surchargeType')}
                                sort={(options) => {
                                    // Sort the CUSTOM option to the end
                                    options.sort((a, b) => {
                                        if (a.value === SurchargeType.CUSTOM) {
                                            return 1;
                                        }
                                        if (b.value === SurchargeType.CUSTOM) {
                                            return -1;
                                        }
                                        return 0;
                                    });
                                }}
                            />

                            {values.surchargeType === SurchargeType.CUSTOM ? (
                                <FormikInput
                                    name="customOption"
                                    label={translate('surchargeForm.labels.customOption')}
                                />
                            ) : null}

                            <FormikMoneyInput
                                name="amount"
                                label={translate('surchargeForm.labels.amount')}
                                decimals={2}
                            />
                            <FormikDebug />
                        </Box>

                        <Box padding={7}>
                            <Button emphasis="high" type="submit" size="large" isLoading={isSubmitting} width="100%">
                                {translate('surchargeForm.button')}
                            </Button>
                        </Box>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};
