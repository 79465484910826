import { InternalProgressBarMarkerProps } from '../../../cdl/ProgressBar/components/InternalProgressBarMarker';
import { ProgressBar, ProgressBarProps } from '../../../cdl/ProgressBar/ProgressBar';
import { formatNumber } from '../../../common/helpers/formatNumber.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { Box } from '../../../common/ui/Box';

interface FillLevelBarProps {
    quantity: number;
    maxVolume?: number;
    warningLimit?: number;
    safetyReserve?: number;
}

export const FillLevelBar = ({ quantity, maxVolume, warningLimit, safetyReserve }: FillLevelBarProps) => {
    if (!maxVolume) {
        return <Box height="4px" />;
    }

    if (!warningLimit) {
        return <ProgressBar value={quantity} maxValue={maxVolume} variant="neutral" />;
    }

    const isBelowWarningLimit = quantity < warningLimit;
    const isBelowSafetyReserve = quantity < (safetyReserve || 0);

    let progressBarVariant: ProgressBarProps['variant'] = 'accent';
    let warningLimitVariant: InternalProgressBarMarkerProps['variant'] = 'accent';
    let safetyReserveVariant: InternalProgressBarMarkerProps['variant'] = 'neutral';

    if (isBelowWarningLimit) {
        progressBarVariant = 'attention';
        warningLimitVariant = 'attention';
    }

    if (isBelowSafetyReserve) {
        progressBarVariant = 'negative';
        warningLimitVariant = 'negative';
        safetyReserveVariant = 'negative';
    }

    return (
        <>
            <ProgressBar value={quantity} minValue={0} maxValue={maxVolume} variant={progressBarVariant}>
                {warningLimit ? (
                    <ProgressBar.Marker value={warningLimit} variant={warningLimitVariant} dimmed={isBelowWarningLimit}>
                        {translate('vessel.remainingOnBoard.warningLimit', {
                            warningLimit: formatNumber({ number: warningLimit, maximumFractionDigits: 0 }),
                        })}
                    </ProgressBar.Marker>
                ) : null}
                {safetyReserve ? (
                    <ProgressBar.Marker value={safetyReserve} variant={safetyReserveVariant}>
                        {translate('vessel.remainingOnBoard.safetyReserve', {
                            safetyReserve: formatNumber({ number: safetyReserve, maximumFractionDigits: 0 }),
                        })}
                    </ProgressBar.Marker>
                ) : null}
            </ProgressBar>
        </>
    );
};
