import { useState } from 'react';

import { Box } from '../../ui/Box.tsx';
import { Label } from '../Label/Label';
import { StyledInput } from '../TextInput/Input';

/**
 * @deprecated
 **/

export const DateTimePicker = ({ label = false, required = false, ...input }) => {
    const [isDirty, setIsDirty] = useState(false);

    return (
        <Label label={label} required={required}>
            <Box position="relative">
                <StyledInput
                    type="datetime-local"
                    className={isDirty ? 'dirty' : ''}
                    onBlur={() => setIsDirty(true)}
                    paddingX={5}
                    paddingY={4}
                    border={1}
                    borderRadius={1}
                    {...input}
                />
            </Box>
        </Label>
    );
};
