import { FieldArray, useField } from 'formik';

import { ItemMessage } from '../../../../../types/ItemMessage';
import { SamplekitMessage } from '../../../../../types/SamplekitMessage';
import { SurchargeMessage } from '../../../../../types/SurchargeMessage';
import { Surcharge } from '../../../../admin/surcharge/SurchargeButton';
import { Waiver } from '../../../../admin/surcharge/WaiverButton';
import { OrderState } from '../../../../order/model/OrderModel';
import { Item } from '../../../models/item.model';
import { Samplekit } from '../../../models/samplekit.model';
import { Surcharge as SurchargeModel } from '../../../models/surcharge.model';
import { AddRow } from '../../common/AddRow';
import { useLubesProductTableConfig } from '../hooks/useLubesProductTableConfig';
import { countActiveItems } from '../utils/items.helpers';

import { ProductRow } from './ProductRow';
import { SampleKit } from './SampleKitForm';
import { SamplekitRow } from './SamplekitRow';
import { SurchargeRow } from './SurchargeRow';

export const TableContent = () => {
    const [orderStateField] = useField('state');
    const [itemsField, , itemsHelpers] = useField('items');
    const [surchargesField, , surchargesHelpers] = useField('surcharges');
    const [samplekitsField, , samplekitsHelpers] = useField('samplekits');
    const { editable, editPrices, editSurcharges } = useLubesProductTableConfig();

    const onAddProductClick = () => {
        const emptyProduct = new Item({
            productId: '',
            new: true,
            unit: 'L',
            packType: 'BULK',
            unitSize: 1,
            units: '',
            price: editPrices ? { value: undefined, currency: 'USD' } : undefined,
        });

        itemsHelpers.setValue([...itemsField.value, emptyProduct]);
    };

    const onAddSurchargeClick = (surcharge: Surcharge) => {
        const newSurcharge = new SurchargeModel({
            name: surcharge.surchargeType === 'CUSTOM' ? surcharge.customOption : '',
            surchargeType: surcharge.surchargeType,
            new: true,
            value: {
                value: Number(surcharge.amount),
                currency: 'USD',
            },
        });

        surchargesHelpers.setValue([...surchargesField.value, newSurcharge]);
    };

    const onAddWaiverClick = (waiver: Waiver) => {
        const newWaiver = new SurchargeModel({
            name: waiver.description,
            surchargeType: 'CUSTOM',
            new: true,
            value: {
                value: Number(waiver.amount) * -1,
                currency: 'USD',
            },
        });

        surchargesHelpers.setValue([...surchargesField.value, newWaiver]);
    };

    const onSampleKitClick = (sampleKit: SampleKit) => {
        const newSampleKit = new Samplekit({
            name: sampleKit.name,
            quantity: sampleKit.quantity,
            new: true,
        });

        samplekitsHelpers.setValue([...samplekitsField.value, newSampleKit]);
    };

    const items: ItemMessage[] = itemsField.value || [];
    const surcharges: SurchargeMessage[] = surchargesField.value || [];
    const samplekits: SamplekitMessage[] = samplekitsField.value || [];

    return (
        <>
            <FieldArray
                name="items"
                render={({ remove }) => {
                    return items.map((item, index: number) => (
                        <ProductRow
                            key={index}
                            name={`items.${index}`}
                            index={index}
                            isDeleteable={
                                orderStateField.value === OrderState.DRAFT || orderStateField.value === undefined
                            }
                            isLastRemainingEntry={items.length === 1}
                            isLastSelectedEntry={countActiveItems(items) === 1 && item.deleted === false}
                            onRowRemoveClicked={() => {
                                if (items.length === 1) {
                                    itemsHelpers.setValue([
                                        new Item({
                                            productId: '',
                                            new: true,
                                            unit: 'L',
                                            packType: 'BULK',
                                            unitSize: 1,
                                            units: '',
                                            price: editPrices ? { value: undefined, currency: 'USD' } : undefined,
                                        }),
                                    ]);
                                    itemsHelpers.setTouched(false);
                                    return;
                                }
                                remove(index);
                            }}
                        />
                    ));
                }}
            />

            <FieldArray
                name="surcharges"
                render={({ remove }) => {
                    return surcharges.map((_, index: number) => {
                        return (
                            <SurchargeRow
                                key={index}
                                name={`surcharges.${index}`}
                                onRowRemoveClicked={() => remove(index)}
                            />
                        );
                    });
                }}
            />

            <FieldArray
                name="samplekits"
                render={({ remove }) => {
                    return samplekits.map((_, index: number) => {
                        return (
                            <SamplekitRow
                                key={index}
                                name={`samplekits.${index}`}
                                onRowRemoveClicked={() => remove(index)}
                            />
                        );
                    });
                }}
            />

            {editable && (
                <AddRow
                    onAddProductClick={onAddProductClick}
                    onAddSurchargeClick={onAddSurchargeClick}
                    onAddWaiverClick={onAddWaiverClick}
                    onAddSampleKitClick={onSampleKitClick}
                    editSurcharges={editSurcharges}
                />
            )}
        </>
    );
};
